var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import TopNavTemplate from "templates/topNav";
import TopNavSettings from "./shared";
var EmptyTopNavLocal = /** @class */ (function (_super) {
    __extends(EmptyTopNavLocal, _super);
    function EmptyTopNavLocal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EmptyTopNavLocal.prototype.render = function () {
        return (React.createElement(TopNavTemplate, null,
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "nav-right row justify-content-end align-items-center", style: { marginRight: "20px" } },
                    React.createElement(TopNavSettings, null)))));
    };
    return EmptyTopNavLocal;
}(React.Component));
var EmptyTopNav = EmptyTopNavLocal;
export default EmptyTopNav;
