var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { UpdateInput } from 'helpers/inputHandlers';
import { USER_POST_REQUEST } from 'helpers/requests';
import { TimeSince } from 'helpers/timesince';
import React from 'react';
import { MemberPreview } from './previews';
var MembersView = /** @class */ (function (_super) {
    __extends(MembersView, _super);
    function MembersView(props) {
        var _this = _super.call(this, props) || this;
        _this.viewMember = function (id) {
            if (id == null) {
                window.history.pushState(null, "", "/organization/members");
            }
            else {
                window.history.pushState(null, "", "/organization/members/".concat(id));
            }
            _this.setState({
                viewingMember: id,
            });
        };
        var id = null;
        // TODO: check for member id
        _this.state = {
            viewingMember: id,
            modalOpen: false,
            importModalOpen: false
        };
        _this.viewMember = _this.viewMember.bind(_this);
        _this.toggleModal = _this.toggleModal.bind(_this);
        _this.toggleImportModal = _this.toggleImportModal.bind(_this);
        return _this;
    }
    MembersView.prototype.toggleModal = function () {
        var modalOpen = this.state.modalOpen;
        this.setState({
            modalOpen: !modalOpen
        });
    };
    MembersView.prototype.toggleImportModal = function () {
        var importModalOpen = this.state.importModalOpen;
        this.setState({
            importModalOpen: !importModalOpen
        });
    };
    MembersView.prototype.componentDidMount = function () {
        // TODO: check for member
        var member = null;
        if (member != null) {
            this.setState({
                viewingMember: member,
            });
        }
        else {
            this.setState({
                viewingMember: null,
            });
        }
    };
    MembersView.prototype.render = function () {
        var _this = this;
        var viewingMember = this.state.viewingMember;
        return (React.createElement("div", { className: "container-fluid organization-page-new members-page" }, viewingMember == null ?
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "row organization-header" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h4", { className: "overview" },
                                    React.createElement("a", { onClick: function () { _this.props.updateView('default'); } }, "<",
                                        " Back to Administration Dashboard")),
                                React.createElement("div", { className: "row align-items-center" },
                                    React.createElement("div", { className: "col-auto" },
                                        React.createElement("h1", { className: "font-alt font-light" }, "Organization Members")),
                                    React.createElement("div", { className: "col-auto" },
                                        React.createElement("button", { className: "btn btn-blue", onClick: this.toggleModal },
                                            "Add User ",
                                            React.createElement("i", { className: "icon-add" }))),
                                    React.createElement("div", { className: "col-auto" },
                                        React.createElement("button", { className: "btn btn-blue", onClick: this.toggleImportModal },
                                            "Import Members ",
                                            React.createElement("i", { className: "icon-add" })))))))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("table", { className: "members-table" },
                                    React.createElement("tbody", null, this.props.members.map(function (member, index) {
                                        return (React.createElement(MemberPreview, { viewMember: function () { _this.viewMember(member.id); }, member: member, key: index }));
                                    }))))))),
                React.createElement(ImportMembersModal, { addMembers: this.props.addMembers, active: this.state.importModalOpen, toggleModal: this.toggleImportModal }),
                React.createElement(AddMemberModal, { addMembers: this.props.addMembers, modalOpen: this.state.modalOpen, toggleModal: this.toggleModal })) :
            React.createElement("div", { className: "row" },
                React.createElement(MemberInfo, { back: function () { _this.viewMember(null); }, ID: this.state.viewingMember }))));
    };
    return MembersView;
}(React.Component));
var AddMemberModal = /** @class */ (function (_super) {
    __extends(AddMemberModal, _super);
    function AddMemberModal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            firstName: '',
            lastName: '',
            email: '',
            errors: [],
        };
        _this.updateInput = UpdateInput.bind(_this);
        _this.addMember = _this.addMember.bind(_this);
        return _this;
    }
    AddMemberModal.prototype.addMember = function () {
        var _this = this;
        var errors = [];
        var _a = this.state, firstName = _a.firstName, lastName = _a.lastName, email = _a.email;
        if (firstName.length == 0) {
            errors.push("First name cannot be left blank.");
        }
        if (lastName.length == 0) {
            errors.push("Last name cannot be left blank.");
        }
        if (email.length == 0) {
            errors.push("Email cannot be left blank.");
        }
        if (errors.length > 0) {
            this.setState({
                errors: errors,
            });
            return;
        }
        USER_POST_REQUEST('add-organization-member', { first_name: firstName, last_name: lastName, email: email }, function (res) {
            _this.setState({
                firstName: '',
                lastName: '',
                email: '',
            }, function () {
                _this.props.toggleModal();
                console.log(res);
                _this.props.addMembers([{ first_name: firstName, last_name: lastName, id: res.id, added: res.created }]);
            });
        }, function (err) {
            console.log(err);
        });
    };
    AddMemberModal.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "modal add-member-modal ".concat(this.props.modalOpen ? "active" : "") },
            React.createElement("div", { className: "modal-content" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "row align-items-center justify-content-between" },
                                React.createElement("div", { className: "col-auto" },
                                    React.createElement("h2", { className: "font-alt heading" }, "Add Member:")),
                                React.createElement("div", { className: "col-auto" },
                                    React.createElement("button", { onClick: this.props.toggleModal, className: "btn btn-cancel-alt" }, "Close")))),
                        React.createElement("div", { className: "col-12 add-member-form" },
                            React.createElement("div", { className: "row field" },
                                React.createElement("div", { className: "col-6" },
                                    React.createElement("label", { htmlFor: "firstName" }, "First Name*:"),
                                    React.createElement("input", { onChange: this.updateInput, value: this.state.firstName, type: "text", name: "firstName" })),
                                React.createElement("div", { className: "col-6" },
                                    React.createElement("label", { htmlFor: "lastName" }, "Last Name*:"),
                                    React.createElement("input", { onChange: this.updateInput, value: this.state.lastName, type: "text", name: "lastName" }))),
                            React.createElement("div", { className: "row field" },
                                React.createElement("div", { className: "col-12" },
                                    React.createElement("label", { htmlFor: "email" }, "Email*:"),
                                    React.createElement("input", { onChange: this.updateInput, value: this.state.email, name: "email", type: "email" }))),
                            React.createElement("div", { className: "row field" },
                                React.createElement("div", { className: "col-12" },
                                    React.createElement("button", { onClick: this.addMember, disabled: this.state.firstName.length == 0 || this.state.lastName.length == 0 || this.state.email.length == 0, className: "btn btn-blue font-alt" }, "Add Member"))),
                            React.createElement("div", { className: "row field" },
                                React.createElement("div", { className: "col-12" },
                                    React.createElement("ul", { className: "errors" }, this.state.errors.map(function (error, index) {
                                        return (React.createElement("li", { key: index }, error));
                                    })))))))),
            React.createElement("div", { className: "modal-bg", onClick: function () { _this.props.toggleModal(); } })));
    };
    return AddMemberModal;
}(React.Component));
var ImportMembersModal = /** @class */ (function (_super) {
    __extends(ImportMembersModal, _super);
    function ImportMembersModal(props) {
        var _this = _super.call(this, props) || this;
        _this.handleFile = function (e) {
            var file = e.currentTarget.files[0];
            if (file == null) {
                return;
            }
            _this.setState({
                members: [],
            }, function () {
                var reader = new FileReader();
                reader.onload = function () {
                    var addMembers = [];
                    var res = reader.result.toString();
                    var entries = res.split(/\r\n|\n/);
                    for (var i = 1; i < entries.length; i++) {
                        var data = entries[i].split(",");
                        if (data.length != 3) {
                            continue;
                        }
                        else {
                            addMembers.push({
                                first_name: data[0],
                                last_name: data[1],
                                email: data[2],
                            });
                        }
                    }
                    _this.setState(function (prev) { return ({
                        members: __spreadArray(__spreadArray([], addMembers, true), prev.members, true)
                    }); });
                };
                reader.readAsBinaryString(file);
            });
        };
        _this.state = {
            members: [],
            errors: [],
            submitting: false,
        };
        _this.handleFile = _this.handleFile.bind(_this);
        _this.clearMembers = _this.clearMembers.bind(_this);
        _this.removeMember = _this.removeMember.bind(_this);
        _this.cancel = _this.cancel.bind(_this);
        _this.submitMembers = _this.submitMembers.bind(_this);
        _this.fileRef = React.createRef();
        return _this;
    }
    ImportMembersModal.prototype.cancel = function () {
        var _this = this;
        this.fileRef.current.value = null;
        this.setState({
            members: [],
        }, function () {
            _this.props.toggleModal();
        });
    };
    ImportMembersModal.prototype.clearMembers = function () {
    };
    ImportMembersModal.prototype.removeMember = function () {
    };
    ImportMembersModal.prototype.submitMembers = function () {
        var _this = this;
        this.setState({
            submitting: true,
        }, function () {
            USER_POST_REQUEST('import-members', { members: _this.state.members }, function (res) {
                console.log(res);
                _this.props.toggleModal();
                _this.props.addMembers(res);
                _this.setState({
                    submitting: false,
                    members: [],
                });
                _this.fileRef.current.value = null;
            }, function (err) {
                _this.setState({
                    submitting: false,
                    errors: err.response.data.errors,
                });
            });
        });
    };
    ImportMembersModal.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "modal add-members-modal ".concat(this.props.active ? "active" : "") },
            React.createElement("div", { className: "modal-content" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h2", { className: "font-alt heading" }, "Import Members:")),
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h3", null, "Select CSV File:"),
                            React.createElement("h5", null, "*First line is ignored (headers)"),
                            React.createElement("input", { ref: this.fileRef, onChange: this.handleFile, type: "file", accept: ".csv" })),
                        React.createElement("div", { className: "col-12" }, this.state.members.length != 0 ?
                            React.createElement("table", { className: "add-members-table" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("td", null, "First Name:"),
                                        React.createElement("td", null, "Last Name:"),
                                        React.createElement("td", null, "Email:"))),
                                React.createElement("tbody", null, this.state.members.map(function (member, index) {
                                    return (React.createElement("tr", { key: index },
                                        React.createElement("td", null, member.first_name),
                                        React.createElement("td", null, member.last_name),
                                        React.createElement("td", null, member.email)));
                                })))
                            : null)),
                    React.createElement("div", { className: "row" },
                        React.createElement("ul", { className: "errors" }, this.state.errors.map(function (err, index) {
                            return (React.createElement("li", { key: index }, err));
                        }))),
                    React.createElement("div", { className: "row submit" },
                        React.createElement("div", { className: "col-12" },
                            this.state.submitting ?
                                React.createElement("h2", { style: { margin: "18px auto" } }, "Submitting...")
                                : null,
                            this.state.members.length == 0 ? null :
                                React.createElement(React.Fragment, null,
                                    React.createElement("button", { className: "btn btn-cancel", disabled: this.state.submitting, onClick: this.cancel }, "Cancel"),
                                    React.createElement("button", { className: "btn btn-blue", disabled: this.state.submitting, onClick: this.submitMembers }, "Add Members")))))),
            React.createElement("div", { className: "modal-bg", onClick: function () { _this.props.toggleModal(); } })));
    };
    return ImportMembersModal;
}(React.Component));
export { ImportMembersModal };
/*
    id: string
    name: string
    created: number
    updated: number
    permissions: number
    actions: OrganizationMemberAction[]
    ideas: number
    groups: number
    roleCount: number
    roles: string[]
    */
var MemberInfo = /** @class */ (function (_super) {
    __extends(MemberInfo, _super);
    function MemberInfo(props) {
        var _this = _super.call(this, props) || this;
        _this.setView = function (view) {
            _this.setState({
                view: view
            }, function () {
                if (view == 'ideas') {
                    if (_this.state.ideasLoaded == false) {
                        _this.getMemberIdeas();
                    }
                }
                if (view == 'projects') {
                    if (!_this.state.projectsLoaded) {
                        _this.getMemberProjects();
                    }
                }
                if (view == 'groups') {
                    if (!_this.state.groupsLoaded) {
                        _this.getMemberGroups();
                    }
                }
                if (view == 'log') {
                }
            });
        };
        _this.state = {
            loaded: false,
            member: null,
            view: 'log',
            ideas: [],
            projects: [],
            groups: [],
            ideasLoaded: true,
            groupsLoaded: true,
            projectsLoaded: true,
        };
        _this.ID = _this.props.ID;
        _this.loadMember = _this.loadMember.bind(_this);
        _this.getMemberIdeas = _this.getMemberIdeas.bind(_this);
        _this.setView = _this.setView.bind(_this);
        _this.getMemberGroups = _this.getMemberGroups.bind(_this);
        _this.getMemberProjects = _this.getMemberProjects.bind(_this);
        return _this;
    }
    MemberInfo.prototype.getMemberIdeas = function () {
        var _this = this;
        USER_POST_REQUEST('get-member-ideas', { id: this.ID }, function (res) {
            _this.setState({
                ideas: res.ideas || [],
                ideasLoaded: true,
            });
        }, function (err) {
            console.log(err);
        });
    };
    MemberInfo.prototype.getMemberProjects = function () {
        var _this = this;
        USER_POST_REQUEST('get-member-projects', { id: this.ID }, function (res) {
            _this.setState({
                projects: res.projects || [],
                projectsLoaded: true,
            });
        }, function (err) {
            console.log(err);
        });
    };
    MemberInfo.prototype.getMemberGroups = function () {
        var _this = this;
        USER_POST_REQUEST('get-member-groups', { id: this.ID }, function (res) {
            _this.setState({
                groups: res.groups || [],
                groupsLoaded: true,
            });
        }, function (err) {
            console.log(err);
        });
    };
    MemberInfo.prototype.loadMember = function () {
        var _this = this;
        USER_POST_REQUEST('get-member-ideas', { id: this.ID }, function (res) {
            _this.setState({
                ideas: res.ideas || [],
                ideasLoaded: true,
            });
        }, function (err) {
            console.log(err);
        });
        USER_POST_REQUEST('get-member-projects', { id: this.ID }, function (res) {
            console.log(res);
            _this.setState({
                projects: res.projects || [],
                projectsLoaded: true,
            });
        }, function (err) {
            console.log(err);
        });
        USER_POST_REQUEST('get-member-groups', { id: this.ID }, function (res) {
            _this.setState({
                groups: res.groups || [],
                groupsLoaded: true,
            });
        }, function (err) {
            console.log(err);
        });
        USER_POST_REQUEST('get-organization-member', { id: this.ID }, function (res) {
            var _a = _this.state, groups = _a.groups, projects = _a.projects, ideas = _a.ideas;
            var view = 'ideas';
            if (ideas.length == 0) {
                view = 'projects';
                if (projects.length == 0) {
                    view = 'groups';
                    if (groups.length == 0) {
                        view = 'none';
                    }
                }
            }
            _this.setState({
                loaded: true,
                ideasLoaded: true,
                projectsLoaded: true,
                groupsLoaded: true,
                member: {
                    id: _this.ID,
                    created: res.created,
                    updated: res.updated,
                    email: res.email,
                    name: res.name,
                    avatar: res.avatar,
                    permissions: res.permissions,
                    groups: res.groups != null ? res.groups : 0,
                    ideas: res.idea_count,
                    actions: [],
                    projects: res.projects != null ? res.projects : 0,
                    isAdmin: true,
                    isLeader: true,
                },
                view: view,
            });
        }, function (err) {
            console.log(err);
        });
    };
    MemberInfo.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.ID != this.props.ID) {
            this.ID = this.props.ID;
            this.loadMember();
        }
    };
    MemberInfo.prototype.componentDidMount = function () {
        this.loadMember();
    };
    MemberInfo.prototype.render = function () {
        var _this = this;
        var _a = this.state, member = _a.member, view = _a.view, loaded = _a.loaded;
        if (loaded == false) {
            return (null);
        }
        return (React.createElement("div", { className: "container-fluid member-page organization-page-new" },
            React.createElement("div", { className: "row organization-header" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h4", { className: "overview" },
                                React.createElement("a", { onClick: this.props.back }, "<",
                                    " Back to Organization Members")),
                            React.createElement("h1", { className: "font-alt font-light" },
                                member.name,
                                " - ",
                                React.createElement("span", { className: "font-alt font-light", style: { textTransform: "lowercase" } }, member.email)))))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 card" },
                            React.createElement("div", { className: "content-wrapper" },
                                React.createElement("div", { className: "row card-header" },
                                    React.createElement("div", { className: "col-auto title font-alt-2" },
                                        React.createElement("i", { className: "icon-info" }),
                                        "  MEMBER OVERVIEW")),
                                React.createElement("div", { className: "row card-content org-stats" },
                                    React.createElement("div", { className: "col-3 t-center br" }, member.avatar != null && member.avatar != "" ?
                                        React.createElement("img", { className: "avatar", src: member.avatar })
                                        : React.createElement("img", { className: "avatar", src: "/images/default_avatar.jpg" })),
                                    React.createElement("div", { className: "col-3 br" },
                                        React.createElement("h2", { className: "font-alt" },
                                            React.createElement("i", { className: "icon-check_bubble" }),
                                            " ",
                                            member.ideas,
                                            " IDEAS")),
                                    React.createElement("div", { className: "col-3 br" },
                                        React.createElement("h2", { className: "font-alt" },
                                            React.createElement("i", { className: "icon-projects" }),
                                            " ",
                                            this.state.projects.length,
                                            " PROJECTS")),
                                    React.createElement("div", { className: "col-3" },
                                        React.createElement("h2", { className: "font-alt" },
                                            React.createElement("i", { className: "icon-group" }),
                                            " ",
                                            this.state.groups.length,
                                            " GROUPS")))))))),
            view != 'none' ?
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 tabbed-card card member-card" },
                            React.createElement("div", { className: "content-wrapper" },
                                React.createElement("div", { className: "row card-header member-menu" },
                                    this.state.projects.length > 0 ?
                                        React.createElement("div", { onClick: function () { _this.setView('projects'); }, className: "col-auto h-c item br ".concat(view == 'projects' ? "active" : "") },
                                            React.createElement("h2", { className: "font-alt-2" },
                                                React.createElement("i", { className: "icon-projects" }),
                                                "  Projects"))
                                        :
                                            null,
                                    this.state.ideas.length > 0 ?
                                        React.createElement("div", { onClick: function () { _this.setView('ideas'); }, className: "col-auto h-c item br ".concat(view == 'ideas' ? "active" : "") },
                                            React.createElement("h2", { className: "font-alt-2" },
                                                React.createElement("i", { className: "icon-ideabase" }),
                                                "  Ideas"))
                                        :
                                            null,
                                    this.state.groups.length > 0 ?
                                        React.createElement("div", { onClick: function () { _this.setView('groups'); }, className: "col-auto h-c item br ".concat(view == 'groups' ? "active" : "") },
                                            React.createElement("h2", { className: "font-alt-2" },
                                                React.createElement("i", { className: "icon-group" }),
                                                "  Groups"))
                                        :
                                            null),
                                React.createElement("div", { className: "row card-content member-content" }, view == 'log' ?
                                    React.createElement(MemberLogView, { logs: this.state.member.actions }) :
                                    view == 'ideas' ?
                                        React.createElement(MemberIdeaView, { ideas: this.state.ideas })
                                        : view == 'projects' ?
                                            React.createElement(MemberProjectView, { projects: this.state.projects })
                                            : view == 'groups' ?
                                                React.createElement(MemberGroupView, { groups: this.state.groups })
                                                : null)))))
                : null));
    };
    return MemberInfo;
}(React.Component));
var MemberGroupView = /** @class */ (function (_super) {
    __extends(MemberGroupView, _super);
    function MemberGroupView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MemberGroupView.prototype.render = function () {
        return (React.createElement("div", { className: "col-12" },
            React.createElement("div", { className: "row" },
                React.createElement("table", { className: "member-groups-table" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("td", { className: "font-alt" }, "Name: "),
                            React.createElement("td", { className: "font-alt" }, "Created: "))),
                    React.createElement("tbody", null, this.props.groups.map(function (g, index) {
                        return (React.createElement("tr", { key: index, onClick: function () { window.open("/group/".concat(g.id)); } },
                            React.createElement("td", null, g.name),
                            React.createElement("td", null,
                                TimeSince(g.created),
                                " ago")));
                    }))))));
    };
    return MemberGroupView;
}(React.Component));
var MemberProjectView = /** @class */ (function (_super) {
    __extends(MemberProjectView, _super);
    function MemberProjectView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MemberProjectView.prototype.render = function () {
        return (React.createElement("div", { className: "col-12" },
            React.createElement("div", { className: "row" },
                React.createElement("table", { className: "member-projects-table" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("td", { className: "font-alt" }, "Name: "),
                            React.createElement("td", { className: "font-alt" }, "Created: "))),
                    React.createElement("tbody", null, this.props.projects.map(function (p, index) {
                        return (React.createElement("tr", { key: index, onClick: function () { window.open("/project/".concat(p.id)); } },
                            React.createElement("td", null, p.name),
                            React.createElement("td", null,
                                TimeSince(p.created),
                                " ago")));
                    }))))));
    };
    return MemberProjectView;
}(React.Component));
var MemberIdeaView = /** @class */ (function (_super) {
    __extends(MemberIdeaView, _super);
    function MemberIdeaView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MemberIdeaView.prototype.render = function () {
        return (React.createElement("div", { className: "col-12" },
            React.createElement("div", { className: "row" },
                React.createElement("table", { className: "member-ideas-table" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("td", { className: "font-alt" }, "Title: "),
                            React.createElement("td", { className: "font-alt" }, "Submitted: "))),
                    React.createElement("tbody", null, this.props.ideas.map(function (idea, index) {
                        return (React.createElement("tr", { key: index, onClick: function () { window.open("/idea/".concat(idea.id)); } },
                            React.createElement("td", null, idea.title),
                            React.createElement("td", null,
                                TimeSince(idea.created),
                                " ago")));
                    }))))));
    };
    return MemberIdeaView;
}(React.Component));
var MemberLogView = /** @class */ (function (_super) {
    __extends(MemberLogView, _super);
    function MemberLogView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MemberLogView.prototype.render = function () {
        return (React.createElement("div", null));
    };
    return MemberLogView;
}(React.Component));
export default MembersView;
