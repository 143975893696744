var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CREDENTIALED_REQUEST } from "helpers/requests";
import React from "react";
import { Link } from "react-router-dom";
import { Logout } from "auth/logout";
import SideNavTemplate from "templates/sideNav";
import { connect } from "react-redux";
var mapState = function (state) { return ({
    Organization: state.App.Organization,
    User: state.App.User,
    Socket: state.App.Socket,
    LoggedIn: state.App.LoggedIn,
}); };
var connector = connect(mapState, null);
var SidebarLocal = /** @class */ (function (_super) {
    __extends(SidebarLocal, _super);
    function SidebarLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            menuOpen: false,
        };
        _this.logout = _this.logout.bind(_this);
        _this.toggleMenu = _this.toggleMenu.bind(_this);
        return _this;
    }
    SidebarLocal.prototype.toggleMenu = function () {
        this.setState(function (prev) { return ({
            menuOpen: !prev.menuOpen,
        }); });
    };
    SidebarLocal.prototype.logout = function () {
        var location = window.location;
        var redirectLink = "".concat(location.protocol, "//").concat(location.hostname).concat(location.port != undefined && location.port != ""
            ? ":".concat(location.port)
            : "");
        if (this.props.Organization != undefined) {
            var Organization = this.props.Organization;
            if (Organization.whitelabel != undefined) {
                if (Organization.whitelabel.referral != "") {
                    redirectLink =
                        redirectLink + "/login/".concat(Organization.whitelabel.referral);
                }
            }
        }
        if (!redirectLink.includes("login")) {
            redirectLink = redirectLink + "/login";
        }
        CREDENTIALED_REQUEST("logout", {}, function (res) {
            Logout();
            window.location.replace("".concat(redirectLink));
        }, function (err) {
            console.log(err);
        });
    };
    SidebarLocal.prototype.componentDidMount = function () { };
    SidebarLocal.prototype.render = function () {
        var Organization = this.props.Organization;
        var split = window.location.pathname.split("/");
        var page = split.length > 1 ? split[1] : "";
        var organization_sub_page = page == "organization" && split.length > 2 ? split[2] : "";
        var assets_sub_page = page == "assets" && split.length > 2 ? split[2] : "";
        if (this.props.LoggedIn == false) {
            return React.createElement(SideNavTemplate, null);
        }
        return (React.createElement(SideNavTemplate, null,
            React.createElement(Link, { to: "/dashboard", className: "side-link ".concat(page == "dashboard" ? "active" : "") },
                React.createElement("i", { className: "icon-home" }),
                React.createElement("span", null, "Home")),
            React.createElement(Link, { className: "side-link ".concat(page == "ideas" || assets_sub_page == "ideas" ? "active" : ""), to: "/ideas" },
                React.createElement("i", { className: "icon-cube_outline" }),
                " ",
                React.createElement("span", null, "Ideas")),
            React.createElement(Link, { className: "side-link ".concat(page == "projects" ||
                    page == "project" ||
                    assets_sub_page == "projects"
                    ? "active"
                    : ""), to: "/projects" },
                React.createElement("i", { className: "icon-projects" }),
                " ",
                React.createElement("span", null, "Projects")),
            React.createElement(Link, { className: "side-link ".concat(page == "groups" || assets_sub_page == "groups" ? "active" : ""), to: "/groups" },
                React.createElement("i", { className: "icon-group" }),
                " ",
                React.createElement("span", null, "Groups")),
            React.createElement(Link, { className: "side-link ".concat(page == "ideabase" ? "active" : ""), to: "/ideabase" },
                React.createElement("i", { className: "icon-ideabase" }),
                " ",
                React.createElement("span", null, "Ideabase")),
            React.createElement(Link, { className: "side-link ".concat(page == "search" ? "active" : ""), to: "/search" },
                React.createElement("i", { className: "icon-search" }),
                " ",
                React.createElement("span", null, "Search")),
            this.props.User.account_type == "organization" &&
                (this.props.Organization.admin || this.props.Organization.leader) ? (React.createElement(Link, { to: "/organization", onClick: function (e) {
                    if (window.location.pathname.includes("organization/")) {
                        e.preventDefault();
                        window.location.replace("/organization");
                        // window.history.pushState({}, '', '/organization')
                    }
                }, className: "side-link ".concat(page == "organization" ? "active" : "") },
                React.createElement("i", { className: "icon-group" }),
                React.createElement("span", null, "Org"))) : null,
            React.createElement("div", { className: "side-nav-bottom" },
                this.props.User.leader ? (React.createElement(Link, { to: "/billing", className: "".concat(window.location.pathname == "/billing"
                        ? "".concat(!this.props.Organization.payment_verified ||
                            !this.props.Organization.subscription_active
                            ? "not-verified active"
                            : "active")
                        : "".concat(!this.props.Organization.payment_verified ||
                            !this.props.Organization.subscription_active
                            ? "not-verified"
                            : "")) },
                    React.createElement("i", null, "$"),
                    React.createElement("span", null, "Billing"))) : null,
                React.createElement(Link, { to: "/settings", className: "".concat(window.location.pathname == "/settings" ? "active" : "") },
                    React.createElement("i", { className: "icon-settings" }),
                    React.createElement("span", null, "Settings")),
                React.createElement("a", { onClick: this.logout, className: "h-c" },
                    React.createElement("i", { className: "icon-logout" }),
                    React.createElement("span", null, "Logout")))));
    };
    return SidebarLocal;
}(React.Component));
var Sidebar = connector(SidebarLocal);
export default Sidebar;
