export function FileInfo(fileSize) {
    if (fileSize > 1000000000) {
        return "".concat(Math.round(fileSize / 10000000) / 100, " GB");
    }
    if (fileSize > 1000000) {
        return "".concat(Math.round(fileSize / 10000) / 100, " MB");
    }
    if (fileSize > 1000) {
        return "".concat(Math.round(fileSize / 1000), " KB");
    }
    return "".concat(Math.round(fileSize), " B");
}
export function FileType(file) {
    if (file.type == "") {
        return "text";
    }
    var parsedType = file.type.split("/")[0];
    if (parsedType == "text") {
        return "text";
    }
    if (parsedType == "image") {
        return "image";
    }
    if (parsedType == "video") {
        return "video";
    }
    // return extension instead 
    var extension = file.name.split('.').pop();
    return extension;
}
