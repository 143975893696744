var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { USER_POST_REQUEST } from "helpers/requests";
import { TimeSince } from "helpers/timesince";
import React from "react";
var MemberPreview = /** @class */ (function (_super) {
    __extends(MemberPreview, _super);
    function MemberPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MemberPreview.prototype.render = function () {
        var _this = this;
        var member = this.props.member;
        return (React.createElement("tr", { onClick: function () {
                _this.props.viewMember(member.id);
            } },
            React.createElement("td", null,
                React.createElement("img", { className: "avatar", src: member.avatar == "" || member.avatar == null
                        ? "/images/default_avatar.jpg"
                        : member.avatar })),
            React.createElement("td", null,
                member.first_name != null ? member.first_name : member.name,
                " ",
                member.last_name != null ? member.last_name : ""),
            React.createElement("td", null,
                TimeSince(member.added != null ? member.added : member.created),
                " ago")));
    };
    return MemberPreview;
}(React.Component));
var ProjectPreview = /** @class */ (function (_super) {
    __extends(ProjectPreview, _super);
    function ProjectPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectPreview.prototype.render = function () {
        var _this = this;
        var project = this.props.project;
        return (React.createElement("div", { className: "project-preview col-xl-4 col-lg-6 col-md-12 col-12" },
            React.createElement("div", { className: "row wrapper", onClick: function () {
                    _this.props.viewProject(project.id);
                } },
                React.createElement("div", { className: "content row" },
                    React.createElement("div", { className: "thumbnail" },
                        React.createElement("img", { src: project.thumbnail == "" || project.thumbnail == null
                                ? "/images/cover_images/1.png"
                                : project.thumbnail })),
                    React.createElement("div", { className: "info" },
                        React.createElement("div", { className: "row info-content-wrapper align-items-center" },
                            React.createElement("h2", { className: "name" }, project.name),
                            React.createElement("h4", { className: "font-alt-2" },
                                project.idea_count,
                                " IDEAS")))))));
    };
    return ProjectPreview;
}(React.Component));
var GroupPreview = /** @class */ (function (_super) {
    __extends(GroupPreview, _super);
    function GroupPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GroupPreview.prototype.render = function () {
        var group = this.props.group;
        return (React.createElement("div", { className: "group-preview col-xl-4 col-lg-6 col-md-12 col-12" },
            React.createElement("div", { className: "row wrapper", onClick: this.props.viewGroup },
                React.createElement("div", { className: "col-auto thumbnail" },
                    React.createElement("img", { src: group.thumbnail == "" || group.thumbnail == null
                            ? "/images/cover_images/4.png"
                            : group.thumbnail })),
                React.createElement("div", { className: "col info" },
                    React.createElement("div", { className: "row info-content-wrapper align-items-center" },
                        React.createElement("h2", { className: "name" }, group.name))))));
    };
    return GroupPreview;
}(React.Component));
var ArchivedIdeaPreview = /** @class */ (function (_super) {
    __extends(ArchivedIdeaPreview, _super);
    function ArchivedIdeaPreview(props) {
        var _this = _super.call(this, props) || this;
        _this.loadIdea = function (id) {
            window.location.href = "/idea/".concat(id);
        };
        _this.state = {
            ideas: [],
        };
        return _this;
    }
    ArchivedIdeaPreview.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST("get-archived-ideas", {}, function (res) {
            var firstTwoIdeas = (res.ideas || []).slice(0, 2);
            _this.setState({
                ideas: firstTwoIdeas,
            });
        }, function (err) {
            console.log(err);
        });
    };
    ArchivedIdeaPreview.prototype.render = function () {
        var _this = this;
        return (React.createElement("table", { className: "w-full" },
            React.createElement("tbody", null, this.state.ideas.map(function (idea, index) { return (React.createElement("tr", { key: index, className: "hover:bg-[#193762] transition-colors cursor-pointer", onClick: function () { return _this.loadIdea(idea.id); } },
                React.createElement("td", { className: "py-2 px-4", onClick: function () { return _this.loadIdea(idea.id); } },
                    React.createElement("img", { className: "w-12 h-12 object-cover rounded-md shadow-sm", src: idea.thumbnail || "/images/cover_images/1.png", alt: idea.title })),
                React.createElement("td", { className: "py-2 px-4", onClick: function () { return _this.loadIdea(idea.id); } },
                    React.createElement("span", { className: "font-semibold text-white hover:text-blue-600" }, idea.title)),
                React.createElement("td", { className: "py-2 px-4 text-left text-gray-400 text-sm", onClick: function () { return _this.loadIdea(idea.id); } },
                    "Updated ",
                    TimeSince(idea.created),
                    " ago"),
                idea.archived ? (React.createElement("td", { className: "py-2 px-4 text-left flex items-center", onClick: function () { return _this.loadIdea(idea.id); } },
                    React.createElement("span", { className: "text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-full" }, "Archived"))) : (React.createElement("td", { className: "py-2 px-4" })),
                React.createElement("td", { className: "py-2 px-4 text-right" }, idea.private && (React.createElement("i", { className: "icon-status text-[#98C13D] hover:text-[#8ab034]" }))))); }))));
    };
    return ArchivedIdeaPreview;
}(React.Component));
export { GroupPreview, MemberPreview, ProjectPreview, ArchivedIdeaPreview };
