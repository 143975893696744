export default function loadDashboardData() {
    return {
        assets: {
            total: 0,
            families: 0,
        },
        pending: null,
        trends: null,
        activity: null,
        statuses: null,
    };
}
