var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UpdateInput } from 'helpers/inputHandlers';
import { FORMDATA_REQUEST, USER_POST_REQUEST } from 'helpers/requests';
import React from 'react';
var SettingsView = /** @class */ (function (_super) {
    __extends(SettingsView, _super);
    function SettingsView(props) {
        var _this = _super.call(this, props) || this;
        _this.handleNewLogo = function (e) {
            if (e.target.files.length > 0) {
                _this.setState({
                    newLogo: e.target.files[0]
                });
            }
            else {
                _this.setState({
                    newLogo: null,
                });
            }
        };
        _this.updateLogo = function () {
            var fd = new FormData;
            fd.append("logo", _this.state.newLogo);
            FORMDATA_REQUEST('update-organization-logo', fd, function (res) {
                _this.setState({
                    logo: res.logo,
                    logoUpdated: true,
                    newLogo: null,
                }, function () {
                    _this.props.updateNameLogo(_this.props.name, res.logo);
                });
            }, function (err) {
                console.log(err);
            });
        };
        _this.updateName = function () {
            USER_POST_REQUEST('update-organization-info', { name: _this.state.newName }, function (res) {
                _this.setState(function (prev) { return ({
                    nameUpdated: true,
                    newName: prev.newName,
                    name: prev.newName,
                }); }, function () {
                    _this.props.updateNameLogo(_this.state.newName, _this.props.logo);
                });
            }, function (err) {
                console.log(err);
            });
        };
        _this.cancelNewName = function () {
            _this.setState({
                newName: _this.props.name,
            });
        };
        _this.editLogo = function () {
            _this.logoRef.current.click();
        };
        _this.cancelNewLogo = function () {
            _this.setState({
                newLogo: null,
            });
        };
        _this.state = {
            name: props.name,
            logo: props.logo,
            newName: props.name,
            newLogo: null,
            logoUpdated: false,
            nameUpdated: false,
        };
        _this.handleNewLogo = _this.handleNewLogo.bind(_this);
        _this.updateLogo = _this.updateLogo.bind(_this);
        _this.updateName = _this.updateName.bind(_this);
        _this.cancelNewName = _this.cancelNewName.bind(_this);
        _this.updateInput = UpdateInput.bind(_this);
        _this.editLogo = _this.editLogo.bind(_this);
        _this.cancelNewLogo = _this.cancelNewLogo.bind(_this);
        _this.logoRef = React.createRef();
        return _this;
    }
    SettingsView.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "container-fluid organization-page" },
            React.createElement("div", { className: "row organization-header" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h4", { className: "overview" },
                                React.createElement("a", { onClick: function () { _this.props.updateView("default"); } }, "<",
                                    " Back to Administration Dashboard")),
                            React.createElement("h1", { className: "font-alt font-light" }, "Organization Settings "))))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row organization-settings" },
                        React.createElement("input", { ref: this.logoRef, type: "file", style: { display: "none" }, onChange: this.handleNewLogo }),
                        React.createElement("div", { className: "col-12 field" },
                            React.createElement("h1", { className: "heading" }, "Organization Settings: "),
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-xs-12 col-6" },
                                    React.createElement("h2", { className: "heading" }, this.state.newLogo != null ? "New Logo" : "Organization Logo"),
                                    this.state.newLogo != null ?
                                        React.createElement("img", { className: "new-logo", src: URL.createObjectURL(this.state.newLogo) })
                                        : this.state.logo != null && this.state.logo + "" ?
                                            React.createElement("div", { className: "logo-container" },
                                                React.createElement("img", { onClick: this.editLogo, className: "logo", src: this.state.logo }),
                                                React.createElement("i", { className: "icon-edit" }))
                                            :
                                                React.createElement("span", null,
                                                    "No Logo set ",
                                                    React.createElement("br", null),
                                                    React.createElement("a", { className: "btn btn-blue-alt", onClick: this.editLogo }, "Click here to add one.")),
                                    React.createElement("div", { className: "row" },
                                        React.createElement("div", { className: "col-12 field-lower" }, this.state.newLogo != null ?
                                            React.createElement(React.Fragment, null,
                                                React.createElement("button", { onClick: this.cancelNewLogo, className: "btn btn-cancel font-alt" }, "Cancel"),
                                                React.createElement("button", { onClick: this.updateLogo, className: "btn btn-blue font-alt" }, "Update Logo"))
                                            : null),
                                        this.state.logoUpdated ?
                                            React.createElement("div", { className: "col-12" }, "Logo has been updated.")
                                            : null)),
                                React.createElement("div", { className: "col-xs-12 col-6" },
                                    React.createElement("h2", { className: "heading" }, "Organization Name"),
                                    React.createElement("input", { onChange: this.updateInput, type: "text", name: "newName", value: this.state.newName }),
                                    React.createElement("div", { className: "row" },
                                        React.createElement("div", { className: "col-12 field-lower" }, this.state.name != this.state.newName ?
                                            React.createElement(React.Fragment, null,
                                                React.createElement("button", { onClick: this.cancelNewName, className: "btn btn-cancel font-alt" }, "Cancel"),
                                                React.createElement("button", { onClick: this.updateName, className: "btn btn-blue font-alt" }, "Update Name"))
                                            : null),
                                        this.state.nameUpdated ?
                                            React.createElement("div", { className: "col-12" }, "Name has been updated.")
                                            : null)))))))));
    };
    return SettingsView;
}(React.Component));
export default SettingsView;
