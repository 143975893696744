// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member-registration .form-container {
  max-width: 900px;
}
.member-registration input {
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid var(--yellow-green);
  background: var(--dark);
  color: white;
}
.member-registration .field {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./app/pages/auth/memberRegistration.sass"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AACE;EACE,WAAA;EACA,aAAA;EACA,cAAA;EACA,qCAAA;EACA,uBAAA;EACA,YAAA;AACJ;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".member-registration\n  .form-container\n    max-width: 900px\n  input\n    width: 100%\n    padding: 12px\n    margin: 12px 0\n    border: 1px solid var(--yellow-green)\n    background: var(--dark)\n    color: white\n  .field\n    margin: 10px 0"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
