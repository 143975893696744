export function recursiveFolderRemove(removeFolder, folders) {
    for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == removeFolder.parent_id) {
            if (removeFolder.new) {
                folders[i].folders = folders[i].folders.filter(function (filtered) { return filtered.id != removeFolder.id; });
            }
            else {
                folders[i].folders.find(function (found) { return found.id == removeFolder.id; }).removed = true;
            }
            return folders;
        }
        if (folders[i].folders != undefined) {
            if (folders[i].folders.length > 0) {
                folders[i].folders = recursiveFolderRemove(removeFolder, folders[i].folders);
            }
        }
    }
    return folders;
}
