export var FILE_TYPES = {
    images: ["png", "jpg", "jpeg", "gif"],
    videos: ["webm", "mp4", "ogg"],
    no_preview: ["exe", "zip", "gz", "7z"],
    archive: ["zip", "gz", "7z"],
};
export function GetFileType(name) {
    var extension = name.split('.').pop().toLowerCase();
    if (extension == "pdf") {
        return "pdf";
    }
    if (extension == "mp3") {
        return "audio";
    }
    if (FILE_TYPES.images.indexOf(extension) != -1) {
        return "image";
    }
    else if (FILE_TYPES.videos.indexOf(extension) != -1) {
        return "video";
    }
    else if (FILE_TYPES.no_preview.indexOf(extension) != -1) {
        return "no-preview";
    }
    else {
        return "text";
    }
}
