export function recursiveFolderUnRemove(removeFolder, folders) {
    for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == removeFolder.parent_id) {
            folders[i].folders.find(function (found) { return found.id == removeFolder.id; }).removed = false;
            return folders;
        }
        if (folders[i].folders != undefined) {
            if (folders[i].folders.length > 0) {
                folders[i].folders = recursiveFolderUnRemove(removeFolder, folders[i].folders);
            }
        }
    }
    return folders;
}
