var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import DropDown from 'components/dropdown/dropdown';
import AssetViewSwitcher from 'components/ux/assetViewSwitcher';
import { USER_POST_REQUEST } from 'helpers/requests';
import { TimeSince } from 'helpers/timesince';
import GroupSettingsOverview from 'pages/groups/groupSettingsOverview';
import React from 'react';
var GroupsView = /** @class */ (function (_super) {
    __extends(GroupsView, _super);
    function GroupsView(props) {
        var _this = _super.call(this, props) || this;
        _this.updateSort = function (method) {
            var _a = method.split("-"), v = _a[0], d = _a[1];
            var p = _this.props.groups;
            if (v == "created") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.updated < b.updated ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.updated > b.updated ? 1 : -1); });
                }
            }
            if (v == "name") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); });
                }
            }
            if (_this.state.searchValue != "") {
                var sr = _this.state.searchResults;
                if (v == "created") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.updated < b.updated ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.updated > b.updated ? 1 : -1); });
                    }
                }
                if (v == "name") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); });
                    }
                }
                _this.setState({
                    searchResults: sr,
                });
            }
            _this.setState({
                sortValue: method,
            });
        };
        _this.updateSearchInput = function (e) {
            var value = e.target.value;
            _this.setState({
                searchValue: value,
                searchResults: _this.props.groups.filter(function (p) { return p.name.substring(0, value.length).toLowerCase() == value.toLowerCase(); })
            });
        };
        _this.viewGroup = function (id) {
            _this.setState({
                viewingGroup: id,
            }, function () {
                if (id != null) {
                    window.history.replaceState(null, "", "/organization/groups/".concat(id));
                }
                else {
                    window.history.replaceState(null, "", "/organization/groups");
                }
            });
        };
        _this.state = {
            viewingGroup: null,
            sortValue: "created-DESC",
            searchValue: "",
            searchResults: [],
        };
        _this.viewGroup = _this.viewGroup.bind(_this);
        _this.updateSearchInput = _this.updateSearchInput.bind(_this);
        _this.updateSort = _this.updateSort.bind(_this);
        return _this;
    }
    GroupsView.prototype.componentDidMount = function () {
        // TODO: get group
        var group = null;
        if (group != null) {
            this.viewGroup(group);
        }
        else {
            this.viewGroup(null);
        }
    };
    GroupsView.prototype.render = function () {
        var _this = this;
        var _a = this.props, groups = _a.groups, assetsView = _a.assetsView;
        var _b = this.state, viewingGroup = _b.viewingGroup, searchValue = _b.searchValue, searchResults = _b.searchResults;
        return (React.createElement("div", { className: "container-fluid organization-page-new group-page" }, viewingGroup == null ?
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "row organization-header" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h4", { className: "overview" },
                                    React.createElement("a", { onClick: function () { _this.props.updateView("default"); } }, "<",
                                        " Back to Administration Dashboard")),
                                React.createElement("h1", { className: "font-alt font-light" }, "Organization Groups "))))),
                React.createElement("div", { className: "row asset-controls" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center justify-content-between" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(DropDown, { valueIdentifier: "SORT: ", value: this.state.sortValue, clickAction: this.updateSort, options: [{ text: "Name Z-A", value: "name-DESC" }, { text: "Name A-Z", value: "name-ASC" }, { text: "Newest", value: "created-DESC" }, { text: "Oldest", value: "created-ASC" }] })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("i", { className: "icon-search" }),
                                React.createElement("input", { className: "input search", placeholder: "Search Groups", onChange: this.updateSearchInput, value: searchValue })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(AssetViewSwitcher, null))))),
                React.createElement("div", { className: "row organization-projects" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" }, assetsView == "list" ?
                            React.createElement("div", { className: "col-12" },
                                React.createElement("table", { className: "assets-table" },
                                    React.createElement("tbody", null, searchValue != "" ?
                                        searchResults.map(function (g, i) {
                                            return React.createElement(GroupTablePreview, { key: i, group: g, viewGroup: _this.viewGroup });
                                        }) :
                                        groups.map(function (g, i) {
                                            return React.createElement(GroupTablePreview, { key: i, group: g, viewGroup: _this.viewGroup });
                                        }))))
                            :
                                searchValue != "" ?
                                    searchResults.map(function (g, i) {
                                        return React.createElement(GroupPreview, { key: i, group: g, viewGroup: _this.viewGroup });
                                    }) :
                                    groups.map(function (g, i) {
                                        return React.createElement(GroupPreview, { key: i, group: g, viewGroup: _this.viewGroup });
                                    }))))) :
            React.createElement("div", { className: "row" },
                React.createElement(GroupPage, { members: this.props.members, group: this.state.viewingGroup, back: function () { _this.viewGroup(null); } }))));
    };
    return GroupsView;
}(React.Component));
var GroupPreview = /** @class */ (function (_super) {
    __extends(GroupPreview, _super);
    function GroupPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GroupPreview.prototype.render = function () {
        var _a = this.props, group = _a.group, viewGroup = _a.viewGroup;
        return (React.createElement("div", { className: "project-preview col-xl-4 col-lg-6 col-md-12 col-12" },
            React.createElement("div", { className: "row wrapper", onClick: function () { viewGroup(group.id); } },
                React.createElement("div", { className: "content row" },
                    React.createElement("div", { className: "thumbnail" },
                        React.createElement("img", { src: group.thumbnail == "" || group.thumbnail == null ? "/images/cover_images/1.png" : group.thumbnail })),
                    React.createElement("div", { className: "info" },
                        React.createElement("div", { className: "row info-content-wrapper align-items-center" },
                            React.createElement("h2", { className: "name font-alt-2" }, group.name)))))));
    };
    return GroupPreview;
}(React.Component));
var GroupTablePreview = /** @class */ (function (_super) {
    __extends(GroupTablePreview, _super);
    function GroupTablePreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GroupTablePreview.prototype.render = function () {
        var _a = this.props, group = _a.group, viewGroup = _a.viewGroup;
        return (React.createElement("tr", { onClick: function () { viewGroup(group.id); } },
            React.createElement("td", null,
                React.createElement("img", { className: "thumbnail", src: group.thumbnail == "" || group.thumbnail == null ? "/images/cover_images/1.png" : group.thumbnail }),
                " "),
            React.createElement("td", null,
                React.createElement("b", null, group.name)),
            React.createElement("td", { className: "updated" },
                "Updated ",
                TimeSince(group.updated),
                " ago")));
    };
    return GroupTablePreview;
}(React.Component));
var GroupPage = /** @class */ (function (_super) {
    __extends(GroupPage, _super);
    function GroupPage(props) {
        var _this = _super.call(this, props) || this;
        _this.loadGroup = function (groupID) {
            USER_POST_REQUEST('get-group', { group: groupID }, function (res) {
                console.log(res);
                _this.setState({
                    ideas: res.ideas || [],
                    members: res.members || [],
                    name: res.name || "",
                    id: res.id,
                    group_loaded: true,
                    created: res.updated,
                    projects: res.projects || [],
                    currentGroup: res,
                });
            }, function (err) {
                console.log(err);
            });
        };
        _this.updateGroup = function (g) {
            _this.setState({
                currentGroup: g,
            });
        };
        _this.state = {
            id: "",
            name: "",
            thumbnail: "",
            ideas: [],
            projects: [],
            members: [],
            idea_count: 0,
            user_count: 0,
            project_count: 0,
            created: 0,
            updated: 0,
            group_loaded: false,
            modalOpen: false,
            currentGroup: null,
        };
        _this.toggleModal = _this.toggleModal.bind(_this);
        return _this;
    }
    GroupPage.prototype.toggleModal = function () {
        var modalOpen = this.state.modalOpen;
        this.setState({
            modalOpen: !modalOpen,
        });
    };
    GroupPage.prototype.componentDidMount = function () {
        this.loadGroup(this.props.group);
    };
    GroupPage.prototype.render = function () {
        var _a = this.state, group_loaded = _a.group_loaded, name = _a.name, thumbnail = _a.thumbnail, created = _a.created, updated = _a.updated, id = _a.id;
        if (!group_loaded) {
            return (null);
        }
        return (React.createElement("div", { className: "container-fluid organization-page-new group-page" },
            React.createElement("div", { className: "row group-header" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 breadcrumbs" },
                            React.createElement("a", { onClick: this.props.back, className: "font-alt" }, "<",
                                " ALL GROUPS"))),
                    React.createElement("div", { className: "row align-items-center" },
                        React.createElement("div", { className: "col-auto thumb" },
                            React.createElement("img", { src: thumbnail == null || thumbnail == "" ? "/images/cover_images/1.png" : thumbnail })),
                        React.createElement("div", { className: "col" },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-12" },
                                    React.createElement("h1", { className: "title font-alt" }, name)),
                                React.createElement("div", { className: "col-12 time" },
                                    React.createElement("p", null,
                                        "Created: ",
                                        TimeSince(created),
                                        " ago",
                                        React.createElement("span", { className: "btn-separator" }),
                                        "|",
                                        React.createElement("span", { className: "btn-separator" }),
                                        "Last Updated: ",
                                        TimeSince(updated),
                                        " ago")),
                                React.createElement("div", { className: "col-12 lower" },
                                    React.createElement("div", { className: "row justify-content-between" },
                                        React.createElement("div", { className: "col-auto" }, true ?
                                            React.createElement("a", { className: "btn btn-blue-alt", onClick: this.toggleModal }, "Settings")
                                            : null)))))),
                    React.createElement("div", null,
                        React.createElement("div", { style: { marginTop: "15px" } },
                            React.createElement("h1", null, "Members"),
                            this.props.members == null || this.props.members.length == 0 ?
                                React.createElement("h2", null, "No members in this group, try adding some.") :
                                React.createElement("table", { className: "table group-members-table" },
                                    React.createElement("thead", null,
                                        React.createElement("tr", null,
                                            React.createElement("td", null, "Name: "),
                                            React.createElement("td", null, "Added: "))),
                                    React.createElement("tbody", null, this.props.members.map(function (gm, index) {
                                        return (React.createElement("tr", { key: index },
                                            React.createElement("td", null, gm.name == null || gm.name == "" ? "".concat(gm.first_name, " ").concat(gm.last_name) : gm.name),
                                            React.createElement("td", null, TimeSince(gm.created))));
                                    }))))))),
            React.createElement("div", { className: "row group-content" }),
            React.createElement("div", { className: "modal group-settings-modal ".concat(this.state.modalOpen ? "active" : "") },
                React.createElement("div", { className: "modal-content" },
                    React.createElement(GroupSettingsOverview, { members: this.props.members, updateGroup: this.updateGroup, currentGroup: this.state.currentGroup })),
                React.createElement("div", { className: "modal-bg", onClick: this.toggleModal }))));
    };
    return GroupPage;
}(React.Component));
export default GroupsView;
