var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import DropDown from 'components/dropdown/dropdown';
import { UpdateInput } from 'helpers/inputHandlers';
import { GetQueryParam } from 'helpers/queryParams';
import { USER_POST_REQUEST } from 'helpers/requests';
import { TimeSince } from 'helpers/timesince';
import React from 'react';
import DashboardTemplate from 'templates/dashboard';
var validTypes = ["all", "private_ideas", "projects", "ideabase", "groups"];
var SearchPage = /** @class */ (function (_super) {
    __extends(SearchPage, _super);
    function SearchPage(props) {
        var _this = _super.call(this, props) || this;
        _this.keyDown = function (e) {
            if (e.key == "Enter") {
                _this.search();
            }
        };
        _this.updateView = function (view) {
            _this.setState({
                viewing: view,
            });
        };
        _this.updateSearchInput = function (val) {
            _this.setState({
                objectType: val,
            });
        };
        var t = GetQueryParam("t");
        var q = GetQueryParam("q");
        _this.state = {
            query: q != null ? q : "",
            projectResults: [],
            groupResults: [],
            privateIdeaResults: [],
            publicIdeaResults: [],
            totalResults: 0,
            totalGroups: 0,
            totalPublicIdeas: 0,
            totalPrivateIdeas: 0,
            totalProjects: 0,
            viewing: "public_ideas",
            activeQuery: false,
            objectType: t != null ? t : "all",
        };
        _this.updateInput = UpdateInput.bind(_this);
        _this.updateSearchInput = _this.updateSearchInput.bind(_this);
        _this.search = _this.search.bind(_this);
        _this.updateView = _this.updateView.bind(_this);
        _this.keyDown = _this.keyDown.bind(_this);
        return _this;
    }
    SearchPage.prototype.setInitialView = function (res) {
        if (res.total_public_ideas > 0) {
            return "public_ideas";
        }
        else if (res.total_private_ideas > 0) {
            return "private_ideas";
        }
        else if (res.total_projects > 0) {
            return "projects";
        }
        else if (res.total_groups > 0) {
            return "groups";
        }
        return "";
    };
    SearchPage.prototype.componentDidMount = function () {
        var query = this.state.query;
        if (query != "") {
            this.search();
        }
    };
    SearchPage.prototype.search = function () {
        var _this = this;
        var _a = this.state, query = _a.query, objectType = _a.objectType;
        USER_POST_REQUEST('search', { query: query, object_type: objectType }, function (res) {
            console.log(res);
            _this.setState({
                totalGroups: res.total_groups,
                totalProjects: res.total_projects,
                totalPrivateIdeas: res.total_private_ideas,
                totalPublicIdeas: res.total_public_ideas,
                projectResults: res.projects || [],
                groupResults: res.groups || [],
                publicIdeaResults: res.public_ideas || [],
                privateIdeaResults: res.private_ideas || [],
                totalResults: res.total_results,
                activeQuery: true,
                viewing: _this.setInitialView(res)
            });
        }, function (err) { });
    };
    SearchPage.prototype.render = function () {
        var _this = this;
        var _a = this.state, query = _a.query, objectType = _a.objectType, projectResults = _a.projectResults, groupResults = _a.groupResults, publicIdeaResults = _a.publicIdeaResults, privateIdeaResults = _a.privateIdeaResults, viewing = _a.viewing;
        var renderSearchResults = function () {
            if (objectType === "all") {
                switch (viewing) {
                    case "public_ideas":
                        return (React.createElement("div", { className: "row" }, publicIdeaResults.map(function (idea, i) { return (React.createElement("div", { key: i, className: "col-3 idea-preview", onClick: function () { window.location.href = "/idea/".concat(idea.hash_id); } },
                            React.createElement("div", { className: "wrapper" },
                                React.createElement("img", { src: isNaN(parseInt(idea.thumbnail)) ? idea.thumbnail : "/images/cover_images/".concat(idea.thumbnail, ".png") }),
                                React.createElement("h3", null,
                                    "ID: ",
                                    idea.hash_id),
                                React.createElement("h2", null, idea.title),
                                React.createElement("h4", null, TimeSince(idea.created))))); })));
                    case "private_ideas":
                        return (React.createElement("div", { className: "row" }, privateIdeaResults.map(function (idea, i) { return (React.createElement("div", { key: i, className: "col-3 idea-preview", onClick: function () { window.location.href = "/idea/".concat(idea.hash_id); } },
                            React.createElement("div", { className: "wrapper" },
                                React.createElement("img", { src: isNaN(parseInt(idea.thumbnail)) ? idea.thumbnail : "/images/cover_images/".concat(idea.thumbnail, ".png") }),
                                React.createElement("h3", null,
                                    "ID: ",
                                    idea.hash_id),
                                React.createElement("h2", null, idea.title),
                                React.createElement("h4", null, TimeSince(idea.created))))); })));
                    case "groups":
                        return (React.createElement("div", { className: "row" }, groupResults.map(function (g, i) { return (React.createElement("div", { key: i, className: "col-3 group-result", onClick: function () { window.location.href = "/group/".concat(g.hash_id); } },
                            React.createElement("img", { src: g.thumbnail != "" ? g.thumbnail : "/images/cover_images/1.png" }),
                            React.createElement("h3", null,
                                "ID: ",
                                g.hash_id),
                            React.createElement("h2", null, g.name),
                            React.createElement("h4", null, TimeSince(g.created)))); })));
                    case "projects":
                        return (React.createElement("div", { className: "row" }, projectResults.map(function (p, i) { return (React.createElement("div", { key: i, className: "col-3 project-result", onClick: function () { window.location.href = "/project/".concat(p.hash_id); } },
                            React.createElement("img", { src: p.thumbnail != "" ? p.thumbnail : "/images/cover_images/3.png" }),
                            React.createElement("h3", null,
                                "ID: ",
                                p.hash_id),
                            React.createElement("h2", null, p.name),
                            React.createElement("h4", null, TimeSince(p.created)))); })));
                    default:
                        return null;
                }
            }
            else {
                switch (objectType) {
                    case "private_ideas":
                        return (React.createElement("div", { className: "row" }, privateIdeaResults.map(function (idea, i) { return (React.createElement("div", { key: i, className: "col-3 idea-preview", onClick: function () { window.location.href = "/idea/".concat(idea.hash_id); } },
                            React.createElement("div", { className: "wrapper" },
                                React.createElement("img", { src: isNaN(parseInt(idea.thumbnail)) ? idea.thumbnail : "/images/cover_images/".concat(idea.thumbnail, ".png") }),
                                React.createElement("h3", null,
                                    "ID: ",
                                    idea.hash_id),
                                React.createElement("h2", null, idea.title),
                                React.createElement("h4", null, TimeSince(idea.created))))); })));
                    case "ideabase":
                        return (React.createElement("div", { className: "row" }, publicIdeaResults.map(function (idea, i) { return (React.createElement("div", { key: i, className: "col-3 idea-preview", onClick: function () { window.location.href = "/idea/".concat(idea.hash_id); } },
                            React.createElement("div", { className: "wrapper" },
                                React.createElement("img", { src: isNaN(parseInt(idea.thumbnail)) ? idea.thumbnail : "/images/cover_images/".concat(idea.thumbnail, ".png") }),
                                React.createElement("h3", null,
                                    "ID: ",
                                    idea.hash_id),
                                React.createElement("h2", null, idea.title),
                                React.createElement("h4", null, TimeSince(idea.created))))); })));
                    case "projects":
                        return (React.createElement("div", { className: "row" }, projectResults.map(function (p, i) { return (React.createElement("div", { key: i, className: "col-3 project-result", onClick: function () { window.location.href = "/project/".concat(p.hash_id); } },
                            React.createElement("img", { src: p.thumbnail != "" ? p.thumbnail : "/images/cover_images/3.png" }),
                            React.createElement("h3", null,
                                "ID: ",
                                p.hash_id),
                            React.createElement("h2", null, p.name),
                            React.createElement("h4", null, TimeSince(p.created)))); })));
                    case "groups":
                        return (React.createElement("div", { className: "row" }, groupResults.map(function (g, i) { return (React.createElement("div", { key: i, className: "col-3 group-result", onClick: function () { window.location.href = "/group/".concat(g.hash_id); } },
                            React.createElement("img", { src: g.thumbnail != "" ? g.thumbnail : "/images/cover_images/1.png" }),
                            React.createElement("h3", null,
                                "ID: ",
                                g.hash_id),
                            React.createElement("h2", null, g.name),
                            React.createElement("h4", null, TimeSince(g.created)))); })));
                    default:
                        return null;
                }
            }
        };
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container-fluid search-page" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("hr", { className: "tp" }),
                                React.createElement("h1", { className: "font-alt font-light t-center" }, "Search"),
                                React.createElement("hr", { className: "tp" })),
                            React.createElement("div", { className: "col-12 t-center" },
                                React.createElement(DropDown, { objClass: "search-ib-dropdown", clickAction: this.updateSearchInput, value: objectType, options: [
                                        { text: "All", value: "all" },
                                        { text: "Private Ideas", value: "private_ideas" },
                                        { text: "Public Ideas", value: "ideabase" },
                                        { text: "Projects", value: "projects" },
                                        { text: "Groups", value: "groups" }
                                    ] }),
                                React.createElement("input", { type: "text", onKeyDown: this.keyDown, value: query, onChange: this.updateInput, name: "query", placeholder: "Search your ideas, projects, and groups" }),
                                React.createElement("button", { className: "btn btn-blue", onClick: this.search }, "Go"))),
                        this.state.activeQuery && (React.createElement("div", { className: "row stats" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("hr", { className: "tp" }),
                                React.createElement("h3", { className: "font-alt" },
                                    this.state.totalResults,
                                    " Results")))),
                        objectType === "all" && this.state.activeQuery && (React.createElement("div", { className: "row tabs" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("hr", { className: "tp" })),
                            this.state.totalPublicIdeas > 0 && (React.createElement("div", { className: "col-auto font-alt-2" },
                                React.createElement("div", { onClick: function () { return _this.updateView("public_ideas"); }, className: "font-alt-2 tab ".concat(viewing === "public_ideas" ? "active" : "") },
                                    "Public Ideas (",
                                    this.state.totalPublicIdeas,
                                    ")"))),
                            this.state.totalPrivateIdeas > 0 && (React.createElement("div", { className: "col-auto font-alt-2" },
                                React.createElement("div", { onClick: function () { return _this.updateView("private_ideas"); }, className: "font-alt-2 tab ".concat(viewing === "private_ideas" ? "active" : "") },
                                    "Private Ideas (",
                                    this.state.totalPrivateIdeas,
                                    ")"))),
                            this.state.totalProjects > 0 && (React.createElement("div", { className: "col-auto font-alt-2" },
                                React.createElement("div", { onClick: function () { return _this.updateView("projects"); }, className: "font-alt-2 tab ".concat(viewing === "projects" ? "active" : "") },
                                    "Projects (",
                                    this.state.totalProjects,
                                    ")"))),
                            this.state.totalGroups > 0 && (React.createElement("div", { className: "col-auto font-alt-2" },
                                React.createElement("div", { onClick: function () { return _this.updateView("groups"); }, className: "font-alt-2 tab ".concat(viewing === "groups" ? "active" : "") },
                                    "Groups (",
                                    this.state.totalGroups,
                                    ")"))))),
                        renderSearchResults())))));
    };
    return SearchPage;
}(React.Component));
export default SearchPage;
