export function recursiveFolderUpdate(updateFolder, folders) {
    for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == updateFolder.parent_id) {
            folders[i].folders[folders[i].folders.findIndex(function (find) { return find.id == updateFolder.id; })] = updateFolder;
            return folders;
        }
        if (folders[i].folders != undefined) {
            if (folders[i].folders.length > 0) {
                folders[i].folders = recursiveFolderUpdate(updateFolder, folders[i].folders);
            }
        }
    }
    return folders;
}
