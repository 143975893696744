var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function recursiveFolderInsert(newFolder, folders) {
    for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == newFolder.parent_id) {
            folders[i].folders = __spreadArray(__spreadArray([], folders[i].folders, true), [newFolder], false);
            return folders;
        }
        if (folders[i].folders != undefined) {
            if (folders[i].folders.length > 0) {
                folders[i].folders = recursiveFolderInsert(newFolder, folders[i].folders);
            }
        }
    }
    return folders;
}
