var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { connect } from 'react-redux';
var mapState = function (state) { return ({
    AssetView: state.UI.AssetView
}); };
var mapDispatch = {
    UpdateAssetView: function (view) { return ({ type: 'UPDATE_ASSET_VIEW', view: view }); }
};
var connector = connect(mapState, mapDispatch);
var AssetViewSwitch = /** @class */ (function (_super) {
    __extends(AssetViewSwitch, _super);
    function AssetViewSwitch(props) {
        return _super.call(this, props) || this;
    }
    AssetViewSwitch.prototype.render = function () {
        var _this = this;
        var AssetView = this.props.AssetView;
        return (React.createElement("div", { className: "asset-view-switch" },
            React.createElement("i", { onClick: function () { _this.props.UpdateAssetView("grid"); }, className: "".concat(AssetView == "grid" ? "active" : "", " grid-icon") }),
            React.createElement("i", { onClick: function () { _this.props.UpdateAssetView("list"); }, className: "".concat(AssetView == "list" ? "active" : "", " list-icon") })));
    };
    return AssetViewSwitch;
}(React.Component));
export default connector(AssetViewSwitch);
