var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FileViewer } from 'components/fileViewer/fileViewer';
import { FileInfo } from 'helpers/fileInfo';
import { FolderInfo, FolderInfoString } from 'helpers/folderInfo';
import React from 'react';
var ReviewIdeaLocal = /** @class */ (function (_super) {
    __extends(ReviewIdeaLocal, _super);
    function ReviewIdeaLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            fileViewerOpen: false,
            bodyScroll: 0,
        };
        _this.toggle = _this.toggle.bind(_this);
        return _this;
    }
    ReviewIdeaLocal.prototype.toggle = function () {
        this.state.fileViewerOpen ? document.body.classList.remove("modal-open") : document.body.classList.add("modal-open");
        this.setState(function (prev) { return ({
            fileViewerOpen: !prev.fileViewerOpen
        }); });
    };
    ReviewIdeaLocal.prototype.render = function () {
        var Idea = this.props.data;
        var tagsLength = Idea.Tags.length;
        var setStage = this.props.setStage;
        var ideaFolderInfoArr = [0, 0];
        Idea.folders.forEach(function (folder) {
            var res = FolderInfo(folder);
            ideaFolderInfoArr[0]++;
            ideaFolderInfoArr[0] += res[0];
            ideaFolderInfoArr[1] += res[1];
        });
        ideaFolderInfoArr[1] += Idea.files.length;
        return (React.createElement("div", { className: "row review-idea" },
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "row section-1" },
                    React.createElement("div", { className: "thumbnail-preview" },
                        React.createElement("i", { onClick: function () { setStage(4); }, className: "icon-edit" }),
                        React.createElement("img", { src: typeof Idea.Thumbnail == "string" ? Idea.Thumbnail == "" ? "/images/cover_images/1.png" : "/images/cover_images/".concat(Idea.Thumbnail, ".png") : URL.createObjectURL(Idea.Thumbnail) })),
                    React.createElement("div", { className: "title" },
                        React.createElement("h3", { className: "font-alt font-light" }, "ID-TBD"),
                        React.createElement("h1", { className: "font-alt font-light" }, Idea.Title))),
                React.createElement("div", { className: "row section-2" },
                    React.createElement("div", { className: "col-3 info-section br" },
                        React.createElement("h4", null,
                            React.createElement("span", { className: "section-heading" },
                                React.createElement("i", { className: "icon-project" }),
                                "  Project"),
                            React.createElement("span", { className: "edit", onClick: function () { setStage(3); } },
                                React.createElement("i", { className: "icon-edit" }),
                                "  Edit")),
                        React.createElement("h4", null, this.props.data.CurrentProject == null ?
                            "No Project Selected." :
                            this.props.data.CurrentProject.name)),
                    React.createElement("div", { className: "col-3 info-section br" },
                        React.createElement("h4", null,
                            React.createElement("span", { className: "section-heading" },
                                React.createElement("i", { className: "icon-tag" }),
                                "  Tags"),
                            React.createElement("span", { className: "edit", onClick: function () { setStage(3); } },
                                React.createElement("i", { className: "icon-edit" }),
                                "  Edit")),
                        React.createElement("h4", { className: "section-list" }, Idea.Tags.map(function (tag, index) {
                            if (index == 3) {
                                return (React.createElement("a", { key: index },
                                    "+",
                                    tagsLength - 3,
                                    " more."));
                            }
                            if (index > 2) {
                                return (null);
                            }
                            if (index <= 2) {
                                return (React.createElement("a", { key: index },
                                    tag,
                                    index != tagsLength - 1 ? ", " : ""));
                            }
                        })),
                        Idea.Tags.length == 0 ? React.createElement("h4", null, "No Tags Set") : null),
                    React.createElement("div", { className: "col-3 info-section br" },
                        React.createElement("h4", null,
                            React.createElement("span", { className: "section-heading" },
                                React.createElement("i", { className: "icon-permissions" }),
                                "  Visibility"),
                            React.createElement("span", { className: "edit", onClick: function () { setStage(3); } },
                                React.createElement("i", { className: "icon-edit" }),
                                "  Edit")),
                        React.createElement("h4", { className: "font-alt-2", style: { textTransform: "capitalize", fontWeight: 100 } }, Idea.Visibility)),
                    React.createElement("div", { className: "col-3 info-section" },
                        React.createElement("h4", null,
                            React.createElement("span", { className: "section-heading" },
                                React.createElement("i", { className: "icon-group" }),
                                "  Collaborators"),
                            React.createElement("span", { className: "edit", onClick: function () { setStage(5); } },
                                React.createElement("i", { className: "icon-edit" }),
                                "  Edit")),
                        React.createElement("h4", null, Idea.CollaboratingGroups.length + Idea.CollaboratingUsers == 0 ? "No collaborators" :
                            "".concat(Idea.CollaboratingGroups.length, " Groups, ").concat(Idea.CollaboratingUsers.length, " People")))),
                React.createElement("div", { className: "row section-2" },
                    React.createElement("div", { className: "col-4 files-preview" },
                        React.createElement("h3", { className: "section-heading" },
                            React.createElement("i", { className: "icon-files" }),
                            "  Files",
                            React.createElement("span", { onClick: function () { setStage(2); }, className: "edit" },
                                React.createElement("i", { className: "icon-edit" }),
                                "  Edit"),
                            React.createElement("i", { className: "icon-project", onClick: this.toggle })),
                        React.createElement("h3", { className: "section-heading" }, ideaFolderInfoArr[0] != 0 && ideaFolderInfoArr[1] != 0 ?
                            "".concat(ideaFolderInfoArr[0], " folders, ").concat(ideaFolderInfoArr[1], " files") :
                            ideaFolderInfoArr[1] != 0 ? "".concat(ideaFolderInfoArr[1], " files") : null),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 ".concat(Idea.folders.length + Idea.files.length == 0 ? "" : "files-present") },
                                React.createElement("div", { className: "file-list" },
                                    Idea.files.length == 0 && Idea.folders.length == 0 ?
                                        React.createElement("h4", null, "No files to show") : "",
                                    Idea.folders.map(function (folder, index) {
                                        return React.createElement(FolderPreview, { folder: folder, key: index });
                                    }),
                                    Idea.files.map(function (file, index) {
                                        return React.createElement(FilePreview, { file: file, key: index });
                                    }))))),
                    React.createElement("div", { className: "col-8 description-preview" },
                        React.createElement("h3", { className: "section-heading" },
                            React.createElement("i", { className: "icon-description" }),
                            "  Description"),
                        React.createElement("p", null, Idea.Description)))),
            React.createElement(FileViewer, { files: Idea.files, folders: Idea.folders, open: this.state.fileViewerOpen, toggle: this.toggle })));
    };
    return ReviewIdeaLocal;
}(React.Component));
var FilePreview = /** @class */ (function (_super) {
    __extends(FilePreview, _super);
    function FilePreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilePreview.prototype.render = function () {
        var file = this.props.file;
        return (React.createElement("div", { className: "file-list-item" },
            React.createElement("div", { className: "row align-items-center file-info" },
                React.createElement("div", { className: "col-md-auto" }, file.type == "image" ?
                    React.createElement("img", { className: "file-thumbnail", src: URL.createObjectURL(file.data) })
                    : React.createElement("i", { className: "icon-draft" })),
                React.createElement("div", { className: "col" },
                    React.createElement("span", { className: "file-name" }, file.name),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: "file-size" },
                        React.createElement("span", { style: { textTransform: "capitalize" } }, file.type),
                        " - ",
                        FileInfo(file.size))))));
    };
    return FilePreview;
}(React.Component));
var FolderPreview = /** @class */ (function (_super) {
    __extends(FolderPreview, _super);
    function FolderPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FolderPreview.prototype.render = function () {
        var folder = this.props.folder;
        return (React.createElement("div", { className: "folder-list-item" },
            React.createElement("div", { className: "row align-items-center folder-info" },
                React.createElement("div", { className: "col-md-auto" },
                    React.createElement("i", { className: "icon-folder" })),
                React.createElement("div", { className: "col" },
                    React.createElement("span", { className: "folder-name" }, folder.name),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: "folder-size" }, FolderInfoString(folder))))));
    };
    return FolderPreview;
}(React.Component));
var ReviewIdea = ReviewIdeaLocal;
export default ReviewIdea;
