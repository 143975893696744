export function recursiveFileUpdate(updateFile, folders) {
    for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == updateFile.parent_id) {
            folders[i].files[folders[i].files.findIndex(function (find) { return find.id == updateFile.id; })] = updateFile;
            return folders;
        }
        if (folders[i].folders != undefined) {
            if (folders[i].folders.length > 0) {
                folders[i].folders = recursiveFileUpdate(updateFile, folders[i].folders);
            }
        }
    }
    return folders;
}
