var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import DropDown from "components/dropdown/dropdown";
import AssetViewSwitcher from "components/ux/assetViewSwitcher";
import { UpdateInput } from "helpers/inputHandlers";
import { POST_REQUEST } from "helpers/requests";
import { TimeSince } from "helpers/timesince";
import React from "react";
import { connect } from "react-redux";
import DashboardTemplate from "templates/dashboard";
var mapState = function (state) { return ({
    AssetView: state.UI.AssetView != "" && state.UI.AssetView != null
        ? state.UI.AssetView
        : "grid",
}); };
var connector = connect(mapState, null);
var IdeabaseLocal = /** @class */ (function (_super) {
    __extends(IdeabaseLocal, _super);
    function IdeabaseLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.handleScroll = function (e) { };
        _this.updateSort = function (sort) {
            _this.setState({
                sortValue: sort,
                page: 0,
                ideas: [],
            }, function () {
                _this.loadIdeas();
            });
        };
        _this.loadIdea = function (hashID) {
            window.location.href = "/idea/".concat(hashID);
        };
        _this.loadIdeas = function () {
            if (!_this.state.nextPage) {
                return;
            }
            var _a = _this.state, page = _a.page, sortValue = _a.sortValue;
            var _b = sortValue.split("-"), sortKey = _b[0], sortDirection = _b[1];
            var nextPage = page + 1;
            _this.setState({
                page: nextPage,
            }, function () {
                POST_REQUEST("get-ideabase-ideas", {
                    page: Number(nextPage),
                    sort_key: sortKey,
                    sort_direction: sortDirection,
                }, function (res) {
                    var ideas = _this.state.ideas;
                    _this.setState({
                        nextPage: res.next_page,
                        ideas: __spreadArray(__spreadArray([], ideas, true), res.ideas, true),
                    });
                }, function (err) {
                    console.log(err);
                });
            });
        };
        _this.state = {
            searchQueried: false,
            activeSearch: "",
            searchValue: "",
            results: [],
            nextPage: true,
            page: 0,
            ideas: [],
            sortValue: "created-DESC",
        };
        _this.updateInput = UpdateInput.bind(_this);
        _this.search = _this.search.bind(_this);
        _this.updateSort = _this.updateSort.bind(_this);
        _this.handleScroll = _this.handleScroll.bind(_this);
        _this.reset = _this.reset.bind(_this);
        return _this;
    }
    IdeabaseLocal.prototype.reset = function () {
        this.setState({
            activeSearch: "",
            results: [],
        });
    };
    IdeabaseLocal.prototype.componentDidMount = function () {
        this.loadIdeas();
    };
    IdeabaseLocal.prototype.search = function () {
        var _this = this;
        var searchValue = this.state.searchValue;
        POST_REQUEST("search-ideabase", { query: searchValue }, function (res) {
            console.log(res);
            _this.setState({
                activeSearch: searchValue,
                results: res.ideas || [],
            });
        }, function (err) {
            _this.setState({
                activeSearch: searchValue,
                results: [],
            });
        });
    };
    IdeabaseLocal.prototype.render = function () {
        var _this = this;
        var _a = this.state, results = _a.results, activeSearch = _a.activeSearch;
        var AssetView = this.props.AssetView;
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "ideabase container-fluid", style: { height: "100%", overflowY: "auto", overflowX: "hidden" } },
                React.createElement("div", { className: "row ideabase-search" },
                    React.createElement("div", { className: "container t-center" },
                        React.createElement("h1", { className: "heading font-alt t-center" }, "IDEABASE"),
                        React.createElement("input", { type: "text", name: "searchValue", value: this.state.searchValue, onChange: this.updateInput, placeholder: "Search our public repository of ideas" }),
                        React.createElement("button", { onClick: this.search, className: "btn btn-blue" }, "Search"))),
                React.createElement("div", { className: "row asset-controls", style: {
                        borderBottom: "2px solid var(--accent-dark)",
                        paddingBottom: "12px",
                    } },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center justify-content-between" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(DropDown, { valueIdentifier: "SORT: ", value: this.state.sortValue, clickAction: this.updateSort, options: [
                                        { text: "Title Z-A", value: "title-DESC" },
                                        { text: "Title A-Z", value: "title-ASC" },
                                        { text: "Newest", value: "created-DESC" },
                                        { text: "Oldest", value: "created-ASC" },
                                    ] })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(AssetViewSwitcher, null))))),
                this.state.activeSearch != "" ? (React.createElement("div", { className: "row ideabase-results" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("h2", { className: "font-light font-alt" },
                                    results.length,
                                    " Results for Query '",
                                    this.state.activeSearch,
                                    "'")),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("button", { className: "btn btn-cancel-alt", onClick: this.reset }, "CLOSE")),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("hr", { className: "tp" }))),
                        React.createElement("div", { className: "row" }, AssetView == "list" ? (React.createElement("div", { className: "col-12" },
                            React.createElement("table", { className: "assets-table" },
                                React.createElement("tbody", null, this.state.results.map(function (idea, i) {
                                    return (React.createElement(TableIdeaPreview, { key: i, loadIdea: _this.loadIdea, idea: idea }));
                                }))))) : (this.state.results.map(function (idea, i) {
                            return (React.createElement(IdeaPreview, { key: i, idea: idea, loadIdea: _this.loadIdea }));
                        })))))) : null,
                activeSearch == "" ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "ideabase-results row", onScroll: this.handleScroll },
                        React.createElement("div", { className: "container" },
                            React.createElement("div", { className: "row" }, AssetView == "list" ? (React.createElement("div", { className: "col-12" },
                                React.createElement("table", { className: "assets-table" },
                                    React.createElement("tbody", null, this.state.ideas.map(function (idea, i) {
                                        return (React.createElement(TableIdeaPreview, { key: i, loadIdea: _this.loadIdea, idea: idea }));
                                    }))))) : (this.state.ideas.map(function (idea, i) {
                                return (React.createElement(IdeaPreview, { key: i, idea: idea, loadIdea: _this.loadIdea }));
                            }))))))) : null)));
    };
    return IdeabaseLocal;
}(React.Component));
var IdeaPreview = /** @class */ (function (_super) {
    __extends(IdeaPreview, _super);
    function IdeaPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IdeaPreview.prototype.render = function () {
        var _a = this.props, idea = _a.idea, loadIdea = _a.loadIdea;
        var thumbCheck = parseInt(idea.thumbnail);
        if (!isNaN(thumbCheck)) {
            // numerical (default cover img)
            idea.thumbnail = "/images/cover_images/" + idea.thumbnail + ".png";
        }
        return (
        //     <div onClick={() => {loadIdea(idea.id)}} className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6 recent-idea" >
        //     <div className="recent-idea-wrapper">
        //         <img className="thumbnail" src={idea.thumbnail} />
        //         <h5 className="id font-alt">{idea.id}</h5>
        //         <h4 className="title">{idea.title}</h4>
        //         <h5 className="created">{TimeSince(idea.created)} ago</h5>
        //     </div>
        // </div>
        React.createElement("div", { onClick: function () {
                loadIdea(idea.id);
            }, className: "w-full py-2 sm:w-1/3 md:w-1/4 lg:w-1/4 xl:w-1/4 text-center recent-idea" },
            React.createElement("div", { className: "p-3 mx-2 border border-transparent hover:border-blue-500 rounded-md transition-colors duration-400 recent-idea-wrapper h-[400px] flex flex-col" },
                React.createElement("div", { className: "relative h-[300px]" },
                    idea.private && (React.createElement("i", { className: "icon-status absolute text-[#90c418] bg-black text-xl rounded-tl-md rounded-br-md " })),
                    React.createElement("img", { className: "w-full h-full object-cover rounded-md", src: idea.thumbnail, alt: idea.title })),
                React.createElement("div", { className: "flex-1 pt-2 flex flex-col justify-between" },
                    React.createElement("h5", { className: "text-xs leading-[18px]  text-[#A9A5A6]" }, idea.id),
                    React.createElement("h4", { className: "text-base leading-5 my-1.5 text-white line-clamp-2" }, idea.title),
                    React.createElement("h5", { className: "text-[11px]  text-[#A9A5A6]" },
                        TimeSince(idea.created),
                        " ago")))));
    };
    return IdeaPreview;
}(React.Component));
var TableIdeaPreview = /** @class */ (function (_super) {
    __extends(TableIdeaPreview, _super);
    function TableIdeaPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TableIdeaPreview.prototype.render = function () {
        var _a = this.props, idea = _a.idea, loadIdea = _a.loadIdea;
        var thumbCheck = parseInt(idea.thumbnail);
        if (!isNaN(thumbCheck)) {
            // numerical (default cover img)
            idea.thumbnail = "/images/cover_images/" + idea.thumbnail + ".png";
        }
        return (React.createElement("tr", null,
            React.createElement("td", { onClick: function () {
                    loadIdea(idea.id);
                } },
                React.createElement("img", { className: "thumbnail", src: idea.thumbnail == "" || idea.thumbnail == null
                        ? "/images/cover_images/1.png"
                        : idea.thumbnail }),
                " "),
            React.createElement("td", { onClick: function () {
                    loadIdea(idea.id);
                } },
                React.createElement("b", null, idea.title)),
            React.createElement("td", { onClick: function () {
                    loadIdea(idea.id);
                }, className: "updated" },
                "Created ",
                TimeSince(idea.created),
                " ago")));
    };
    return TableIdeaPreview;
}(React.Component));
var Ideabase = connector(IdeabaseLocal);
export default Ideabase;
