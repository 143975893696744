var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import DropDown from "components/dropdown/dropdown";
import AssetViewSwitcher from "components/ux/assetViewSwitcher";
import { USER_POST_REQUEST } from "helpers/requests";
import { TimeSince } from "helpers/timesince";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DashboardTemplate from "templates/dashboard";
var mapState = function (state) { return ({
    AssetView: state.UI.AssetView,
}); };
var connector = connect(mapState, null);
var IdeasPage = /** @class */ (function (_super) {
    __extends(IdeasPage, _super);
    function IdeasPage(props) {
        var _this = _super.call(this, props) || this;
        _this.updateSort = function (method) {
            var _a = method.split("-"), v = _a[0], d = _a[1];
            var p = _this.state.ideas;
            if (v == "created") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.created < b.created ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.created > b.created ? 1 : -1); });
                }
            }
            if (v == "name") {
                if (d == "DESC") {
                    p.sort(function (a, b) {
                        return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
                    });
                }
                else {
                    p.sort(function (a, b) {
                        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                    });
                }
            }
            if (_this.state.searchValue != "") {
                var sr = _this.state.searchResults;
                if (v == "created") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.created < b.created ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.created > b.created ? 1 : -1); });
                    }
                }
                if (v == "name") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) {
                            return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
                        });
                    }
                    else {
                        sr.sort(function (a, b) {
                            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                        });
                    }
                }
                _this.setState({
                    searchResults: sr,
                });
            }
            _this.setState({
                sortValue: method,
            });
        };
        _this.updateSearchInput = function (e) {
            var value = e.target.value;
            _this.setState({
                searchValue: value,
                searchResults: _this.state.ideas.filter(function (p) {
                    return (p.title.substring(0, value.length).toLowerCase() ==
                        value.toLowerCase());
                }),
            });
        };
        _this.loadIdea = function (id) {
            window.location.href = "/idea/".concat(id);
        };
        _this.state = {
            ideas: [],
            pinnedIdeas: [],
            pinned: [],
            sortValue: "created-DESC",
            searchValue: "",
            searchResults: [],
        };
        _this.loadIdea = _this.loadIdea.bind(_this);
        _this.updateSort = _this.updateSort.bind(_this);
        _this.updateSearchInput = _this.updateSearchInput.bind(_this);
        return _this;
    }
    IdeasPage.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST("get-ideas", {}, function (res) {
            _this.setState({
                ideas: res.ideas || [],
            });
        }, function (err) {
            console.log(err);
        });
    };
    IdeasPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container-fluid ideas-page" },
                React.createElement("div", { className: "row header" },
                    React.createElement("div", { className: "container-fluid" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "container" },
                                React.createElement("div", { className: "breadcrumbs" },
                                    React.createElement(Link, { to: "/", className: "font-alt" }, "<",
                                        " HOME"))))),
                    React.createElement("div", { className: "container-fluid" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "container" },
                                React.createElement("div", { className: "row align-items-center justify-content-between" },
                                    React.createElement("div", { className: "col-auto" },
                                        React.createElement("h1", { className: "ideas-heading" }, "Ideas")),
                                    React.createElement("div", { className: "col-auto t-right" },
                                        React.createElement(Link, { style: { marginBottom: "20px" }, to: "/create-idea", className: "dashboard-create-idea welcome-link btn btn-blue" },
                                            React.createElement("span", { className: "icon-add" }),
                                            " Add New Idea"))))))),
                React.createElement("div", { className: "row asset-controls" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center justify-content-between" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(DropDown, { valueIdentifier: "SORT: ", value: this.state.sortValue, clickAction: this.updateSort, options: [
                                        { text: "Name Z-A", value: "name-DESC" },
                                        { text: "Name A-Z", value: "name-ASC" },
                                        { text: "Newest", value: "created-DESC" },
                                        { text: "Oldest", value: "created-ASC" },
                                    ] })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("i", { className: "icon-search" }),
                                React.createElement("input", { className: "input search", placeholder: "Search Ideas", onChange: this.updateSearchInput, value: this.state.searchValue })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(AssetViewSwitcher, null))))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            this.state.ideas.length == 0 ? (React.createElement("div", { className: "col-12" },
                                React.createElement("h2", { className: "font-alt" }, "No ideas...yet"))) : null,
                            this.props.AssetView == "list" ? (React.createElement("div", { className: "col-12" },
                                React.createElement("table", { className: "assets-table" },
                                    React.createElement("tbody", null, this.state.searchValue != ""
                                        ? this.state.searchResults.map(function (p, i) {
                                            return (React.createElement(TableIdeaPreview, { key: i, idea: p, loadIdea: _this.loadIdea }));
                                        })
                                        : this.state.ideas.map(function (p, i) {
                                            return (React.createElement(TableIdeaPreview, { key: i, idea: p, loadIdea: _this.loadIdea }));
                                        }))))) : this.state.searchValue != "" ? (this.state.searchResults.map(function (p, i) {
                                return (React.createElement(IdeaPreview, { key: i, idea: p, loadIdea: _this.loadIdea }));
                            })) : (this.state.ideas.map(function (p, i) {
                                return (React.createElement(IdeaPreview, { key: i, idea: p, loadIdea: _this.loadIdea }));
                            }))))))));
    };
    return IdeasPage;
}(React.Component));
export default connector(IdeasPage);
var IdeaPreview = /** @class */ (function (_super) {
    __extends(IdeaPreview, _super);
    function IdeaPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IdeaPreview.prototype.render = function () {
        var _a = this.props, idea = _a.idea, loadIdea = _a.loadIdea;
        var thumbCheck = parseInt(idea.thumbnail);
        if (!isNaN(thumbCheck)) {
            // numerical (default cover img)
            idea.thumbnail = "/images/cover_images/" + idea.thumbnail + ".png";
        }
        return (
        //   <div onClick={() => {loadIdea(idea.id)}} className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6 recent-idea" >
        //       <div className="recent-idea-wrapper">
        //           {idea.private ?
        //           <i className="icon-status" />
        //           : null}
        //           <img className="thumbnail" src={idea.thumbnail} />
        //           <h5 className="id font-alt">{idea.id}</h5>
        //           <h4 className="title">{idea.title}</h4>
        //           <h5 className="created">{TimeSince(idea.created)} ago</h5>
        //       </div>
        //   </div>
        React.createElement("div", { onClick: function () {
                loadIdea(idea.id);
            }, className: "w-full py-2 sm:w-1/3 md:w-1/4 lg:w-1/4 xl:w-1/5 text-center recent-idea" },
            React.createElement("div", { className: "p-3 mx-2 border border-transparent hover:border-blue-500 rounded-md transition-colors duration-400 recent-idea-wrapper xl:h-[400px]  flex flex-col" },
                React.createElement("div", { className: "relative h-[300px]" },
                    idea.private && (React.createElement("i", { className: "icon-status absolute text-[#90c418] bg-black text-xl rounded-tl-md rounded-br-md " })),
                    idea.archived && (React.createElement("span", { className: "right-0 absolute top-2" },
                        React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "28", height: "28", viewBox: "0 0 24 24" },
                            React.createElement("path", { fill: "currentColor", d: "m12 18l4-4l-1.4-1.4l-1.6 1.6V10h-2v4.2l-1.6-1.6L8 14zm-7 3q-.825 0-1.412-.587T3 19V6.525q0-.35.113-.675t.337-.6L4.7 3.725q.275-.35.687-.538T6.25 3h11.5q.45 0 .863.188t.687.537l1.25 1.525q.225.275.338.6t.112.675V19q0 .825-.587 1.413T19 21zm.4-15h13.2l-.85-1H6.25z" })))),
                    React.createElement("img", { className: "w-full h-full object-cover rounded-md", src: idea.thumbnail, alt: idea.title })),
                React.createElement("div", { className: "flex-1 pt-2 flex flex-col justify-between" },
                    React.createElement("h5", { className: "text-xs leading-[18px]  text-[#A9A5A6]" }, idea.id),
                    React.createElement("h4", { className: "text-base leading-5 my-1.5 text-white line-clamp-2" }, idea.title),
                    React.createElement("h5", { className: "text-[11px]  text-[#A9A5A6]" },
                        TimeSince(idea.created),
                        " ago")))));
    };
    return IdeaPreview;
}(React.Component));
var TableIdeaPreview = /** @class */ (function (_super) {
    __extends(TableIdeaPreview, _super);
    function TableIdeaPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TableIdeaPreview.prototype.render = function () {
        var _a = this.props, idea = _a.idea, loadIdea = _a.loadIdea;
        var thumbCheck = parseInt(idea.thumbnail);
        if (!isNaN(thumbCheck)) {
            idea.thumbnail = "/images/cover_images/" + idea.thumbnail + ".png";
        }
        return (
        // <tr>
        //     <td onClick={() => {loadIdea(idea.id)}}><img className="thumbnail" src={idea.thumbnail == "" || idea.thumbnail == null ? "/images/cover_images/1.png" : idea.thumbnail} /> </td>
        //     <td onClick={() => {loadIdea(idea.id)}}><b>{idea.title}</b></td>
        //     <td onClick={() => {loadIdea(idea.id)}} className="t-left updated">Updated {TimeSince(idea.created)} ago</td>
        //     {idea.archived ?
        //                  <td className='t-left flex items-center'>
        //                 Archived
        //                  </td>
        //                  : <td></td>
        //             }
        //     <td className="t-right">{idea.private ? <i className="icon-status" /> : ""}</td>
        // </tr>
        React.createElement("tr", { className: " transition-colors cursor-pointer" },
            React.createElement("td", { className: "py-3 px-4", onClick: function () { return loadIdea(idea.id); } },
                React.createElement("img", { className: "w-12 h-12 object-cover rounded-md shadow-sm", src: idea.thumbnail || "/images/cover_images/1.png", alt: idea.title })),
            React.createElement("td", { className: "py-3 px-4", onClick: function () { return loadIdea(idea.id); } },
                React.createElement("span", { className: "font-semibold text-white hover:text-blue-600" }, idea.title)),
            React.createElement("td", { className: "py-3 px-4 text-left text-gray-400 text-sm", onClick: function () { return loadIdea(idea.id); } },
                "Updated ",
                TimeSince(idea.created),
                " ago"),
            idea.archived ? (React.createElement("td", { className: "py-3 px-4 text-left text-gray-400 text-sm" },
                React.createElement("span", { className: "text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-full" }, "Archived"))) : (React.createElement("td", { className: "py-3 px-4" })),
            React.createElement("td", { className: "py-3 px-4 text-right" }, idea.private && (React.createElement("i", { className: "icon-status text-gray-600 hover:text-gray-800" })))));
    };
    return TableIdeaPreview;
}(React.Component));
