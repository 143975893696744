var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
//Vendor
import React from 'react';
import { connect } from 'react-redux';
//Local
import { CREDENTIALED_REQUEST, POST_REQUEST } from 'helpers/requests';
import { Login as LoginAction } from 'auth/login';
import { UpdateInput } from 'helpers/inputHandlers';
import { DefaultNoSideBar } from 'templates/default';
import { Link, Navigate } from 'react-router-dom';
var mapState = function (state) { return ({
    LoggedIn: state.App.LoggedIn,
    AppLoaded: state.App.AppLoaded,
}); };
var mapDispatch = {
    Login: function (payload) { return ({ type: 'LOGIN', payload: payload }); },
    SetAppLoaded: function (loaded) { return ({ type: 'SET_APP_LOADED', loaded: loaded }); }
};
var connector = connect(mapState, mapDispatch);
var LoginLocal = /** @class */ (function (_super) {
    __extends(LoginLocal, _super);
    function LoginLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.submit = function () {
            _this.setState({
                errors: [],
            });
            var errors = [];
            var _a = _this.state, email = _a.email, password = _a.password;
            if (email == "") {
                errors.push("Email cannot be blank.");
            }
            if (password == "") {
                errors.push("Password cannot be blank.");
            }
            if (errors.length != 0) {
                _this.setState({
                    errors: errors
                });
                return;
            }
            CREDENTIALED_REQUEST("login", { email: email, password: password }, (function (res) {
                LoginAction(res).then(function () {
                    window.location.href = "/dashboard";
                });
            }), function (err) {
                // if (err.response.data)
                _this.setState({
                    errors: err.response.data || []
                });
            });
        };
        _this.keyUpListener = function (e) {
            if (e.key == "Enter") {
                _this.submit();
            }
        };
        _this.state = {
            email: '',
            password: '',
            errors: []
        };
        _this.submit = _this.submit.bind(_this);
        _this.updateInput = UpdateInput.bind(_this);
        _this.keyUpListener = _this.keyUpListener.bind(_this);
        return _this;
    }
    LoginLocal.prototype.componentDidMount = function () {
        var _this = this;
        var referral = "";
        if (referral != undefined && referral != "") {
            POST_REQUEST('get-referral-info', { referral: referral }, function (res) {
                _this.setState({
                    referral: res,
                });
            }, function (err) {
            });
        }
    };
    LoginLocal.prototype.render = function () {
        var _a = this.state, errors = _a.errors, referral = _a.referral;
        var LoggedIn = this.props.LoggedIn;
        if (LoggedIn) {
            return React.createElement(Navigate, { to: "/dashboard" });
        }
        return (React.createElement(DefaultNoSideBar, null,
            referral != null ?
                React.createElement("a", { className: "ideablock-login-referral", href: referral.link, target: "_blank" },
                    React.createElement("img", { src: referral.logo }))
                : null,
            React.createElement("div", { className: "container login-form-container login-page" },
                React.createElement("div", { className: "row align-items-center content-wrapper" },
                    React.createElement("div", { className: "col-6 login-hero" },
                        React.createElement("img", { src: referral != null ? referral.logo : "/images/IB_Glyph_Green.svg" })),
                    React.createElement("div", { className: "col-6 form login-form" },
                        React.createElement("div", { className: "field row" },
                            React.createElement("div", { className: "col-12 t-center" },
                                React.createElement("h1", { className: "font-alt-2" }, "Login"))),
                        React.createElement("div", { className: "field" },
                            React.createElement("label", { htmlFor: "email" }, "Email:"),
                            React.createElement("input", { type: "email", placeholder: 'victor@ideablock.com', name: "email", onChange: this.updateInput, value: this.state.email })),
                        React.createElement("div", { className: "field" },
                            React.createElement("label", { htmlFor: "password" }, "Password:"),
                            React.createElement("input", { type: "password", name: "password", placeholder: '***', onChange: this.updateInput, onKeyDown: this.keyUpListener, value: this.state.password })),
                        React.createElement("div", { className: "field" },
                            React.createElement("hr", { className: "tp" }),
                            React.createElement(Link, { to: "/forgot-password", className: "forgot-password font-alt-2" }, "Forgot Password?")),
                        React.createElement("div", { className: "field" },
                            React.createElement("button", { onClick: this.submit, className: "btn btn-blue-alt" }, "Login")),
                        React.createElement("div", { className: "field errors" },
                            React.createElement("ul", null, this.state.errors.map(function (error, index) {
                                return (React.createElement("li", { key: index }, error));
                            }))))))));
    };
    return LoginLocal;
}(React.Component));
var Login = connector(LoginLocal);
export default Login;
