var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import DropDown from 'components/dropdown/dropdown';
import AssetViewSwitcher from 'components/ux/assetViewSwitcher';
import { USER_POST_REQUEST } from 'helpers/requests';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DashboardTemplate from 'templates/dashboard';
import NewGroupModal from './newGroupModal';
var mapState = function (state) { return ({
    AssetView: state.UI.AssetView,
}); };
var connector = connect(mapState, null);
var GroupsPage = /** @class */ (function (_super) {
    __extends(GroupsPage, _super);
    function GroupsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.addGroup = function (g) {
            var groups = _this.state.groups;
            _this.setState({
                groups: __spreadArray([g], groups, true)
            });
        };
        _this.loadGroup = function (id) {
            window.location.href = "/group/".concat(id);
        };
        _this.updateSort = function (method) {
            var _a = method.split("-"), v = _a[0], d = _a[1];
            var p = _this.state.groups;
            if (v == "updated") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.updated < b.updated ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.updated > b.updated ? 1 : -1); });
                }
            }
            if (v == "name") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); });
                }
            }
            if (_this.state.searchValue != "") {
                var sr = _this.state.searchResults;
                if (v == "updated") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.updated < b.updated ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.updated > b.updated ? 1 : -1); });
                    }
                }
                if (v == "name") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); });
                    }
                }
                _this.setState({
                    searchResults: sr,
                });
            }
            _this.setState({
                sortValue: method,
            });
        };
        _this.updateSearchInput = function (e) {
            var value = e.target.value;
            _this.setState({
                searchValue: value,
                searchResults: _this.state.groups.filter(function (p) { return p.name.substring(0, value.length).toLowerCase() == value.toLowerCase(); })
            });
        };
        _this.state = {
            groups: [],
            searchValue: "",
            sortValue: "updated-DESC",
            searchResults: [],
            modalOpen: false,
        };
        _this.addGroup = _this.addGroup.bind(_this);
        _this.updateSort = _this.updateSort.bind(_this);
        _this.updateSearchInput = _this.updateSearchInput.bind(_this);
        _this.loadGroup = _this.loadGroup.bind(_this);
        _this.toggleModal = _this.toggleModal.bind(_this);
        return _this;
    }
    GroupsPage.prototype.toggleModal = function () {
        var modalOpen = this.state.modalOpen;
        this.setState({ modalOpen: !modalOpen });
    };
    GroupsPage.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST("get-groups", {}, function (res) {
            _this.setState({
                groups: res.groups || []
            });
        }, function (err) {
            console.log(err);
        });
    };
    GroupsPage.prototype.render = function () {
        var _this = this;
        var AssetView = this.props.AssetView;
        var g_display = this.state.searchValue == '' ? this.state.groups : this.state.searchResults;
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container-fluid groups-page" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "breadcrumbs" },
                                React.createElement(Link, { to: "/", className: "font-alt" }, "<",
                                    " HOME"))),
                        React.createElement("div", { className: "row align-items-center justify-content-between" },
                            React.createElement("div", { className: "groups-heading" }, "Groups"),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("button", { className: "btn btn-blue", onClick: this.toggleModal },
                                    "CREATE NEW GROUP ",
                                    React.createElement("i", { className: "icon-add" })))))),
                React.createElement("div", { className: "row asset-controls" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center justify-content-between" },
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(DropDown, { valueIdentifier: "SORT: ", value: this.state.sortValue, clickAction: this.updateSort, options: [{ text: "Name Z-A", value: "name-DESC" }, { text: "Name A-Z", value: "name-ASC" }, { text: "Newest", value: "updated-DESC" }, { text: "Oldest", value: "updated-ASC" }] })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("i", { className: "icon-search" }),
                                React.createElement("input", { className: "input search", placeholder: "Search Groups", onChange: this.updateSearchInput, value: this.state.searchValue })),
                            React.createElement("div", { className: "col-auto" },
                                React.createElement(AssetViewSwitcher, null))))),
                React.createElement("div", { className: "groups-list row" },
                    React.createElement("div", { className: "container" }, AssetView == 'list' ?
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("table", { className: "assets-table" },
                                    React.createElement("tbody", null, g_display.map(function (g, i) {
                                        return React.createElement(GroupTableDisplay, { group: g, viewGroup: _this.loadGroup, key: i });
                                    })))))
                        :
                            React.createElement("div", { className: "row" }, g_display.map(function (g, i) {
                                return React.createElement(GroupPreview, { group: g, viewGroup: _this.loadGroup, key: i });
                            }))))),
            React.createElement(NewGroupModal, { addGroup: this.addGroup, toggle: this.toggleModal, open: this.state.modalOpen })));
    };
    return GroupsPage;
}(React.Component));
var GroupPreview = /** @class */ (function (_super) {
    __extends(GroupPreview, _super);
    function GroupPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GroupPreview.prototype.render = function () {
        var _this = this;
        var group = this.props.group;
        return (React.createElement("div", { className: "group-preview col-xl-3 col-lg-6 col-md-12 col-12" },
            React.createElement("div", { className: "row wrapper", onClick: function () { _this.props.viewGroup(group.id); } },
                React.createElement("div", { className: "col-auto thumbnail" },
                    React.createElement("img", { src: group.thumbnail == "" || group.thumbnail == null ? "/images/cover_images/4.png" : group.thumbnail })),
                React.createElement("div", { className: "col info" },
                    React.createElement("div", { className: "row info-content-wrapper align-items-center" },
                        React.createElement("h2", { className: "name" }, group.name))))));
    };
    return GroupPreview;
}(React.Component));
var GroupTableDisplay = /** @class */ (function (_super) {
    __extends(GroupTableDisplay, _super);
    function GroupTableDisplay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GroupTableDisplay.prototype.render = function () {
        var _this = this;
        var group = this.props.group;
        return (React.createElement("tr", { onClick: function () { _this.props.viewGroup(group.id); } },
            React.createElement("td", null,
                React.createElement("img", { src: group.thumbnail == "" || group.thumbnail == null ? "/images/cover_images/4.png" : group.thumbnail })),
            React.createElement("td", null, group.name)));
    };
    return GroupTableDisplay;
}(React.Component));
export default connector(GroupsPage);
