var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { FileType } from "helpers/fileInfo";
import Tempid from "../../helpers/tempids";
import { recursiveFileInsert } from "./files/recursiveFileInsert";
import { GetFolderInfo } from "./folders/info";
import { recursiveFolderInsert } from "./folders/recursiveFolderInsert";
export function handleDirectory(directory, parent) {
    var _this = this;
    if (parent === void 0) { parent = ""; }
    if (directory.name == "node_modules" || directory.name == ".git") {
        return;
    }
    this.handleDirectory = handleDirectory.bind(this);
    var newFolder = {
        identifier: "folder",
        name: directory.name,
        id: Tempid(),
        new: true,
        in_folder: parent != "",
        parent_id: parent,
        folders: [],
        files: [],
    };
    if (parent != "") {
        this.setState(function (prev) { return ({
            folders: recursiveFolderInsert(newFolder, prev.folders)
        }); });
    }
    else {
        this.setState(function (prev) { return ({
            folders: __spreadArray(__spreadArray([], prev.folders, true), [newFolder], false)
        }); });
    }
    var reader = directory.createReader();
    reader.readEntries(function (entries) {
        entries.forEach(function (entry) {
            if (entry.isDirectory) {
                _this.handleDirectory(entry, newFolder.id);
            }
            else {
                entry.file(function (file) {
                    var newFile = {
                        identifier: "file",
                        id: Tempid(),
                        type: FileType(file),
                        name: file.name,
                        data: file,
                        size: file.size,
                        parent_id: newFolder.id,
                        in_folder: true,
                        new: true,
                    };
                    _this.setState(function (prev) { return ({
                        folders: recursiveFileInsert(newFile, prev.folders)
                    }); });
                });
            }
        });
    });
}
export function select(f) {
    if (f.data instanceof File) {
        f = GetFolderInfo(f);
    }
    this.setState({
        selected: f
    });
}
export function extensionToHLJS(extension) {
    var langMap = new Map();
    langMap.set('go', 'go');
    return langMap.get(extension);
}
