var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UpdateInput } from "helpers/inputHandlers";
import { POST_REQUEST } from "helpers/requests";
import React from "react";
import { Link } from "react-router-dom";
import { DefaultNoSideBar } from "templates/default";
var ForgotPassword = /** @class */ (function (_super) {
    __extends(ForgotPassword, _super);
    function ForgotPassword(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSubmit = function () {
            if (!_this.state.email) {
                _this.setState({ errors: ["Please enter your email address"] });
                return;
            }
            _this.setState({ isLoading: true, errors: null });
            POST_REQUEST("send-password-reset-link", {
                email: _this.state.email,
            }, function (res) {
                if (res.errors) {
                    if (res.errors[0].includes("no rows in result set")) {
                        _this.setState({
                            errors: ["User not found"],
                            isLoading: false,
                        });
                        return;
                    }
                    else {
                        _this.setState({
                            errors: [res.errors],
                            isLoading: false,
                        });
                    }
                    return;
                }
                else {
                    _this.setState({ stage: 2, isLoading: false });
                }
            }, function (err) {
                console.log(err);
                _this.setState({
                    errors: err || ["Something went wrong"],
                });
            });
        };
        _this.state = {
            email: "",
            stage: 1,
            errors: null,
            isLoading: false,
        };
        _this.updateInput = UpdateInput.bind(_this);
        return _this;
    }
    ForgotPassword.prototype.renderStage = function () {
        var _this = this;
        switch (this.state.stage) {
            case 1:
                return (React.createElement(React.Fragment, null,
                    React.createElement("p", null, "Enter your email address to reset your password."),
                    this.state.errors && (React.createElement("div", { className: "alert alert-danger" }, this.state.errors.map(function (error, index) { return (React.createElement("div", { key: index }, error)); }))),
                    React.createElement("div", { className: "field" },
                        React.createElement("label", { style: {
                                width: "100%",
                                marginTop: "12px",
                                display: "block",
                            }, htmlFor: "email" }, "Email:"),
                        React.createElement("input", { placeholder: "email", style: {
                                width: "100%",
                                margin: "10px 0",
                                padding: "12px",
                                border: "1px solid ",
                                borderRadius: "4px",
                                // color: "var(--colors-basic-light)",
                                background: "transparent",
                                marginTop: "12px",
                                display: "block",
                            }, type: "email", name: "email", onChange: this.updateInput, value: this.state.email })),
                    React.createElement("div", { className: "field py-2" },
                        React.createElement(Link, { to: "/login", className: " text-xs text-gray-300" }, "Login?")),
                    React.createElement("div", { className: "field" },
                        React.createElement("button", { onClick: this.handleSubmit, className: "btn btn-blue", disabled: this.state.isLoading }, this.state.isLoading ? "Sending..." : "Send Reset Link"))));
            case 2:
                return (React.createElement("div", { className: "success-message" },
                    React.createElement("h2", null, "Check Your Email"),
                    React.createElement("div", { className: "check-icon" }, "\u2713"),
                    React.createElement("p", null,
                        "We've sent a password reset link to",
                        " ",
                        React.createElement("strong", null, this.state.email)),
                    React.createElement("p", null, "Please check your inbox and follow the instructions to reset your password."),
                    React.createElement("p", { className: "small" },
                        "Didn't receive the email? Check your spam folder or",
                        " ",
                        React.createElement("button", { onClick: function () { return _this.setState({ stage: 1 }); }, className: "btn btn-link" }, "try again"))));
            default:
                return null;
        }
    };
    ForgotPassword.prototype.render = function () {
        return (React.createElement(DefaultNoSideBar, null,
            React.createElement("div", { className: "container-fluid forgot-password-page" },
                React.createElement("div", { className: "row align-items-center", style: { minHeight: "600px" } },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("div", { className: "col-6  hero border-r border-[#CAFC3D]" },
                                React.createElement("img", { className: "w-[75%]", src: "/images/IB_Glyph_Green.svg", alt: "Logo" })),
                            React.createElement("div", { className: "col-6 forget-password-form", style: {
                                    paddingLeft: "45px",
                                    paddingRight: "45px",
                                } }, this.renderStage()))))),
            React.createElement("style", null, "\n          .success-message {\n            text-align: center;\n            padding: 2rem;\n          }\n          .check-icon {\n            font-size: 48px;\n            color: var(--yellow-green);\n            margin: 1rem 0;\n          }\n          .small {\n            font-size: 0.9rem;\n            color: #666;\n            margin-top: 2rem;\n          }\n          .btn-link {\n            padding: 0;\n            border: none;\n            background: none;\n            color: var(--yellow-green);\n            text-decoration: underline;\n            cursor: pointer;\n          }\n          .alert {\n            padding: 1rem;\n            margin-bottom: 1rem;\n            border-radius: 4px;\n          }\n          .alert-danger {\n            background-color: #fff1f0;\n            color: #ff4d4f;\n            border: 1px solid #ffa39e;\n          }\n        ")));
    };
    return ForgotPassword;
}(React.Component));
export default ForgotPassword;
