export function recursiveFileRemove(removeFile, folders) {
    for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == removeFile.parent_id) {
            if (removeFile.new) {
                folders[i].files = folders[i].files.filter(function (filtered) { return filtered.id != removeFile.id; });
            }
            else {
                folders[i].files.find(function (found) { return found.id == removeFile.id; }).removed = true;
            }
            return folders;
        }
        if (folders[i].folders != undefined) {
            if (folders[i].folders.length > 0) {
                folders[i].folders = recursiveFileRemove(removeFile, folders[i].folders);
            }
        }
    }
    return folders;
}
