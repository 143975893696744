import axios from 'axios';
import Cookies from 'universal-cookie';
//
import { ENDPOINT } from '../config';
import { store } from '../redux/store';
export var USER_POST_REQUEST = function (url, data, callback, errorHandler) {
    var state = store.getState();
    var token = state.App.Auth.Token;
    var cookies = new Cookies();
    var organization = cookies.get("current_organization");
    var headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Organization': organization || 'personal',
    };
    axios.post(ENDPOINT.URL + url, data, { headers: headers }).then(function (res) {
        callback(res.data);
    }).catch(function (err) {
        errorHandler == undefined ? console.log(err) : errorHandler(err);
    });
};
export var POST_REQUEST = function (url, data, callback, errorHandler) {
    var cookies = new Cookies();
    var organization = cookies.get("current_organization");
    var headers = {
        'Content-Type': 'application/json',
        'Organization': organization || 'personal',
    };
    axios.post(ENDPOINT.URL + url, data, { headers: headers }).then(function (res) {
        callback(res.data);
    }).catch(function (err) {
        errorHandler == undefined ? console.log(err) : errorHandler(err);
    });
};
export var CREDENTIALED_REQUEST = function (url, data, callback, errorHandler) {
    var cookies = new Cookies();
    var organization = cookies.get("current_organization");
    var headers = {
        'Content-Type': 'application/json',
        'Organization': organization || 'personal',
    };
    axios.post(ENDPOINT.URL + url, data, { withCredentials: true, headers: headers }).then(function (res) {
        callback(res.data);
    }).catch(function (err) {
        errorHandler == undefined ? console.log(err) : errorHandler(err);
    });
};
export var FORMDATA_REQUEST = function (url, data, callback, errorHandler) {
    var state = store.getState();
    var cookies = new Cookies();
    var organization = cookies.get("current_organization");
    var token = state.App.Auth.Token;
    var headers = {
        'Authorization': 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
        'Organization': organization || 'personal',
    };
    axios.post(ENDPOINT.URL + url, data, { headers: headers }).then(function (res) {
        callback(res.data);
    }).catch(function (err) {
        errorHandler == undefined ? console.log(err) : errorHandler(err);
    });
};
export var FORMDATA_REQUEST_WITH_UPLOAD_PROGRESS = function (url, data, callback, progressCallback, errorHandler) {
    var state = store.getState();
    var cookies = new Cookies();
    var organization = cookies.get("current_organization");
    var token = state.App.Auth.Token;
    var headers = {
        'Authorization': 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
        'Organization': organization || 'personal',
    };
    axios.post(ENDPOINT.URL + url, data, { headers: headers, onUploadProgress: function (ProgressEvent) { progressCallback(ProgressEvent); } }).then(function (res) {
        callback(res.data);
    }).catch(function (err) {
        errorHandler == undefined ? console.log(err) : errorHandler(err);
    });
};
export var CREDENTIALED_FORMDATA_REQUEST = function (url, data, callback, errorHandler) {
    var headers = {
        "Content-Type": "multipart/form-data",
    };
    axios.post(ENDPOINT.URL + url, data, { withCredentials: true, headers: headers }).then(function (res) {
        callback(res.data);
    }).catch(function (err) {
        errorHandler == undefined ? console.log(err) : errorHandler(err);
    });
};
