var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import DropDown from "components/dropdown/dropdown";
import AssetViewSwitcher from "components/ux/assetViewSwitcher";
import { TimeSince } from "helpers/timesince";
import ProjectOverview from "pages/projects/projectOverview";
import React from "react";
var ProjectsView = /** @class */ (function (_super) {
    __extends(ProjectsView, _super);
    function ProjectsView(props) {
        var _this = _super.call(this, props) || this;
        _this.updateSort = function (method) {
            var _a = method.split("-"), v = _a[0], d = _a[1];
            var p = _this.props.projects;
            if (v == "created") {
                if (d == "DESC") {
                    p.sort(function (a, b) { return (a.created < b.created ? 1 : -1); });
                }
                else {
                    p.sort(function (a, b) { return (a.created > b.created ? 1 : -1); });
                }
            }
            if (v == "name") {
                if (d == "DESC") {
                    p.sort(function (a, b) {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
                    });
                }
                else {
                    p.sort(function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                    });
                }
            }
            if (_this.state.searchValue != "") {
                var sr = _this.state.searchResults;
                if (v == "created") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) { return (a.created < b.created ? 1 : -1); });
                    }
                    else {
                        sr.sort(function (a, b) { return (a.created > b.created ? 1 : -1); });
                    }
                }
                if (v == "name") {
                    if (d == "DESC") {
                        sr.sort(function (a, b) {
                            return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
                        });
                    }
                    else {
                        sr.sort(function (a, b) {
                            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                        });
                    }
                }
                _this.setState({
                    searchResults: sr,
                });
            }
            _this.setState({
                sortValue: method,
            });
        };
        _this.updateSearchInput = function (e) {
            var value = e.target.value;
            _this.setState({
                searchValue: value,
                searchResults: _this.props.projects.filter(function (p) {
                    return (p.name.substring(0, value.length).toLowerCase() == value.toLowerCase());
                }),
            });
        };
        //   viewProject = (id: string) => {
        //     id != null
        //       ? window.history.replaceState(null, "", `/organization/projects/${id}`)
        //       : window.history.replaceState(null, "", `/organization/projects`);
        //     this.setState({
        //       viewingProject: id,
        //     });
        //   };
        _this.viewProject = function (id) {
            _this.setState({
                viewingProject: id,
            }, function () {
                if (id != null && id != "") {
                    window.history.replaceState(null, "", "/organization/projects/".concat(id));
                }
                else {
                    window.history.replaceState(null, "", "/organization/projects");
                }
            });
        };
        _this.state = {
            viewingProject: null,
            sortValue: "created-DESC",
            searchValue: "",
            searchResults: [],
        };
        _this.viewProject = _this.viewProject.bind(_this);
        _this.updateSearchInput = _this.updateSearchInput.bind(_this);
        _this.updateSort = _this.updateSort.bind(_this);
        return _this;
    }
    ProjectsView.prototype.componentDidMount = function () {
        var project = "";
        // TODO : get project
        if (project != null) {
            this.viewProject(project);
        }
        else {
            this.viewProject(null);
        }
    };
    ProjectsView.prototype.render = function () {
        var _this = this;
        var _a = this.props, projects = _a.projects, assetsView = _a.assetsView;
        var _b = this.state, viewingProject = _b.viewingProject, searchResults = _b.searchResults, searchValue = _b.searchValue;
        return (React.createElement("div", { className: "container-fluid organization-page-new" }, viewingProject == null || viewingProject == "" ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row organization-header" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h4", { className: "overview" },
                                React.createElement("a", { onClick: function () {
                                        _this.props.updateView("default");
                                    } }, "<",
                                    " Back to Administration Dashboard")),
                            React.createElement("h1", { className: "font-alt font-light" },
                                "Organization Projects",
                                " "))))),
            React.createElement("div", { className: "row asset-controls" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row align-items-center justify-content-between" },
                        React.createElement("div", { className: "col-auto" },
                            React.createElement(DropDown, { valueIdentifier: "SORT: ", value: this.state.sortValue, clickAction: this.updateSort, options: [
                                    { text: "Name Z-A", value: "name-DESC" },
                                    { text: "Name A-Z", value: "name-ASC" },
                                    { text: "Newest", value: "created-DESC" },
                                    { text: "Oldest", value: "created-ASC" },
                                ] })),
                        React.createElement("div", { className: "col-auto" },
                            React.createElement("i", { className: "icon-search" }),
                            React.createElement("input", { className: "input search", placeholder: "Search Projects", onChange: this.updateSearchInput, value: searchValue })),
                        React.createElement("div", { className: "col-auto" },
                            React.createElement(AssetViewSwitcher, null))))),
            React.createElement("div", { className: "row organization-projects" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" }, assetsView == "list" ? (React.createElement("div", { className: "col-12" },
                        React.createElement("table", { className: "assets-table" },
                            React.createElement("tbody", null, searchValue != ""
                                ? searchResults.map(function (p, i) {
                                    return (React.createElement(ProjectTablePreview, { key: i, project: p, viewProject: _this.viewProject }));
                                })
                                : projects.map(function (p, i) {
                                    return (React.createElement(ProjectTablePreview, { key: i, project: p, viewProject: _this.viewProject }));
                                }))))) : searchValue != "" ? (searchResults.map(function (p, i) {
                        return (React.createElement(ProjectPreview, { key: i, project: p, viewProject: _this.viewProject }));
                    })) : (projects.map(function (p, i) {
                        return (React.createElement(ProjectPreview, { key: i, project: p, viewProject: _this.viewProject }));
                    }))))))) : (React.createElement("div", { className: "row" },
            React.createElement(ProjectOverview, { back: function () {
                    _this.viewProject(null);
                }, id: this.state.viewingProject, isAdmin: true, isLeader: true, users: this.props.members, groups: this.props.groups, userID: "" })))));
    };
    return ProjectsView;
}(React.Component));
var ProjectTablePreview = /** @class */ (function (_super) {
    __extends(ProjectTablePreview, _super);
    function ProjectTablePreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectTablePreview.prototype.render = function () {
        var _a = this.props, project = _a.project, viewProject = _a.viewProject;
        return (React.createElement("tr", { onClick: function () {
                viewProject(project.id);
            } },
            React.createElement("td", null,
                React.createElement("img", { className: "thumbnail", src: project.thumbnail == "" || project.thumbnail == null
                        ? "/images/cover_images/1.png"
                        : project.thumbnail }),
                " "),
            React.createElement("td", null,
                React.createElement("b", null, project.name)),
            React.createElement("td", { className: "font-alt" },
                project.idea_count,
                " IDEAS"),
            React.createElement("td", { className: "updated" },
                "Updated ",
                TimeSince(project.updated),
                " ago")));
    };
    return ProjectTablePreview;
}(React.Component));
var ProjectPreview = /** @class */ (function (_super) {
    __extends(ProjectPreview, _super);
    function ProjectPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectPreview.prototype.render = function () {
        var _a = this.props, project = _a.project, viewProject = _a.viewProject;
        return (React.createElement("div", { className: "project-preview col-xl-4 col-lg-6 col-md-12 col-12" },
            React.createElement("div", { className: "row wrapper", onClick: function () {
                    viewProject(project.id);
                } },
                React.createElement("div", { className: "content row" },
                    React.createElement("div", { className: "thumbnail" },
                        React.createElement("img", { src: project.thumbnail == "" || project.thumbnail == null
                                ? "/images/cover_images/1.png"
                                : project.thumbnail })),
                    React.createElement("div", { className: "info" },
                        React.createElement("div", { className: "row info-content-wrapper align-items-center" },
                            React.createElement("h2", { className: "name" }, project.name),
                            React.createElement("h4", { className: "font-alt-2" },
                                project.idea_count,
                                " IDEAS")))))));
    };
    return ProjectPreview;
}(React.Component));
export default ProjectsView;
