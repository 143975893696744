import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
//Local imports 
import { AppReducer } from './app/reducers';
import { UIReducer } from './ui/reducers';
export var store = null;
export default function createAppStore(initialState) {
    var state = initialState;
    var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    var enhancers = composeEnhancers(applyMiddleware(thunk));
    var CombinedReducer = combineReducers({
        App: AppReducer,
        UI: UIReducer,
    });
    store = createStore(CombinedReducer, state, enhancers);
}
