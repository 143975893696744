var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { ArchivedIdeaPreview, GroupPreview, MemberPreview, ProjectPreview } from "./previews";
import UserSVG from "./userSVG";
import Archive from "components/Icons/ArchiveIcon";
import { USER_POST_REQUEST } from "helpers/requests";
var DefaultView = /** @class */ (function (_super) {
    __extends(DefaultView, _super);
    function DefaultView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DefaultView.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST("get-archived-ideas", {}, function (res) {
            _this.setState({
                ideas: res.ideas || [],
            });
        }, function (err) {
            console.log(err);
        });
    };
    DefaultView.prototype.render = function () {
        var _this = this;
        var _a = this.props, idea_count = _a.idea_count, group_count = _a.group_count, project_count = _a.project_count, name = _a.name, logo = _a.logo, membersPreview = _a.membersPreview, groupsPreview = _a.groupsPreview, projectsPreview = _a.projectsPreview, user_seats = _a.user_seats, admin_seats = _a.admin_seats, updateView = _a.updateView;
        console.log(this.props);
        return (React.createElement("div", { className: "container-fluid organization-page-new" },
            React.createElement("div", { className: "row default-header" },
                React.createElement("div", { className: "container" },
                    React.createElement("h1", null, "Administration Dashboard"))),
            React.createElement("div", { className: "row section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12 card" },
                            React.createElement("div", { className: "content-wrapper" },
                                React.createElement("div", { className: "row card-header" },
                                    React.createElement("div", { className: "col-auto title font-alt-2" },
                                        React.createElement("i", { className: "icon-info" }),
                                        " Assets")),
                                React.createElement("div", { className: "row card-content org-stats" },
                                    React.createElement("div", { className: "col-4 br" },
                                        React.createElement("h2", { className: "font-alt" },
                                            React.createElement("i", { className: "icon-check_bubble" }),
                                            " ",
                                            idea_count,
                                            " IDEAS")),
                                    React.createElement("div", { className: "col-4 br" },
                                        React.createElement("h2", { className: "font-alt" },
                                            React.createElement("i", { className: "icon-projects" }),
                                            " ",
                                            project_count,
                                            " PROJECTS")),
                                    React.createElement("div", { className: "col-4" },
                                        React.createElement("h2", { className: "font-alt" },
                                            React.createElement("i", { className: "icon-group" }),
                                            " ",
                                            group_count,
                                            " GROUPS")))))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-6 card" },
                            React.createElement("div", { className: "content-wrapper" },
                                React.createElement("div", { className: "row card-header justify-content-between align-items-center" },
                                    React.createElement("div", { className: "col-auto title font-alt-2" },
                                        React.createElement("i", { className: "icon-settings" }),
                                        " Org Logo/Name"),
                                    React.createElement("div", { className: "col-auto change", onClick: function () {
                                            _this.props.updateView("settings");
                                        } }, "Manage")),
                                React.createElement("div", { className: "row card-content align-items-center" },
                                    React.createElement("div", { className: "col-auto" },
                                        React.createElement("img", { className: "logo", src: "".concat(logo != "" && logo != "none"
                                                ? logo
                                                : "/images/IB_Glyph_Green.svg") })),
                                    React.createElement("div", { className: "col t-center" },
                                        React.createElement("h2", null, name))))),
                        React.createElement("div", { className: "col-6 card" },
                            React.createElement("div", { className: "content-wrapper" },
                                React.createElement("div", { className: "row card-header justify-content-between align-items-center" },
                                    React.createElement("div", { className: "col-auto title font-alt-2" },
                                        React.createElement("i", { className: "icon-permissions" }),
                                        " Seats"),
                                    React.createElement("div", { className: "col-auto change", onClick: function () {
                                            _this.props.updateView("seats");
                                        } }, "Manage")),
                                React.createElement("div", { className: "row card-content" },
                                    React.createElement("div", { className: "col-6 t-center" },
                                        React.createElement("h1", null, admin_seats || 1),
                                        React.createElement("h4", { className: "font-alt font-light" }, "Admin Seats")),
                                    React.createElement("div", { className: "col-6 t-center" },
                                        React.createElement("h1", null, user_seats || 0),
                                        React.createElement("h4", { className: "font-alt font-light" }, "User Seats")))))))),
            React.createElement("div", { className: "row section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row section-header justify-content-between align-items-center" },
                        React.createElement("div", { className: "col-auto" },
                            React.createElement("h2", { className: "font-alt-2" },
                                React.createElement("i", { className: "icon-project" }),
                                " ORGANIZATION PROJECTS")),
                        React.createElement("div", { className: "col-auto" },
                            React.createElement("a", { className: "view-all", onClick: function () {
                                    _this.props.updateView("projects");
                                } }, "VIEW ALL PROJECTS")),
                        React.createElement("div", { className: "col-12" },
                            React.createElement("hr", null))),
                    React.createElement("div", { className: "row" }, projectsPreview.map(function (p, i) {
                        if (i > 2) {
                            return null;
                        }
                        return (React.createElement(ProjectPreview, { viewProject: function () {
                                _this.props.viewProject(p.id);
                            }, key: i, project: p }));
                    })))),
            React.createElement("div", { className: "row section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row section-header justify-content-between align-items-center" },
                        React.createElement("div", { className: "col-auto" },
                            React.createElement("h2", { className: "font-alt-2" },
                                React.createElement("i", { className: "icon-group" }),
                                " ORGANIZATION GROUPS")),
                        React.createElement("div", { className: "col-auto" },
                            React.createElement("a", { onClick: function () {
                                    _this.props.updateView("groups");
                                }, className: "view-all" }, "VIEW ALL GROUPS")),
                        React.createElement("div", { className: "col-12" },
                            React.createElement("hr", null))),
                    React.createElement("div", { className: "row" }, groupsPreview.map(function (g, i) {
                        if (i > 2) {
                            return null;
                        }
                        return (React.createElement(GroupPreview, { viewGroup: function () {
                                _this.props.viewGroup(g.id);
                            }, key: i, group: g }));
                    })))),
            React.createElement("div", { className: "row section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row section-header  justify-content-between align-items-center" },
                        React.createElement("div", { className: "col-auto " },
                            React.createElement("h2", { className: "font-alt-2  flex items-center" },
                                React.createElement(UserSVG, null),
                                " ORGANIZATION MEMBERS")),
                        React.createElement("div", { className: "col-auto" },
                            React.createElement("a", { className: "view-all", onClick: function () {
                                    _this.props.updateView("members");
                                } }, "VIEW ALL MEMBERS")),
                        React.createElement("div", { className: "col-12" },
                            React.createElement("hr", null))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("table", { className: "members-table" },
                                React.createElement("tbody", null, membersPreview.map(function (m, i) {
                                    if (i > 2) {
                                        return null;
                                    }
                                    return (React.createElement(MemberPreview, { viewMember: function () {
                                            _this.props.viewMember(m.id);
                                        }, key: i, member: m }));
                                }))),
                            React.createElement("hr", { className: "tp" }),
                            React.createElement("h3", { className: "t-center" },
                                React.createElement("a", { className: "view-all-members", onClick: function () {
                                        _this.props.updateView("members");
                                    } }, "View All Members")))))),
            React.createElement("div", { className: "row section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row section-header  justify-content-between align-items-center" },
                        React.createElement("div", { className: "col-auto " },
                            React.createElement("h2", { className: "font-alt-2  flex gap-2 items-center" },
                                React.createElement("span", { className: "text-[#217AFA]" },
                                    React.createElement(Archive, null)),
                                "Archived Ideas")),
                        React.createElement("div", { className: "col-auto" },
                            React.createElement("a", { className: "view-all", onClick: function () {
                                    _this.props.updateView("archivedidea");
                                } }, "View All Archived Ideas")),
                        React.createElement("div", { className: "col-12" },
                            React.createElement("hr", null))),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement(ArchivedIdeaPreview, null),
                            React.createElement("hr", { className: "tp" }),
                            React.createElement("h3", { className: "t-center" },
                                React.createElement("a", { className: "view-all-members", onClick: function () {
                                        _this.props.updateView("archivedidea");
                                    } }, "View All Archived Ideas"))))))));
    };
    return DefaultView;
}(React.Component));
export default DefaultView;
