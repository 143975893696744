var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UnixToDate } from 'helpers/dates';
import { NumberCommas } from 'helpers/numbers';
import { USER_POST_REQUEST } from 'helpers/requests';
import React from 'react';
import { Link } from 'react-router-dom';
import DashboardTemplate from 'templates/dashboard';
var AssetsLocal = /** @class */ (function (_super) {
    __extends(AssetsLocal, _super);
    function AssetsLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            ideas: [],
            info: {
                total_ideas: 0,
                projects: 0,
                since: 0,
            }
        };
        _this.loadIdea = _this.loadIdea.bind(_this);
        return _this;
    }
    AssetsLocal.prototype.loadIdea = function (id) {
    };
    AssetsLocal.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST('display/assets', {}, function (res) {
            console.log(res);
            _this.setState({
                info: res,
            });
        }, function (err) {
            console.log(err);
        });
        USER_POST_REQUEST('get-user-ideas', {}, function (res) {
            _this.setState({
                ideas: res.ideas || [],
            });
        }, function (err) {
            console.log(err);
        });
    };
    AssetsLocal.prototype.render = function () {
        var info = this.state.info;
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container-fluid assets-page" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "container-fluid", style: { padding: '0 50px' } },
                        React.createElement("div", { className: "row" },
                            React.createElement("h1", { className: "assets-heading" }, "Assets"),
                            React.createElement("div", { className: "col-12 header" },
                                React.createElement("div", { className: "row assets-container align-items-center" },
                                    React.createElement("div", { className: "col-4 item br t-center" },
                                        React.createElement("div", { className: "row align-items-center justify-content-center" },
                                            React.createElement("i", { className: "icon-allassets asset-icon" }),
                                            React.createElement("div", { className: "asset-count font-alt" }, NumberCommas(info.total_ideas)),
                                            React.createElement("div", { className: "asset-description" },
                                                React.createElement("span", { className: "upper font-alt" }, "TOTAL"),
                                                React.createElement("span", { className: "lower" },
                                                    "since ",
                                                    UnixToDate(info.since))))),
                                    React.createElement("div", { className: "col-4 item br t-center" },
                                        React.createElement("div", { className: "row align-items-center justify-content-center" },
                                            React.createElement("i", { className: "icon-projects asset-icon" }),
                                            React.createElement("div", { className: "asset-count font-alt" }, NumberCommas(info.projects)),
                                            React.createElement("div", { className: "asset-description" },
                                                React.createElement("span", { className: "upper font-alt" }, "PROJECTS"),
                                                React.createElement("span", { className: "lower" }, "All Groups")))),
                                    React.createElement("div", { className: "col-4 item t-center" },
                                        React.createElement("span", { className: "" }, "No Ideas Pending Verification")))))),
                    React.createElement("div", { className: "container-fluid" },
                        React.createElement("div", { className: "row projects-section", style: { position: "relative" } },
                            React.createElement("div", { className: "container-fluid", style: { padding: '0 50px' } },
                                React.createElement("div", { className: "row" },
                                    React.createElement("h2", { className: "font-alt-2 heading" },
                                        React.createElement("i", { className: "icon-favorite_filled" }),
                                        "TOP PROJECTS",
                                        React.createElement(Link, { to: "/assets/projects" },
                                            "View all projects   ",
                                            '>')),
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("div", { className: "row" }, "No Projects currently.")))))),
                    React.createElement("div", { className: "container-fluid", style: { padding: '0 50px' } },
                        React.createElement("div", { className: "row ideas-section" },
                            React.createElement("h2", { className: "font-alt-2 heading" },
                                React.createElement("i", { className: "icon-pinned" }),
                                "PINNED IDEAS",
                                React.createElement(Link, { to: "/assets/ideas" },
                                    "View all ideas   ",
                                    '>'))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "row" }, "No Pinned Ideas yet"))))))));
    };
    return AssetsLocal;
}(React.Component));
var AssetsPage = AssetsLocal;
export default AssetsPage;
