var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { connect } from 'react-redux';
import TopNavTemplate from 'templates/topNav';
import { Link } from 'react-router-dom';
import { UpdateInput } from 'helpers/inputHandlers';
import DropDown from 'components/dropdown/dropdown';
var mapState = function (state) {
    var _a, _b, _c, _d;
    return ({
        LoggedIn: state.App.LoggedIn,
        User: state.App.User,
        Organization: state.App.Organization,
        SidebarExpanded: state.UI.SidebarExpanded,
        Socket: state.App.Socket,
        SocketStatus: state.App.SocketStatus,
        OrganizationName: (_b = (_a = state.App) === null || _a === void 0 ? void 0 : _a.Organization) === null || _b === void 0 ? void 0 : _b.name,
        OrganizationLogo: (_d = (_c = state.App) === null || _c === void 0 ? void 0 : _c.Organization) === null || _d === void 0 ? void 0 : _d.logo,
        ProfilePhoto: state.App.User.avatar,
        FirstName: state.App.User.first_name,
        LastName: state.App.User.last_name,
    });
};
var mapDispatch = {
    UpdateUserOrganization: function (organization) { return ({ type: 'UPDATE_USER_ORGANIZATION', organization: organization }); },
    Logout: function () { return ({ type: 'LOGOUT' }); }
};
var connector = connect(mapState, mapDispatch);
var UserTopNavLocal = /** @class */ (function (_super) {
    __extends(UserTopNavLocal, _super);
    function UserTopNavLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.handleKeyDown = function (e) {
            if (e.key == "Enter") {
                _this.search();
            }
        };
        _this.updateSearchValue = function (value) {
            _this.setState({
                objectType: value,
            });
        };
        _this.state = {
            NewIdeaUpdate: false,
            IdeaUpdate: null,
            User: _this.props.User,
            query: '',
            objectType: 'all'
        };
        _this.closeIdeaUpdate = _this.closeIdeaUpdate.bind(_this);
        _this.updateInput = UpdateInput.bind(_this);
        _this.handleKeyDown = _this.handleKeyDown.bind(_this);
        _this.updateSearchValue = _this.updateSearchValue.bind(_this);
        return _this;
    }
    UserTopNavLocal.prototype.search = function () {
        var objectType = this.state.objectType;
        window.location.href = "/search?q=".concat(this.state.query).concat(objectType != "all" ? "&t=".concat(objectType) : "");
    };
    UserTopNavLocal.prototype.closeIdeaUpdate = function () {
        this.setState({
            NewIdeaUpdate: false,
            IdeaUpdate: null,
        });
    };
    UserTopNavLocal.prototype.componentDidMount = function () {
        var _this = this;
        var Socket = this.props.Socket;
        if (Socket == null) {
            return;
        }
        Socket.on("idea-update", function (msg) {
            var data = JSON.parse(msg);
            _this.setState({
                NewIdeaUpdate: true,
                IdeaUpdate: data,
            });
        });
        this.props.Socket.on("test", function (data) {
        });
    };
    UserTopNavLocal.prototype.componentWillUnmount = function () {
        if (this.props.Socket == null) {
            return;
        }
        this.props.Socket.off("test");
        this.props.Socket.off("idea-update");
        this.props.Socket.off("notification");
    };
    UserTopNavLocal.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var prevUser = this.state.User;
        var nextUser = this.props.User;
        if (prevUser.avatar != nextUser.avatar) {
            this.setState({
                User: this.props.User,
            });
        }
        if (prevUser.last_name != nextUser.last_name) {
            this.setState({
                User: this.props.User,
            });
        }
    };
    UserTopNavLocal.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        var Organization = this.props.Organization;
        var _d = this.state, NewIdeaUpdate = _d.NewIdeaUpdate, IdeaUpdate = _d.IdeaUpdate, User = _d.User;
        return (React.createElement(React.Fragment, null,
            React.createElement(TopNavTemplate, null,
                ((_a = Organization === null || Organization === void 0 ? void 0 : Organization.whitelabel) === null || _a === void 0 ? void 0 : _a.link) != "" ?
                    React.createElement("a", { target: "_blank", href: (_b = Organization === null || Organization === void 0 ? void 0 : Organization.whitelabel) === null || _b === void 0 ? void 0 : _b.link, className: "whitelabel" },
                        React.createElement("img", { style: { width: "100%", height: "auto" }, src: (_c = Organization === null || Organization === void 0 ? void 0 : Organization.whitelabel) === null || _c === void 0 ? void 0 : _c.logo }))
                    : null,
                React.createElement("div", { className: "col-4" },
                    React.createElement(OrganizationSwitcher, null)),
                window.location.pathname != "/search" && window.location.pathname != "/ideabase" ?
                    React.createElement("div", { className: "col-4 top-nav-search" },
                        React.createElement(DropDown, { options: [{ value: "all", text: "All" }, { value: "private_ideas", text: "Private Ideas" }, { value: "ideabase", text: "Ideabase" }, { value: "projects", text: "Projects" }, { value: "groups", text: "Groups" }], clickAction: this.updateSearchValue, value: this.state.objectType }),
                        React.createElement("input", { type: "text", name: "query", onKeyDown: this.handleKeyDown, placeholder: "Search your projects ".concat('&', " ideas"), value: this.state.query, onChange: this.updateInput }),
                        React.createElement("i", { className: "icon-search", onClick: function () { _this.search(); } })) : React.createElement("div", { className: "col-4" }),
                React.createElement("div", { className: "col-4" },
                    React.createElement("div", { className: "nav-right row justify-content-end align-items-center" }, "".concat(this.props.FirstName, " ").concat(this.props.LastName),
                        this.props.ProfilePhoto != null && this.props.ProfilePhoto != "" ?
                            React.createElement("img", { src: this.props.ProfilePhoto, className: "avatar" })
                            : React.createElement("img", { src: "/images/default_avatar.jpg", className: "avatar" })))),
            NewIdeaUpdate ?
                React.createElement("div", { className: "idea-update ".concat(IdeaUpdate.type, " row align-items-center justify-content-center") },
                    React.createElement("i", { className: "icon-verified" }),
                    React.createElement("span", { className: "title" }, IdeaUpdate.title),
                    " has been successfully protected",
                    React.createElement(Link, { to: "/idea/".concat(IdeaUpdate.id), className: "font-alt btn btn-blue-alt" }, "View Idea"),
                    React.createElement("span", { className: "dismiss h-c", onClick: this.closeIdeaUpdate },
                        React.createElement("i", { onClick: this.closeIdeaUpdate, className: "icon-cancel" }),
                        " Dismiss"))
                : null));
    };
    return UserTopNavLocal;
}(React.Component));
var OrganizationSwitcher_NODUX = /** @class */ (function (_super) {
    __extends(OrganizationSwitcher_NODUX, _super);
    function OrganizationSwitcher_NODUX() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OrganizationSwitcher_NODUX.prototype.componentDidMount = function () {
    };
    OrganizationSwitcher_NODUX.prototype.shouldComponentUpdate = function (nextProps, nextState, nextContext) {
        console.log(nextProps);
        return true;
    };
    OrganizationSwitcher_NODUX.prototype.render = function () {
        var _a = this.props, Organization = _a.Organization, User = _a.User;
        if (User.account_type == "organization") {
            return (React.createElement("div", { className: "row align-items-center organization-switcher single" },
                React.createElement("img", { className: "logo", src: this.props.OrganizationLogo != null && this.props.OrganizationLogo != "none" ? this.props.OrganizationLogo : "/icons/project.svg" }),
                React.createElement("a", null,
                    React.createElement("span", null, this.props.OrganizationName))));
        }
        return (React.createElement("div", { className: "organization-switcher" }, this.props.OrganizationName));
    };
    return OrganizationSwitcher_NODUX;
}(React.Component));
var OrganizationSwitcher = connector(OrganizationSwitcher_NODUX);
var UserTopNav = connector(UserTopNavLocal);
export default UserTopNav;
