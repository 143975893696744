var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var BTCIcon = /** @class */ (function (_super) {
    __extends(BTCIcon, _super);
    function BTCIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BTCIcon.prototype.render = function () {
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 32 32" },
            React.createElement("g", { fill: "none", fillRule: "evenodd" },
                React.createElement("circle", { cx: "16", cy: "16", r: "16", fill: "#F7931A" }),
                React.createElement("path", { fill: "#FFF", fillRule: "nonzero", d: "M23.189 14.02c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84-1.728-.43-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009-2.384-.595-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045-1.13 4.532c-.086.212-.303.531-.793.41.018.025-1.256-.313-1.256-.313l-.858 1.978 2.25.561c.418.105.828.215 1.231.318l-.715 2.872 1.727.43.708-2.84c.472.127.93.245 1.378.357l-.706 2.828 1.728.43.715-2.866c2.948.558 5.164.333 6.097-2.333.752-2.146-.037-3.385-1.588-4.192 1.13-.26 1.98-1.003 2.207-2.538zm-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11zm.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733z" }))));
    };
    return BTCIcon;
}(React.Component));
export { BTCIcon };
var LTCIcon = /** @class */ (function (_super) {
    __extends(LTCIcon, _super);
    function LTCIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LTCIcon.prototype.render = function () {
        return (React.createElement("svg", { className: "ltc", xmlns: "http://www.w3.org/2000/svg", width: "32", height: "32", viewBox: "0 0 32 32" },
            React.createElement("g", { fill: "none", fillRule: "evenodd" },
                React.createElement("circle", { cx: "16", cy: "16", r: "16", fill: "#BFBBBB" }),
                React.createElement("path", { fill: "#FFF", d: "M10.427 19.214L9 19.768l.688-2.759 1.444-.58L13.213 8h5.129l-1.519 6.196 1.41-.571-.68 2.75-1.427.571-.848 3.483H23L22.127 24H9.252z" }))));
    };
    return LTCIcon;
}(React.Component));
export { LTCIcon };
