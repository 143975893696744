var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FileView, FolderView } from 'components/fileHandler/components/list';
import { FileType } from 'helpers/fileInfo';
import TempID from 'helpers/tempids';
import React from 'react';
var AddFiles = /** @class */ (function (_super) {
    __extends(AddFiles, _super);
    function AddFiles(props) {
        var _this = _super.call(this, props) || this;
        _this.preventDefault = function (e) {
            e.preventDefault();
            e.stopPropagation();
        };
        _this.dropHandler = function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer && e.dataTransfer.items) {
                for (var i = 0; i < e.dataTransfer.items.length; i++) {
                    var item = e.dataTransfer.items[i].webkitGetAsEntry();
                    if (item.isDirectory) {
                        _this.props.actionHandler("ADD_FOLDER_DT", item);
                    }
                    else {
                        var file = e.dataTransfer.items[i].getAsFile();
                        _this.props.actionHandler("ADD_FILE", {
                            name: file.name,
                            identifier: "file",
                            type: FileType(file),
                            size: file.size,
                            in_folder: false,
                            id: TempID(),
                            new: true,
                            data: file,
                        });
                    }
                }
            }
            else {
            }
        };
        _this.handleFile = function (e) {
            var files = e.target.files;
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                _this.props.actionHandler("ADD_FILE", {
                    name: file.name,
                    identifier: "file",
                    type: FileType(file),
                    size: file.size,
                    in_folder: false,
                    id: TempID(),
                    new: true,
                    data: file,
                });
            }
        };
        _this.dropHandler = _this.dropHandler.bind(_this);
        _this.handleFile = _this.handleFile.bind(_this);
        _this.fileProxy = _this.fileProxy.bind(_this);
        _this.fileProxyRef = React.createRef();
        return _this;
    }
    AddFiles.prototype.fileProxy = function () {
        this.fileProxyRef.current.click();
    };
    AddFiles.prototype.render = function () {
        var _this = this;
        var _a = this.props, folders = _a.folders, files = _a.files;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "add-files-header font-alt col-12" }, "Upload Files")),
            React.createElement("input", { className: "d-none", ref: this.fileProxyRef, onChange: this.handleFile, type: "file", multiple: true }),
            React.createElement("div", { className: "row file-uploader-wrapper" },
                React.createElement("div", { className: "file-uploader-panel-wrapper ".concat(folders.length + files.length == 0 ? "col-12" : "col-8 files-present") },
                    React.createElement("div", { className: "row justify-content-center align-items-center file-uploader-panel h-c", onDragEnter: this.preventDefault, onDragLeave: this.preventDefault, onDragOver: this.preventDefault, onDrop: this.dropHandler, onClick: this.fileProxy },
                        React.createElement("div", { className: "file-uploader-text" },
                            React.createElement("i", { className: "icon-cloud" }),
                            React.createElement("h2", { className: "font-alt" },
                                "Drag files and folders here or ",
                                React.createElement("u", null, "click"),
                                " to upload"),
                            React.createElement("h4", null, "We support all standard file types including Word, Powerpoint, JPG, PDF, MOVE, and MP4s etc.")))),
                React.createElement("div", { className: "file-list-panel-wrapper  ".concat(folders.length + files.length == 0 ? "d-none" : "col-4 files-present") },
                    React.createElement("div", { className: "file-list-panel" },
                        folders.map(function (folder, index) {
                            return (React.createElement(FolderView, { index: "0", depth: "0", parentTree: "", actionHandler: _this.props.actionHandler, folder: folder, key: index }));
                        }),
                        files.map(function (file, index) {
                            return (React.createElement(FileView, { actionHandler: _this.props.actionHandler, file: file, key: index }));
                        }))))));
    };
    return AddFiles;
}(React.Component));
export default AddFiles;
