import { UPDATE_APP_THEME, SET_APP_THEME, TOGGLE_SIDEBAR, UPDATE_ASSET_VIEW } from "redux/ui/types";
import { handleUpdatedAssetView, handleUpdatedTheme } from './functions';
export function UIReducer(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return Object.assign({}, state, {
                SidebarExpanded: !state.SidebarExpanded
            });
        case SET_APP_THEME:
            return Object.assign({}, state, {
                Theme: action.theme
            });
        case UPDATE_APP_THEME:
            handleUpdatedTheme(action.theme);
            return Object.assign({}, state, {
                Theme: action.theme
            });
        case UPDATE_ASSET_VIEW:
            handleUpdatedAssetView(action.view);
            return Object.assign({}, state, {
                AssetView: action.view,
            });
        default:
            return state;
    }
}
