var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { USER_POST_REQUEST } from "helpers/requests";
import React from "react";
import { connect } from "react-redux";
import DashboardTemplate from "templates/dashboard";
import DefaultView from "./defaultView";
import GroupsView from "./groupsView";
import MembersView from "./membersView";
import ProjectsView from "./projectsView";
import SeatsView from "./seatsView";
import SettingsView from "./settingsView";
import ArchivedIdeaView from "./archivedIdeaView";
var mapState = function (state) { return ({
    Organization: state.App.Organization,
    User: state.App.User,
    Socket: state.App.Socket,
    AssetView: state.UI.AssetView,
}); };
var mapDispatch = {
    UpdateOrganization: function (org) { return ({
        type: "UPDATE_APP_ORGANIZATION",
        organization: org,
    }); },
};
var connector = connect(mapState, mapDispatch);
var NewOrganizationPage = /** @class */ (function (_super) {
    __extends(NewOrganizationPage, _super);
    function NewOrganizationPage(props) {
        var _this = _super.call(this, props) || this;
        _this.updateOrganizationName = function (name) {
            var Organization = _this.props.Organization;
            Organization.name = name;
            _this.props.UpdateOrganization(Organization);
        };
        _this.updateOrganizationLogo = function (logo) {
            var Organization = _this.props.Organization;
            Organization.logo = logo;
            _this.props.UpdateOrganization(Organization);
        };
        _this.updateGroup = function (group) {
            var groups = _this.state.groups;
            for (var i = 0; i < groups.length; i++) {
                var g = groups[i];
                if (g.id == group.id) {
                    groups[i] = group;
                }
            }
            _this.setState({
                groups: groups,
            });
        };
        _this.addGroup = function (group) {
            var groups = _this.state.groups;
            _this.setState({
                groups: __spreadArray([group], groups, true),
            });
        };
        _this.removeGroup = function (group) {
            var groups = _this.state.groups;
            _this.setState({
                groups: groups.filter(function (g) {
                    return g.id != group.id;
                }),
            });
        };
        _this.updateView = function (newView) {
            var routeView = "";
            if (newView == "default") {
                routeView = "";
            }
            else {
                routeView = "/" + newView;
            }
            window.history.replaceState(null, "", "/organization".concat(routeView));
            _this.setState({
                view: newView,
            });
        };
        _this.updateNameLogo = function (name, logo) {
            _this.updateOrganizationName(name);
            _this.updateOrganizationLogo(logo);
            _this.setState({
                name: name,
                logo: logo,
            });
        };
        _this.viewProject = function (id) {
            window.history.replaceState(null, "", "/organization/projects/".concat(id));
            _this.setState({
                view: "projects",
            });
        };
        _this.viewGroup = function (id) {
            window.history.replaceState(null, "", "/organization/groups/".concat(id));
            _this.setState({
                view: "groups",
            });
        };
        _this.viewMember = function (id) {
            window.history.replaceState(null, "", "/organization/members/".concat(id));
            _this.setState({
                view: "members",
            });
        };
        _this.viewArchivedIdea = function () {
            window.history.replaceState(null, "", "/organization/archivedidea");
            _this.setState({
                view: "archivedidea",
            });
        };
        _this.updateSeatsInput = function (e) {
            var _a = e.target, name = _a.name, value = _a.value;
            if (name == "user_seats") {
                _this.setState({
                    user_seats: value,
                }, function () {
                    var _a = _this.state, admin_seats = _a.admin_seats, original_admin_seats = _a.original_admin_seats, user_seats = _a.user_seats, original_user_seats = _a.original_user_seats;
                    admin_seats != original_admin_seats ||
                        user_seats != original_user_seats
                        ? _this.setState({ seats_updated: true })
                        : _this.setState({ seats_updated: false });
                });
            }
            else {
                _this.setState({
                    admin_seats: value,
                }, function () {
                    var _a = _this.state, admin_seats = _a.admin_seats, original_admin_seats = _a.original_admin_seats, user_seats = _a.user_seats, original_user_seats = _a.original_user_seats;
                    admin_seats != original_admin_seats ||
                        user_seats != original_user_seats
                        ? _this.setState({ seats_updated: true })
                        : _this.setState({ seats_updated: false });
                });
            }
        };
        _this.addMembers = function (newmem) {
            var members = _this.state.members;
            _this.setState({
                members: __spreadArray(__spreadArray([], newmem, true), members, true),
            });
        };
        _this.updateSeats = function (newUserSeats, newAdminSeats) {
            _this.setState({
                original_admin_seats: newAdminSeats,
                original_user_seats: newUserSeats,
                user_seats: newUserSeats,
                admin_seats: newAdminSeats,
            });
        };
        var pathname = window.location.pathname;
        var view = "default";
        if (pathname.split("/").length > 2) {
            view = pathname.split("/")[2];
        }
        _this.state = {
            view: view,
            logo: "",
            name: "",
            domain: "",
            created: 0,
            user_seats: 0,
            admin_seats: 1,
            idea_count: 0,
            project_count: 0,
            group_count: 0,
            groups: [],
            projects: [],
            members: [],
            original_admin_seats: 0,
            original_user_seats: 0,
            updating_seats: false,
            seats_updated: false,
            loading: true,
        };
        _this.addMembers = _this.addMembers.bind(_this);
        _this.updateSeats = _this.updateSeats.bind(_this);
        _this.updateSeatsInput = _this.updateSeatsInput.bind(_this);
        _this.resetSeats = _this.resetSeats.bind(_this);
        _this.viewProject = _this.viewProject.bind(_this);
        _this.viewGroup = _this.viewGroup.bind(_this);
        _this.viewMember = _this.viewMember.bind(_this);
        _this.updateNameLogo = _this.updateNameLogo.bind(_this);
        _this.updateView = _this.updateView.bind(_this);
        _this.addGroup = _this.addGroup.bind(_this);
        _this.updateGroup = _this.updateGroup.bind(_this);
        _this.removeGroup = _this.removeGroup.bind(_this);
        _this.updateOrganizationLogo = _this.updateOrganizationLogo.bind(_this);
        _this.updateOrganizationName = _this.updateOrganizationName.bind(_this);
        return _this;
    }
    NewOrganizationPage.prototype.resetSeats = function () {
        var _a = this.state, original_admin_seats = _a.original_admin_seats, original_user_seats = _a.original_user_seats;
        this.setState({
            user_seats: original_user_seats,
            admin_seats: original_admin_seats,
            seats_updated: false,
        });
    };
    NewOrganizationPage.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST("get-organization-info", {}, function (res) {
            console.log(res);
            _this.setState({
                logo: res.logo,
                name: res.name,
                idea_count: res.idea_count,
                project_count: res.project_count,
                group_count: res.group_count,
                original_admin_seats: res.admin_seats,
                original_user_seats: res.user_seats,
                admin_seats: res.admin_seats,
                user_seats: res.user_seats,
                projects: res.projects || [],
                groups: res.groups || [],
                members: res.users || [],
                loading: false,
            });
        }, function (err) {
            console.log(err);
        });
    };
    NewOrganizationPage.prototype.render = function () {
        var _a = this.state, logo = _a.logo, name = _a.name, domain = _a.domain, created = _a.created, user_seats = _a.user_seats, admin_seats = _a.admin_seats, groups = _a.groups, projects = _a.projects, members = _a.members;
        var GroupsViewProps = {
            name: name,
            members: members,
            // @ts-ignore
            groups: this.state.groups,
            addGroup: this.addGroup,
            updateGroup: this.updateGroup,
            removeGroups: this.removeGroup,
            updateView: this.updateView,
        };
        var ProjectsViewProps = {
            projects: this.state.projects,
            name: this.state.name,
            addProject: Function,
            updateProject: Function,
            updateView: this.updateView,
            members: this.state.members,
            groups: this.state.groups,
        };
        var MembersViewProps = {
            members: this.state.members,
            groups: this.state.groups,
            projects: this.state.projects,
            name: this.state.name,
            user_seats: this.state.user_seats,
            admin_seats: this.state.admin_seats,
            updateView: this.updateView,
            addMembers: this.addMembers,
        };
        var SeatsViewProps = {
            // userSeats: this.state.user_seats,
            // adminSeats: this.state.admin_seats,
            updateSeats: this.updateSeats,
            updateView: this.updateView,
            users: this.state.members,
        };
        var SettingsViewProps = {
            name: this.state.name,
            logo: this.state.logo,
            updateView: this.updateView,
            updateNameLogo: this.updateNameLogo,
        };
        var DefaultViewProps = {
            name: this.state.name,
            logo: this.state.logo,
            idea_count: this.state.idea_count,
            project_count: this.state.project_count,
            group_count: this.state.group_count,
            //@ts-ignore
            seatsPreview: [],
            membersPreview: this.state.members,
            groupsPreview: this.state.groups,
            projectsPreview: this.state.projects,
            user_seats: this.state.user_seats,
            admin_seats: this.state.admin_seats,
            updateView: this.updateView,
            viewProject: this.viewProject,
            viewGroup: this.viewGroup,
            viewMember: this.viewMember,
            viewArchivedIdea: this.viewArchivedIdea,
        };
        var _b = this.state, view = _b.view, loading = _b.loading;
        if (loading) {
            return React.createElement(DashboardTemplate, null);
        }
        return (React.createElement(DashboardTemplate, null, view == "default" ? (React.createElement(DefaultView, __assign({}, DefaultViewProps))) : view == "members" ? (React.createElement(MembersView, __assign({ assetsView: this.props.AssetView }, MembersViewProps))) : view == "projects" ? (React.createElement(ProjectsView, __assign({ assetsView: this.props.AssetView }, ProjectsViewProps))) : view == "seats" ? (React.createElement(SeatsView, __assign({}, SeatsViewProps))) : view == "groups" ? (React.createElement(GroupsView, __assign({ assetsView: this.props.AssetView }, GroupsViewProps))) : view == "settings" ? (React.createElement(SettingsView, __assign({}, SettingsViewProps))) : view == "archivedidea" ? (React.createElement(ArchivedIdeaView, { updateView: DefaultViewProps.updateView })) : ("")));
    };
    return NewOrganizationPage;
}(React.Component));
export default connector(NewOrganizationPage);
