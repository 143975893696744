var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FileInfo } from "helpers/fileInfo";
import { FolderInfoString } from "helpers/folderInfo";
import { POST_REQUEST, USER_POST_REQUEST } from "helpers/requests";
import React from "react";
import DashboardTemplate from "templates/dashboard";
import { FileViewer } from "components/fileViewer/fileViewer";
import { TimeSince } from "helpers/timesince";
import { connect } from "react-redux";
import { BTCIcon } from "./chainIcons";
import IdeaSettingsModal from "./ideaSettingsModal";
var mapState = function (state) { return ({
    User: state.App.User,
    LoggedIn: state.App.LoggedIn,
    Organization: state.App.Organization,
}); };
var connector = connect(mapState, null);
var IdeaPageLocal = /** @class */ (function (_super) {
    __extends(IdeaPageLocal, _super);
    function IdeaPageLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.archiveIdea = function () {
            var pn = window.location.pathname.split("/");
            if (pn.length != 3) {
                return;
            }
            var idea = pn[2];
            if (_this.props.LoggedIn) {
                USER_POST_REQUEST("archive-idea", { idea_id: idea, archive: _this.state.idea.archived ? false : true }, function (res) {
                    if (_this.props.LoggedIn) {
                        USER_POST_REQUEST("get-idea", { idea: idea }, function (res) {
                            console.log(res);
                            _this.setState({
                                idea: res,
                            }, function () {
                                _this.headerRef.current.style.height =
                                    _this.heightRef.current.offsetHeight + 90 + "px";
                            });
                        }, function (err) {
                            console.log(err);
                        });
                    }
                    else {
                        POST_REQUEST("get-idea", { idea: idea }, function (res) {
                            console.log(res);
                            _this.setState({
                                idea: res,
                            }, function () {
                                _this.headerRef.current.style.height =
                                    _this.heightRef.current.offsetHeight + 90 + "px";
                            });
                        }, function (err) {
                            console.log(err);
                        });
                    }
                }, function (err) {
                    _this.setState({
                        archiveErrors: "An error occured while archiving the idea",
                    });
                });
            }
        };
        _this.loadProject = function (id) {
            window.location.href = "/project/".concat(id);
        };
        _this.state = {
            idea: null,
            fileViewerOpen: false,
            modalOpen: false,
        };
        _this.headerRef = React.createRef();
        _this.heightRef = React.createRef();
        _this.resizeHandler = _this.resizeHandler.bind(_this);
        _this.toggle = _this.toggle.bind(_this);
        _this.loadProject = _this.loadProject.bind(_this);
        _this.toggleModal = _this.toggleModal.bind(_this);
        return _this;
    }
    IdeaPageLocal.prototype.toggleModal = function () {
        var modalOpen = this.state.modalOpen;
        this.setState({
            modalOpen: !modalOpen,
        });
    };
    IdeaPageLocal.prototype.toggle = function () {
        this.state.fileViewerOpen
            ? document.body.classList.remove("modal-open")
            : document.body.classList.add("modal-open");
        this.setState(function (prev) { return ({
            fileViewerOpen: !prev.fileViewerOpen,
        }); });
    };
    IdeaPageLocal.prototype.resizeHandler = function () {
        this.headerRef.current.style.height =
            this.heightRef.current.offsetHeight + 90 + "px";
    };
    IdeaPageLocal.prototype.componentDidMount = function () {
        var _this = this;
        window.addEventListener("resize", this.resizeHandler);
        var pn = window.location.pathname.split("/");
        if (pn.length != 3) {
            return;
        }
        var idea = pn[2];
        if (this.props.LoggedIn) {
            USER_POST_REQUEST("get-idea", { idea: idea }, function (res) {
                console.log(res);
                _this.setState({
                    idea: res,
                }, function () {
                    _this.headerRef.current.style.height =
                        _this.heightRef.current.offsetHeight + 90 + "px";
                });
            }, function (err) {
                console.log(err);
            });
        }
        else {
            POST_REQUEST("get-idea", { idea: idea }, function (res) {
                console.log(res);
                _this.setState({
                    idea: res,
                }, function () {
                    _this.headerRef.current.style.height =
                        _this.heightRef.current.offsetHeight + 90 + "px";
                });
            }, function (err) {
                console.log(err);
            });
        }
    };
    IdeaPageLocal.prototype.componentWillUnmount = function () {
        window.removeEventListener("resize", this.resizeHandler);
    };
    IdeaPageLocal.prototype.render = function () {
        var _this = this;
        var idea = this.state.idea;
        if (idea == null) {
            return (React.createElement(DashboardTemplate, null,
                React.createElement("h1", null, "Loading...")));
        }
        var tagsLength = idea != null && idea.tags != null ? idea.tags.length : 0;
        idea.folders = idea.folders || [];
        idea.files == null ? (idea.files = []) : "";
        idea.tags == null ? (idea.tags = []) : "";
        if (idea != null) {
            var thumbCheck = parseInt(idea.thumbnail);
            if (!isNaN(thumbCheck)) {
                idea.thumbnail = "/images/cover_images/" + idea.thumbnail + ".png";
            }
        }
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "idea-page-header-bg", ref: this.headerRef }),
            React.createElement("div", { className: "container-fluid idea-page" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-4 left" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 thumbnail" },
                                React.createElement("img", { src: idea.thumbnail }))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "row align-items-center section-heading" },
                                    React.createElement("div", { className: "col-12" },
                                        "Files",
                                        " ",
                                        idea.zip_link != "" ? (React.createElement("a", { href: idea.zip_link, style: {
                                                padding: "2px 4px",
                                                fontSize: "12px",
                                                marginLeft: "4px",
                                            }, className: "btn btn-blue-alt font-alt" },
                                            React.createElement("i", { className: "icon-download" }),
                                            " Download Zip")) : null,
                                        React.createElement("span", { className: "view-all-files fl-right h-c", onClick: this.toggle },
                                            "View all files ",
                                            React.createElement("i", { className: "icon-files" })))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12 ".concat((idea.folders != null ? idea.folders.length : 0) +
                                            (idea.files != null ? idea.files.length : 0) ==
                                            0
                                            ? ""
                                            : "files-present") },
                                        React.createElement("div", { className: "file-list" },
                                            (idea.folders != null ? idea.folders.length : 0) +
                                                (idea.files != null ? idea.files.length : 0) ==
                                                0 ? (React.createElement("h4", null, "No files to show")) : (""),
                                            idea.folders.map(function (folder, index) {
                                                return React.createElement(FolderPreview, { folder: folder, key: index });
                                            }),
                                            idea.files.map(function (file, index) {
                                                return React.createElement(FilePreview, { file: file, key: index });
                                            }))))))),
                    React.createElement("div", { className: "col-8 right" },
                        React.createElement("div", { className: "row section-1", ref: this.heightRef },
                            React.createElement("div", { className: "col-12" }, this.props.LoggedIn ? (React.createElement("div", { className: "row align-items-center top-list" },
                                idea.project_name != "" && idea.project_id != "" ? (React.createElement("div", { className: "col-md-auto project" },
                                    React.createElement("h4", { onClick: function () {
                                            _this.loadProject(idea.project_id);
                                        } }, idea.project_name))) : (React.createElement("div", { className: "col-md-auto" })),
                                React.createElement("div", { className: "col-md-auto align-self-end utility-list" }))) : null),
                            React.createElement("div", { className: "col-12 title" },
                                React.createElement("h1", { className: "font-alt" },
                                    idea.private ? React.createElement("i", { className: "icon-status" }) : null,
                                    " ",
                                    idea.title)),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("ul", { className: "inline-list" },
                                    React.createElement("li", { className: "idea-id" },
                                        "ID-",
                                        idea.hash_id),
                                    React.createElement("li", null,
                                        TimeSince(idea.created),
                                        " ago"))),
                            this.props.LoggedIn && idea.is_submitter_or_admin ? (React.createElement("div", { className: "flex gap-2" },
                                React.createElement("hr", { className: "tp" }),
                                React.createElement("button", { onClick: this.toggleModal, className: "btn btn-blue-alt" },
                                    React.createElement("i", { className: "icon-settings" }),
                                    " Idea Settings"),
                                React.createElement("button", { onClick: this.archiveIdea, className: "btn btn-blue-alt flex gap-2 items-center" }, idea.archived ? (React.createElement(React.Fragment, null,
                                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" },
                                        React.createElement("path", { fill: "currentColor", d: "m12 6l-4 4l1.4 1.4l1.6-1.6V14h2V9.8l1.6 1.6L16 10zm-7 13q-.825 0-1.412-.587T3 17V4.525q0-.35.113-.675t.337-.6L4.7 1.725q.275-.35.687-.538T6.25 1h11.5q.45 0 .863.188t.687.537l1.25 1.525q.225.275.338.6t.112.675V17q0 .825-.587 1.413T19 19zm.4-15h13.2l-.85-1H6.25z" })),
                                    "Unarchive")) : (React.createElement(React.Fragment, null,
                                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24" },
                                        React.createElement("path", { fill: "currentColor", d: "m12 18l4-4l-1.4-1.4l-1.6 1.6V10h-2v4.2l-1.6-1.6L8 14zm-7 3q-.825 0-1.412-.587T3 19V6.525q0-.35.113-.675t.337-.6L4.7 3.725q.275-.35.687-.538T6.25 3h11.5q.45 0 .863.188t.687.537l1.25 1.525q.225.275.338.6t.112.675V19q0 .825-.587 1.413T19 21zm.4-15h13.2l-.85-1H6.25z" })),
                                    "Archive"))))) : null),
                        this.state.archiveErrors &&
                            this.state.archiveErrors.length > 0 && (React.createElement("div", { className: "text-red-700" }, this.state.archiveErrors)),
                        React.createElement("div", { className: "row section-2" },
                            React.createElement("div", { className: "col-4 info-section br info-section-pv" },
                                React.createElement("div", { className: "row align-items-center info-section-head" },
                                    React.createElement("div", { className: "col-md-auto", style: { padding: "0" } },
                                        React.createElement("span", { className: "protection-verified" },
                                            React.createElement("i", { className: "icon-check_bubble", style: { color: "var(--colors-basic-light)" } }))),
                                    React.createElement("div", { className: "col" },
                                        React.createElement("h4", { className: "font-alt" }, "PROTECTION VERIFIED"))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("h3", { className: "time font-alt-2" }, new Date(idea.created * 1000).toLocaleString("en-US", {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }))))),
                            React.createElement("div", { className: " info-section br" }, idea.blockchain_data != null &&
                                idea.blockchain_data.length > 0 ? (React.createElement("div", { className: "blockchain" },
                                React.createElement("div", { className: "info-section-head row align-items-center" },
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("h4", { className: "font-alt-2" },
                                            React.createElement("i", null, "#"),
                                            " Timestamps"))),
                                idea.blockchain_data
                                    .filter(function (bd) { return bd.network === "BTC"; })
                                    .map(function (bd, index) { return (React.createElement("span", { key: index, className: "mb-6 whitespace-nowrap inline-block  border-white p-3 " },
                                    React.createElement("a", { href: "https://live.blockcypher.com/".concat(bd.network.toLowerCase(), "/tx/").concat(bd.tx, "/"), className: "no-underline text-blue-500 px-3 py-1.5 mr-4 border-2 border-blue-200 rounded font-mono font-bold flex items-center", target: "_blank" },
                                        React.createElement(BTCIcon, null),
                                        bd.network))); }))) : null),
                            React.createElement("div", { className: "col-4 info-section " },
                                React.createElement("div", { className: "row align-items-center info-section-head" },
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("h4", { className: "section-heading" },
                                            React.createElement("i", { className: "icon-tag" }),
                                            " Tags"))),
                                React.createElement("h4", { className: "section-list" },
                                    idea.tags.map(function (tag, index) {
                                        if (index == 3) {
                                            return (React.createElement("span", { className: "tag", key: index },
                                                "+",
                                                tagsLength - 3,
                                                " more."));
                                        }
                                        if (index > 2) {
                                            return null;
                                        }
                                        if (index <= 2) {
                                            return (React.createElement("span", { className: "tag", key: index }, tag.name));
                                        }
                                    }),
                                    tagsLength == 0 ? React.createElement("span", null, "N/A") : ""))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 blockchain" },
                                React.createElement("h3", { className: "hash" },
                                    React.createElement("span", { className: "network" }, "Zip Hash: "),
                                    idea.zip_hash)),
                            React.createElement("div", { className: "col-12 description" },
                                React.createElement("h3", { className: "section-heading" }, "Description"),
                                React.createElement("p", null, idea.description)))))),
            this.props.LoggedIn && idea.is_submitter_or_admin ? (React.createElement(IdeaSettingsModal, { modalOpen: this.state.modalOpen, users: this.props.Organization.users, groups: this.props.Organization.groups, toggleModal: this.toggleModal, idea: idea, organization: this.props.Organization })) : null,
            React.createElement(FileViewer, { files: idea.files, folders: idea.folders, open: this.state.fileViewerOpen, toggle: this.toggle })));
    };
    return IdeaPageLocal;
}(React.Component));
var IdeaPage = connector(IdeaPageLocal);
export default IdeaPage;
var FilePreview = /** @class */ (function (_super) {
    __extends(FilePreview, _super);
    function FilePreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilePreview.prototype.render = function () {
        var file = this.props.file;
        return (React.createElement("div", { className: "file-list-item" },
            React.createElement("div", { className: "row align-items-center file-info" },
                React.createElement("div", { className: "col-md-auto" }, file.thumbnail != null && file.thumbnail != "" ? (React.createElement("img", { className: "file-thumbnail", src: file.thumbnail })) : (React.createElement("i", { className: "icon-draft" }))),
                React.createElement("div", { className: "col" },
                    React.createElement("span", { className: "file-name" }, file.name),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: "file-size" },
                        React.createElement("span", { style: { textTransform: "capitalize" } }, file.type),
                        " -",
                        " ",
                        FileInfo(file.size))))));
    };
    return FilePreview;
}(React.Component));
var FolderPreview = /** @class */ (function (_super) {
    __extends(FolderPreview, _super);
    function FolderPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FolderPreview.prototype.render = function () {
        var folder = this.props.folder;
        return (React.createElement("div", { className: "folder-list-item" },
            React.createElement("div", { className: "row align-items-center folder-info" },
                React.createElement("div", { className: "col-md-auto" },
                    React.createElement("i", { className: "icon-folder" })),
                React.createElement("div", { className: "col" },
                    React.createElement("span", { className: "folder-name" }, folder.name),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: "folder-size" }, FolderInfoString(folder))))));
    };
    return FolderPreview;
}(React.Component));
