import { LOGIN, LOGOUT, SET_APP_LOADED, SET_APP_SOCKET, SET_APP_SOCKET_STATUS, UPDATE_APP_ORGANIZATION, UPDATE_APP_USER, SET_DEPLOYMENT } from "./types";
export function AppReducer(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case LOGIN:
            return Object.assign({}, state, {
                LoggedIn: true,
                AppLoaded: true,
                User: action.payload.User,
                Auth: action.payload.Auth,
                Organization: action.payload.Organization,
            });
        case LOGOUT:
            return Object.assign({}, state, {
                LoggedIn: false,
                User: null,
                Auth: null,
                Organization: null,
                Organizations: null
            });
        case SET_APP_LOADED:
            return Object.assign({}, state, {
                AppLoaded: action.loaded
            });
        case SET_APP_SOCKET:
            return Object.assign({}, state, {
                Socket: action.socket
            });
        case SET_APP_SOCKET_STATUS:
            return Object.assign({}, state, {
                SocketStatus: action.status
            });
        case UPDATE_APP_ORGANIZATION:
            return Object.assign({}, state, {
                Organization: action.organization,
            });
        case UPDATE_APP_USER:
            return Object.assign({}, state, {
                User: action.user,
            });
        case SET_DEPLOYMENT:
            return Object.assign({}, state, {
                Deployment: action.deployment,
            });
        default:
            return state;
    }
}
