var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { Link } from "react-router-dom";
//
var TopNavTemplateLocal = /** @class */ (function (_super) {
    __extends(TopNavTemplateLocal, _super);
    function TopNavTemplateLocal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TopNavTemplateLocal.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row nav-wrapper" },
                React.createElement("div", { className: "  flex justify-center items-center nav-logo" },
                    React.createElement("div", { "bg-red-700": true },
                        React.createElement(Link, { to: "/" },
                            React.createElement("img", { src: "/images/logo.svg " })))),
                React.createElement("div", { className: "nav-content row align-items-center" },
                    React.createElement("div", { className: "container-fluid" },
                        React.createElement("div", { className: "row align-items-center" }, this.props.children)))),
            React.createElement("div", { className: "top-nav-bump" })));
    };
    return TopNavTemplateLocal;
}(React.Component));
var TopNavTemplate = TopNavTemplateLocal;
export default TopNavTemplate;
