export function recursiveFileUnRemove(removeFile, folders) {
    for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == removeFile.parent_id) {
            folders[i].files.find(function (found) { return found.id == removeFile.id; }).removed = false;
            return folders;
        }
        if (folders[i].folders != undefined) {
            if (folders[i].folders.length > 0) {
                folders[i].folders = recursiveFileUnRemove(removeFile, folders[i].folders);
            }
        }
    }
    return folders;
}
