import React, { useEffect, useState } from 'react';
import { DefaultNoSideBar } from "../../templates/default";
import { store } from "../../redux/store";
import { Navigate } from "react-router-dom";
import './memberRegistration.sass';
import { CREDENTIALED_REQUEST, POST_REQUEST } from "../../helpers/requests";
import { GetQueryParam } from "../../helpers/queryParams";
import { Login as LoginAction } from 'auth/login';
export var MemberRegistration = function () {
    var appstate = store.getState();
    var _a = useState(''), orgName = _a[0], setOrgName = _a[1];
    var _b = useState(''), orgLogo = _b[0], setOrgLogo = _b[1];
    var _c = useState(''), name = _c[0], setName = _c[1];
    var _d = useState(''), email = _d[0], setEmail = _d[1];
    var _e = useState(false), loaded = _e[0], setLoaded = _e[1];
    useEffect(function () {
        if (!loaded) {
            setLoaded(true);
            // post request with token and id for said token
            var token = GetQueryParam("token");
            var id = GetQueryParam("id");
            POST_REQUEST("check-token", { token: token, id: id }, function (res) {
                if (res.errors != null) {
                    setErrors(res.errors);
                    return;
                }
                if (res.organization_name != null) {
                    setOrgName(res.organization_name);
                }
                if (res.logo != null) {
                    setOrgLogo(res.logo);
                }
                if (res.first_name) {
                    setName("".concat(res.first_name, " ").concat(res.last_name));
                }
                if (res.email) {
                    setEmail(res.email);
                }
            }, function (err) {
            });
        }
    });
    var _f = useState(''), password = _f[0], setPassword = _f[1];
    var _g = useState(''), confirmPassword = _g[0], setConfirmPassword = _g[1];
    var _h = useState([]), errors = _h[0], setErrors = _h[1];
    var up = function (e) { setPassword(e.target.value); };
    var upc = function (e) { setConfirmPassword(e.target.value); };
    var submit = function () {
        setErrors([]);
        if (password != confirmPassword) {
            setErrors(["passwords must match"]);
            return;
        }
        if (password.length == 0) {
            setErrors(["you have to set a password"]);
            return;
        }
        var token = GetQueryParam("token");
        var id = GetQueryParam("id");
        CREDENTIALED_REQUEST("register-org-user", {
            password: password,
            confirm_password: confirmPassword,
            token: token,
            id: id,
        }, function (res) {
            if (res.errors != null) {
                setErrors(res.errors);
                return;
            }
            LoginAction(res).then(function () {
                window.location.href = "/dashboard";
            });
        }, function (err) {
            console.log(err);
        });
    };
    if (!loaded) {
        return React.createElement(DefaultNoSideBar, null);
    }
    if (appstate.App.LoggedIn) {
        return React.createElement(Navigate, { to: "/dashboard" });
    }
    return (React.createElement(DefaultNoSideBar, null,
        React.createElement("div", { className: "container member-registration" },
            React.createElement("div", { className: "row justify-content-center align-items-center form-wrapper", style: { height: "calc(100vh - 700px)" } },
                React.createElement("div", { className: "container form-container" },
                    React.createElement("div", { className: "row align-items-center" },
                        React.createElement("div", { className: "col-6 org-info" },
                            React.createElement("img", { src: orgLogo })),
                        React.createElement("div", { className: "col-6" },
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-12 t-center" },
                                    React.createElement("h3", { className: "font-alt" },
                                        "Finish your registration for: ",
                                        React.createElement("br", null),
                                        " ",
                                        orgName)),
                                React.createElement("div", { className: "col-12 field" },
                                    React.createElement("p", null,
                                        "Name: ",
                                        name),
                                    React.createElement("p", null,
                                        "Email: ",
                                        email)),
                                React.createElement("div", { className: "col-12 field" },
                                    React.createElement("input", { value: password, onChange: up, type: "password", placeholder: "Password" }),
                                    React.createElement("input", { value: confirmPassword, onChange: upc, type: "password", placeholder: "Confirm Password" })),
                                React.createElement("div", { className: "col-12 field" },
                                    React.createElement("button", { onClick: submit, className: "btn btn-blue-alt" }, "Submit")),
                                React.createElement("div", { className: "col-12 field errors" }, errors.map(function (e, k) {
                                    return (React.createElement("p", { className: "err", key: k }, e));
                                }))))))))));
};
