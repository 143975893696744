var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import DashboardTemplate from 'templates/dashboard';
var OrganizationMemberSetupLocal = /** @class */ (function (_super) {
    __extends(OrganizationMemberSetupLocal, _super);
    function OrganizationMemberSetupLocal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OrganizationMemberSetupLocal.prototype.render = function () {
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container-fluid organization-member-setup" },
                React.createElement("div", { className: "row heading align-items-center" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h1", { className: "font-alt-2" }, "Ideablock Members: ")),
                            React.createElement("div", { className: "col-12 stats" },
                                React.createElement("h3", { className: "font-alt-2" },
                                    "User Seats: 15 ",
                                    React.createElement("span", { className: "btn-separator" }, "|"),
                                    "Admin Seats: 3")),
                            React.createElement("div", { className: "col-12 controls" },
                                React.createElement("button", { className: "btn btn-blue-alt" }, "Hide Form"),
                                React.createElement("span", { className: "btn-separator" }),
                                React.createElement("button", { className: "btn btn-blue-alt" }, "Import VIA CSV")),
                            React.createElement("div", { className: "col-12 add-member-form" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12 field" },
                                        React.createElement("label", null, "First Name: "),
                                        React.createElement("input", { type: "text" })),
                                    React.createElement("div", { className: "col-12 field" },
                                        React.createElement("label", null, "Last Name: "),
                                        React.createElement("input", { type: "text" })),
                                    React.createElement("div", { className: "col-12 field" },
                                        React.createElement("label", null, "Email:"),
                                        React.createElement("input", { type: "text" }))),
                                React.createElement("button", { className: "btn btn-blue-alt" }, "Add Member to List"))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 errors" })))),
                React.createElement("div", { className: "row content" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h2", { className: "font-alt-2" },
                                    "1/18 TOTAL Seats ",
                                    React.createElement("span", { className: "btn-separator" }, "|"),
                                    " 0/15 User Seats ",
                                    React.createElement("span", { className: "btn-separator" }, "|"),
                                    " 1/3 Admin Seats"))),
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("table", { className: "members-table" },
                                    React.createElement("thead", null,
                                        React.createElement("tr", null,
                                            React.createElement("td", null, "First Name:"),
                                            React.createElement("td", null, "Last Name:"),
                                            React.createElement("td", null, "Email: "),
                                            React.createElement("td", null, "Admin: "))),
                                    React.createElement("tbody", null,
                                        React.createElement("tr", null,
                                            React.createElement("td", null, "Adam"),
                                            React.createElement("td", null, "Flores"),
                                            React.createElement("td", null, "adam@ideablock.com"),
                                            React.createElement("td", null, "Yes")))))))),
                React.createElement("div", { className: "row" }))));
    };
    return OrganizationMemberSetupLocal;
}(React.Component));
var OrganizationMemberSetup = OrganizationMemberSetupLocal;
export default OrganizationMemberSetup;
