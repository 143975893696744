var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import TopNavTemplate from "templates/topNav";
import { Link } from "react-router-dom";
import TopNavSettings from "./shared";
var SeatTopNavLocal = /** @class */ (function (_super) {
    __extends(SeatTopNavLocal, _super);
    function SeatTopNavLocal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SeatTopNavLocal.prototype.render = function () {
        return (React.createElement(TopNavTemplate, null,
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "nav-right row justify-content-end align-items-center", style: { marginRight: "20px" } },
                    React.createElement(TopNavSettings, null),
                    React.createElement(Link, { className: "btn btn-primary flex items-center", to: "/dashboard", style: {
                            textDecoration: "none",
                            backgroundColor: "#CAFC3D",
                            color: "black",
                            borderRadius: "5px",
                            // textAlign: "center",
                        } },
                        "Continue To Dashboard",
                        React.createElement("svg", { className: "ml-2", width: "19", height: "15", viewBox: "0 0 19 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                            React.createElement("path", { d: "M11.5659 0L9.98876 1.59116L14.7942 6.43923H0V8.56077H14.7942L9.98876 13.4088L11.5659 15L19 7.5L11.5659 0Z", fill: "black" })))))));
    };
    return SeatTopNavLocal;
}(React.Component));
var SeatTopNav = SeatTopNavLocal;
export default SeatTopNav;
