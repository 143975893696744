var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NumberCommas } from 'helpers/numbers';
import { USER_POST_REQUEST } from 'helpers/requests';
import React from 'react';
var AssetsLocal = /** @class */ (function (_super) {
    __extends(AssetsLocal, _super);
    function AssetsLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            total: 0,
            projects: 0,
        };
        return _this;
    }
    AssetsLocal.prototype.componentDidMount = function () {
        var _this = this;
        USER_POST_REQUEST('display/assets', {}, function (res) {
            _this.setState({
                total: res.total_ideas,
                projects: res.projects,
            });
        }, function (err) {
            console.log(err);
        });
    };
    AssetsLocal.prototype.render = function () {
        return (React.createElement("div", { className: "col-12 dashboard-component-wrapper" },
            React.createElement("div", { className: "dashboard-component assets-component row" },
                React.createElement("div", { className: "heading col-12" },
                    React.createElement("h2", null, "Assets")),
                React.createElement("div", { className: "section col-6" },
                    React.createElement("h2", null,
                        React.createElement("i", { className: "icon-allassets" }),
                        NumberCommas(this.state.total)),
                    React.createElement("h3", { className: "sub-title" }, "Total Assets")),
                React.createElement("div", { className: "col-6 section" },
                    React.createElement("h2", null,
                        React.createElement("i", { className: "icon-projects" }),
                        NumberCommas(this.state.projects)),
                    React.createElement("h3", { className: "sub-title" }, "Projects")))));
    };
    return AssetsLocal;
}(React.Component));
var Assets = AssetsLocal;
export default Assets;
