var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CREDENTIALED_FORMDATA_REQUEST, POST_REQUEST } from 'helpers/requests';
import React from 'react';
import { Link } from 'react-router-dom';
import { DefaultNoSideBar } from 'templates/default';
import { UpdateInput } from 'helpers/inputHandlers';
import { connect } from 'react-redux';
var mapState = function (state) { return ({
    LoggedIn: state.App.LoggedIn,
}); };
var connector = connect(mapState, null);
var SignUpLocal = /** @class */ (function (_super) {
    __extends(SignUpLocal, _super);
    function SignUpLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.updateHeadlinePhrase = function (stage) {
            if (_this.headlinesActive.has(stage - 1)) {
                if (_this.headlinesActive.get(stage - 1)) {
                    return;
                }
            }
            _this.headlinesActive.set(stage - 1, true);
            var TYPING_SPEED = 150;
            var i = 0;
            var current_phrase = '';
            var _a = _this.state, phrases = _a.phrases, headlines = _a.headlines;
            var handleTyper = function () {
                if (i < phrases[stage - 1].length) {
                    current_phrase += phrases[stage - 1].charAt(i);
                    headlines[stage - 1] = current_phrase;
                    _this.setState({
                        headlines: headlines
                    });
                    i++;
                    setTimeout(handleTyper, TYPING_SPEED);
                }
                else {
                    _this.headlinesActive.set(stage - 1, false);
                }
            };
            handleTyper();
        };
        _this.handleFile = function (e) {
            var _a = e.target, name = _a.name, files = _a.files;
            if (name == "logo") {
                _this.setState({
                    logo: files[0] || null,
                });
            }
            else {
                _this.setState({
                    profile_picture: files[0] || null,
                });
            }
        };
        _this.clearFile = function (name) {
            if (name == "logo") {
                _this.setState({
                    logo: null,
                });
            }
            else {
                _this.setState({
                    profile_picture: null,
                });
            }
        };
        _this.updatestage = function (e) {
            var stage = parseInt(e.currentTarget.dataset.stage);
            _this.setState({
                stage: stage
            }, function () {
                _this.updateHeadlinePhrase(stage);
            });
        };
        _this.state = {
            referral_err: false,
            referral: null,
            first_name: '',
            last_name: '',
            email: '',
            isLoading: false,
            email_confirm: '',
            password: '',
            password_confirm: '',
            domain: '',
            organization_name: '',
            admin_seats: 1,
            user_seats: 0,
            errors: [],
            stage: 1,
            expired: false,
            used: false,
            registrationKey: '',
            completedstages: [],
            headlines: [],
            profile_picture: null,
            logo: null,
            phrases: ["WELCOME TO IDEABLOCK", "YOUR IP's NEW HOME", "THE LAUNCHPAD IS READY...", "...ARE YOU?"],
            registering: false,
            registrationComplete: false,
            referralSlug: '',
        };
        _this.stagesComplete = new Map();
        _this.headlinesActive = new Map();
        _this.register = _this.register.bind(_this);
        _this.updateInput = UpdateInput.bind(_this);
        _this.updatestage = _this.updatestage.bind(_this);
        _this.updateHeadlinePhrase = _this.updateHeadlinePhrase.bind(_this);
        _this.handleFile = _this.handleFile.bind(_this);
        _this.clearFile = _this.clearFile.bind(_this);
        _this.prevStage = _this.prevStage.bind(_this);
        _this.nextStage = _this.nextStage.bind(_this);
        return _this;
    }
    SignUpLocal.prototype.componentDidMount = function () {
        var _this = this;
        var referral = "";
        // TODO : get referral
        var LoggedIn = this.props.LoggedIn;
        if (LoggedIn) {
            window.location.href = "/";
        }
        if (referral != undefined && referral != "") {
            POST_REQUEST('get-referral-info', { referral: referral }, function (res) {
                _this.setState({
                    referral: res,
                });
            }, function (err) {
                _this.setState({ referral_err: true });
            });
        }
        return;
        this.updateHeadlinePhrase(1);
    };
    SignUpLocal.prototype.nextStage = function () {
        this.setState(function (prev) { return ({
            stage: prev.stage + 1,
        }); });
    };
    SignUpLocal.prototype.prevStage = function () {
        this.setState(function (prev) { return ({
            stage: prev.stage - 1,
        }); });
    };
    SignUpLocal.prototype.register = function () {
        var _this = this;
        var _a = this.state, registering = _a.registering, referral = _a.referral, email = _a.email, email_confirm = _a.email_confirm, first_name = _a.first_name, last_name = _a.last_name, profile_picture = _a.profile_picture, password = _a.password, password_confirm = _a.password_confirm, domain = _a.domain, organization_name = _a.organization_name, logo = _a.logo;
        if (registering) {
            return;
        }
        this.setState({
            registering: true,
        }, function () {
            var errors = [];
            password != password_confirm ? errors.push("Passwords do not match.") : "";
            first_name == "" ? errors.push("you need a first name") : "";
            last_name == "" ? errors.push("you need a last name") : "";
            email == "" ? errors.push("you need an email") : "";
            email.split('@').length < 2 ? errors.push("Issue with email formatting") : "";
            //domain == "" ? errors.push("you need to set a domain") : ""
            first_name.length > 255 ? errors.push("first name too long (max 255 characters)") : "";
            last_name.length > 255 ? errors.push("last name too long (max 255 characters)") : "";
            // domain.length > 255 ? errors.push("domain name is too long (max 255 characters)") : "" 
            organization_name == "" ? errors.push("you need to set an organization name") : "";
            organization_name.length > 255 ? errors.push("organization name too long (max 255 characters)") : "";
            if (errors.length != 0) {
                _this.setState({
                    errors: errors,
                    registering: false,
                });
                return;
            }
            var fd = new FormData();
            fd.append("email", email);
            fd.append("first_name", first_name);
            fd.append("last_name", last_name);
            fd.append("password", password);
            fd.append("domain", email.split('@')[email.split('@').length - 1]);
            fd.append("organization_name", organization_name);
            fd.append("referral", _this.state.referralSlug || "");
            fd.append("logo", logo);
            fd.append("avatar", profile_picture);
            fd.append("admin_seats", String(_this.state.admin_seats));
            fd.append("user_seats", String(_this.state.user_seats));
            CREDENTIALED_FORMDATA_REQUEST('register', fd, function (res) {
                _this.setState({
                    registering: false,
                    registrationComplete: true,
                });
                // LoginAction(res).then(() => {
                //     // window.location.href = '/manage-seat'
                // })
                // window.location.href = '/manage-seat'
            }, function (err) {
                _this.setState({
                    errors: err.response.data.errors,
                    registering: false,
                });
            });
        });
    };
    SignUpLocal.prototype.render = function () {
        var _this = this;
        var _a = this.state, registering = _a.registering, referral_err = _a.referral_err, referral = _a.referral, email = _a.email, email_confirm = _a.email_confirm, headlines = _a.headlines, stage = _a.stage, first_name = _a.first_name, last_name = _a.last_name, password = _a.password, password_confirm = _a.password_confirm, domain = _a.domain, organization_name = _a.organization_name, errors = _a.errors, expired = _a.expired, used = _a.used, logo = _a.logo, profile_picture = _a.profile_picture, admin_seats = _a.admin_seats, user_seats = _a.user_seats;
        var stageProps = {
            stage: stage,
            logo: logo,
            profile_picture: profile_picture,
            email: email,
            email_confirm: email_confirm,
            first_name: first_name,
            last_name: last_name,
            password: password,
            password_confirm: password_confirm,
            domain: domain,
            organization_name: organization_name,
            admin_seats: admin_seats,
            user_seats: user_seats,
            errors: errors,
            expired: expired,
            used: used,
            updateInput: this.updateInput,
            handleInput: this.updateInput,
            handleFile: this.handleFile,
            clearFile: this.clearFile,
        };
        if (referral_err) {
            return (React.createElement(DefaultNoSideBar, null,
                React.createElement("div", { className: "signup-page container-fluid" },
                    React.createElement("div", { className: "row heading-wrapper align-items-center" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement("h1", { className: "t-center font-alt-2 welcome-heading" }, "REFERRAL CODE INVALID"),
                            React.createElement("h2", { className: "t-center font-alt-2", style: { marginTop: 48 } },
                                "To go to our normal signup page please click ",
                                React.createElement("a", { onClick: function () { _this.setState({ referral_err: false }); window.location.href = "/register"; }, style: { color: 'var(--clear-blue)' } }, "here.")))))));
        }
        if (this.state.registrationComplete) {
            return React.createElement(DefaultNoSideBar, null,
                React.createElement("div", { className: "signup-page container-fluid", style: { height: "calc(100vh - 100px)" } },
                    React.createElement("div", { className: "row align-items-center", style: { height: "calc(100vh - 100px)", overflow: "auto" } },
                        React.createElement("div", { className: "col-12 t-center" },
                            React.createElement("h1", { className: "font-alt-2" }, "Registration Successful"),
                            React.createElement("hr", { className: "tp" }),
                            React.createElement("h3", null, "Please check your email")))));
        }
        return (React.createElement(DefaultNoSideBar, null,
            React.createElement("div", { className: "signup-page container-fluid", style: { height: "calc(100vh - 100px)" } },
                React.createElement("div", { className: "row align-items-center", style: { height: "calc(100vh - 100px)", overflow: "auto" } },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "row heading-wrapper" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h1", { className: "t-center font-alt-2 welcome-heading" }, this.state.referralSlug == undefined || this.state.referralSlug == "" ?
                                    "Ideablock Registration" :
                                    // "Ideablock Trial Registration" : 
                                    this.state.referralSlug == "gini" ?
                                        // "GInI AssetChain Registration"
                                        "Ideablocklo Registration"
                                        :
                                            headlines[stage - 1]),
                                React.createElement("p", null,
                                    "Already have an account? ",
                                    React.createElement(Link, { to: "/login".concat(this.state.referralSlug != undefined ? "/".concat(this.state.referralSlug) : "") }, "Login")))),
                        React.createElement("div", { className: "row field" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h2", { className: "font-alt-2" }, "Your Information:")),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-6" },
                                        React.createElement("label", null, "First Name"),
                                        React.createElement("input", { type: "text", name: "first_name", onChange: this.updateInput, value: first_name })),
                                    React.createElement("div", { className: "col-6" },
                                        React.createElement("label", null, "Last Name"),
                                        React.createElement("input", { type: "text", name: "last_name", onChange: this.updateInput, value: last_name }))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("label", null, "Business Email"),
                                        React.createElement("input", { type: "email", value: email, onChange: this.updateInput, name: "email" }))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-6" },
                                        React.createElement("label", null, "Password"),
                                        React.createElement("input", { type: "password", value: password, onChange: this.updateInput, name: "password" })),
                                    React.createElement("div", { className: "col-6" },
                                        React.createElement("label", null, "Confirm Password"),
                                        React.createElement("input", { type: "password", value: password_confirm, onChange: this.updateInput, name: "password_confirm" }))))),
                        React.createElement("div", { className: "row field" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h2", { className: "font-alt-2" }, "Organization Information:")),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-6" },
                                        React.createElement("label", null, "Organization Name"),
                                        "  ",
                                        React.createElement("br", null),
                                        React.createElement("input", { type: "text", value: organization_name, name: "organization_name", onChange: this.updateInput })),
                                    React.createElement("div", { className: "col-6" },
                                        React.createElement("label", null, "Organization Domain"),
                                        " ",
                                        React.createElement("br", null),
                                        React.createElement("h3", null, email.split("@").length > 1 ? email.split("@")[email.split("@").length - 1] : null))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-6" },
                                        React.createElement("label", null, "Admin Seats"),
                                        React.createElement("input", { min: 1, name: "admin_seats", type: "number", value: admin_seats, onChange: this.updateInput })),
                                    React.createElement("div", { className: "col-6" },
                                        React.createElement("label", null, "User Seats"),
                                        React.createElement("input", { name: "user_seats", type: "number", value: user_seats, onChange: this.updateInput }))))),
                        React.createElement("div", { className: "row errors" },
                            React.createElement("div", { className: "col-12" }, errors != null ?
                                React.createElement("ul", null, errors.map(function (err, index) {
                                    return (React.createElement("li", { key: index }, err));
                                }))
                                : null)),
                        React.createElement("div", { className: "row submit" },
                            React.createElement("div", { className: "col-12 t-right" },
                                React.createElement("button", { className: "btn btn-blue-alt", onClick: this.register },
                                    " ",
                                    registering ? "Registering..." : "Register"))))),
                referral != null ?
                    React.createElement("a", { className: "ideablock-referral", href: referral.link, target: "_blank" },
                        React.createElement("img", { src: referral.logo }))
                    : null)));
    };
    return SignUpLocal;
}(React.Component));
var SignUp = connector(SignUpLocal);
export default SignUp;
var Stage1 = /** @class */ (function (_super) {
    __extends(Stage1, _super);
    function Stage1() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Stage1.prototype.render = function () {
        return ("yo");
    };
    return Stage1;
}(React.Component));
var Stage2 = /** @class */ (function (_super) {
    __extends(Stage2, _super);
    function Stage2() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Stage2.prototype.render = function () {
        return ("ye");
    };
    return Stage2;
}(React.Component));
var Welcomestage = /** @class */ (function (_super) {
    __extends(Welcomestage, _super);
    function Welcomestage(props) {
        var _this = _super.call(this, props) || this;
        _this.imageProxy = React.createRef();
        return _this;
    }
    Welcomestage.prototype.componentDidMount = function () {
    };
    Welcomestage.prototype.render = function () {
        var _this = this;
        var _a = this.props, organization_name = _a.organization_name, domain = _a.domain, handleInput = _a.handleInput, clearFile = _a.clearFile, logo = _a.logo;
        return (React.createElement("div", { className: "row stage-1 welcome" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 field t-center" },
                        React.createElement("h2", { className: "font-alt" }, "Company Logo"),
                        React.createElement("input", { type: "file", name: "logo", hidden: true, ref: this.imageProxy, onChange: this.props.handleFile }),
                        logo != null ?
                            React.createElement(React.Fragment, null,
                                React.createElement("h2", { className: "logo-preview t-center" },
                                    React.createElement("img", { src: URL.createObjectURL(logo) })),
                                React.createElement("h2", null,
                                    React.createElement("button", { className: "btn btn-cancel", onClick: function () { clearFile("logo"); } }, "Remove Logo")))
                            :
                                React.createElement("div", { className: "logo-select box", onClick: function () { _this.imageProxy.current.click(); } }, "+ Click here to add your Logo")),
                    React.createElement("div", { className: "col-12 field t-center" },
                        React.createElement("h2", { className: "font-alt " }, "The Name:"),
                        React.createElement("input", { type: "text", placeholder: "e.g. Ideablock", value: organization_name, name: "organization_name", onChange: handleInput })),
                    React.createElement("div", { className: "col-12 field t-center" },
                        React.createElement("h2", { className: "font-alt t-center" }, "The Domain"),
                        React.createElement("h4", { className: "font-alt t-center" }, "name.extension, do not include 'http/https' or 'www'"),
                        React.createElement("h4", { className: "font-alt t-center" }, "Example: ideablock.com"),
                        React.createElement("input", { type: "text", placeholder: "e.g. ideablock.com", value: domain, name: "domain", onChange: handleInput }))))));
    };
    return Welcomestage;
}(React.Component));
var UserStage = /** @class */ (function (_super) {
    __extends(UserStage, _super);
    function UserStage(props) {
        var _this = _super.call(this, props) || this;
        _this.imageProxy = React.createRef();
        return _this;
    }
    UserStage.prototype.render = function () {
        var _this = this;
        var _a = this.props, first_name = _a.first_name, last_name = _a.last_name, email = _a.email, handleInput = _a.handleInput, password = _a.password, password_confirm = _a.password_confirm, profile_picture = _a.profile_picture, clearFile = _a.clearFile;
        return (React.createElement("div", { className: "row stage-2 about-you" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12 field t-center" },
                        React.createElement("h2", { className: "font-alt" }, "Profile Picture (optional)"),
                        React.createElement("input", { type: "file", name: "avatar", onChange: this.props.handleFile, hidden: true, ref: this.imageProxy }),
                        profile_picture != null ?
                            React.createElement(React.Fragment, null,
                                React.createElement("h2", { className: "logo-preview t-center" },
                                    React.createElement("img", { src: URL.createObjectURL(profile_picture) })),
                                React.createElement("h2", null,
                                    React.createElement("button", { className: "btn btn-cancel", onClick: function () { clearFile("avatar"); } }, "Remove Picture")))
                            :
                                React.createElement("div", { className: "logo-select box", onClick: function () { _this.imageProxy.current.click(); } }, "+ Click here to add your Profile Picture")),
                    React.createElement("div", { className: "col-12 field t-center" },
                        React.createElement("h2", { className: "font-alt " },
                            "First Name ", "/",
                            " Last Name"),
                        React.createElement("input", { type: "text", onChange: handleInput, name: "first_name", placeholder: "first name", value: first_name }),
                        " ",
                        React.createElement("br", null),
                        React.createElement("input", { type: "text", onChange: handleInput, name: "last_name", placeholder: "last name", value: last_name })),
                    React.createElement("div", { className: "col-12 field t-center" },
                        React.createElement("h2", { className: "font-alt t-center" }, "Your Email:"),
                        React.createElement("input", { type: "email", placeholder: "email", onChange: handleInput, name: "email", value: email })),
                    React.createElement("div", { className: "col-12 field t-center" },
                        React.createElement("h2", { className: "font-alt t-center" }, "Your Password:"),
                        React.createElement("input", { type: "password", placeholder: "password", onChange: handleInput, name: "password", value: password })),
                    React.createElement("div", { className: "col-12 field t-center" },
                        React.createElement("h2", { className: "font-alt t-center" }, "Confirm Password:"),
                        React.createElement("input", { type: "password", placeholder: "confirm password", onChange: handleInput, name: "password_confirm", value: password_confirm }))))));
    };
    return UserStage;
}(React.Component));
var ReviewStage = /** @class */ (function (_super) {
    __extends(ReviewStage, _super);
    function ReviewStage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReviewStage.prototype.render = function () {
        var _a = this.props, logo = _a.logo, email = _a.email, email_confirm = _a.email_confirm, profile_picture = _a.profile_picture, first_name = _a.first_name, last_name = _a.last_name, domain = _a.domain, organization_name = _a.organization_name, errors = _a.errors;
        return (React.createElement("div", { className: "row stage-3 settings" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h2", { className: "font-alt-2 t-center" }, "Review your settings:")),
                    React.createElement("div", { className: "col-12 org-info info-block" },
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("div", { className: "col-6" }, logo != null ?
                                React.createElement("img", { src: URL.createObjectURL(logo) })
                                : React.createElement("h2", { className: "t-center font-alt" }, "No logo set")),
                            React.createElement("div", { className: "col-6" },
                                React.createElement("h2", null, "Organization Info:"),
                                React.createElement("p", null,
                                    "Name: ",
                                    organization_name),
                                React.createElement("p", null,
                                    "Domain: ",
                                    domain)))),
                    React.createElement("div", { className: "col-12 user-info info-block" },
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("div", { className: "col-6" }, profile_picture != null ?
                                React.createElement("img", { src: URL.createObjectURL(profile_picture) })
                                : React.createElement("h2", { className: "t-center font-alt" }, "No profile picture set")),
                            React.createElement("div", { className: "col-6" },
                                React.createElement("h2", null, "User Info:"),
                                React.createElement("p", null,
                                    "Name: ",
                                    first_name,
                                    " ",
                                    last_name,
                                    " "),
                                React.createElement("p", null,
                                    "Email: ",
                                    email)))),
                    React.createElement("div", { className: "col-12 errors info-block" }, errors != null ?
                        React.createElement("ul", null, errors.map(function (err, index) {
                            return (React.createElement("li", { key: index }, err));
                        }))
                        : null)))));
    };
    return ReviewStage;
}(React.Component));
var PaymentStage = /** @class */ (function (_super) {
    __extends(PaymentStage, _super);
    function PaymentStage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PaymentStage.prototype.render = function () {
        return (React.createElement("div", { className: "row stage-3 payment" },
            React.createElement("div", { className: "col-12" },
                React.createElement("h2", { className: "font-alt-2 t-center" }, "You've already setup a contract with us, you're all set."))));
    };
    return PaymentStage;
}(React.Component));
/*
            <div className="stage-wrapper row">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6 stage-left">
                            <h1 className="font-alt font-light">
                                {DEPLOYMENT == "TRIAL" ?
                                stage == 1 ?
                                "About Your Company" :
                                stage == 2 ?
                                "About You" :
                                stage == 3 ?
                                "Review" :
                                ""
                                :
                                stage == 1 ?
                                "About Your Company" :
                                stage == 2 ?
                                "About You" :
                                stage == 3 ?
                                "How are you paying?" :
                                stage == 4 ?
                                "Review" :
                                ""
                                }

                            </h1>
                        </div>
                        <div className="col-6 stage-right">
                            <span data-stage={1} onClick={this.updatestage} className={`stage-number h-c ${stage == 1 ? "active" : this.stagesComplete.get(1) == true ? "completed"  : ""}`}>
                                <span className="number">1</span>
                                <i className="icon-check" />
                            </span>
                            <span className="stage-separator" />
                            <span data-stage={2} onClick={this.updatestage} className={`stage-number h-c ${stage == 2 ? "active" : this.stagesComplete.get(2) == true ? "completed"  : ""}`}>
                            <span className="number">2</span>
                                <i className="icon-check" />
                            </span>
                            <span className="stage-separator" />
                            <span data-stage={3} onClick={this.updatestage} className={`stage-number h-c ${stage == 3 ? "active" : this.stagesComplete.get(3) == true ? "completed"  : ""}`}>
                                <span className="number">3</span>
                                    <i className="icon-check" />
                            </span>
                            {DEPLOYMENT == "TRIAL" ? null :
                            <React.Fragment>
                            <span className="stage-separator" />
                            <span data-stage={4} onClick={this.updatestage} className={`stage-number h-c ${stage == 4 ? "active" : this.stagesComplete.get(4) == true ? "completed"  : ""}`}>
                                <span className="number">4</span>
                                    <i className="icon-check" />
                            </span>
                            </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
                </div>

                            {
                    stage == 1 ?
                    <Welcomestage {...stageProps} />
                    :
                    stage == 2 ?
                    <UserStage {...stageProps} />
                    :
                    stage == 3 ?
                    DEPLOYMENT == "TRIAL" ?
                    <ReviewStage {...stageProps} /> :
                    <PaymentStage />
                    :
                    null
                }

                <div className="content-lower container-fluid">
                        <div className="row">
                            <div className="container">
                                <div className="row justify-content-end align-items-center button-wrapper">
                                        <span onClick={this.prevStage} className={`${stage == 1 ? "d-none" : ""} idea-previous-stage h-c`}><i className="icon-previous
                                        " /> Previous</span>
                                            <span className="btn-separator" />
                                        {stage == 1 ?
                                            <button onClick={this.register} disabled={registering} className={`btn btn-blue font-alt ${registering ? "inactive" : ""}`}>
                                            {registering ? "Registering..." : <React.Fragment> Register </React.Fragment>}
                                            </button>
                                            :
                                        <button onClick={this.nextStage} className={`btn btn-blue font-alt ${stage ? "inactive" : ""}`}>Next Step</button>
                                        }
                                </div>
                            </div>
                        </div>
                </div>













            <div className="row heading-wrapper" style={{height: "calc(100vh - 100px)", overflow: "auto"}}>
                <div className="col-12">
                    <h1 className="t-center font-alt-2 welcome-heading">
                        {DEPLOYMENT == "TRIAL" ?
                        this.props.match.params.referral == undefined ?
                        "Ideablock Trial Registration" :
                        this.props.match.params.referral == "gini" ?
                        "GInI AssetChain Registration"
                        :
                        "Powered by Ideablock" :
                        headlines[stage-1]
                            }
                    </h1>
                </div>
            </div>
*/ 
