var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FileInfo } from 'helpers/fileInfo';
import { GetFileType } from 'helpers/fileTypes';
import { FolderInfoString } from 'helpers/folderInfo';
import React from 'react';
var FolderView = /** @class */ (function (_super) {
    __extends(FolderView, _super);
    function FolderView(props) {
        var _this = _super.call(this, props) || this;
        _this.remove = _this.remove.bind(_this);
        _this.select = _this.select.bind(_this);
        return _this;
    }
    FolderView.prototype.remove = function () {
        this.props.actionHandler("REMOVE_FOLDER", this.props.folder);
    };
    FolderView.prototype.select = function () {
        this.props.actionHandler("SELECT_FOLDER", this.props.folder);
    };
    FolderView.prototype.render = function () {
        var _this = this;
        var _a = this.props, folder = _a.folder, depth = _a.depth, parentTree = _a.parentTree, index = _a.index;
        var depthInt = parseInt(depth);
        var indexInt = parseInt(index);
        return (React.createElement("div", { className: "col-12 ".concat(!folder.in_folder ? "folder-list-item" : "sub-folder-list-item", " ").concat(folder.selected ? "selected" : "", " ") },
            React.createElement("div", { className: "".concat(indexInt > 0 ? "b-t" : "", " row align-items-center folder-info") },
                React.createElement("div", { className: "col-md-auto" },
                    React.createElement("i", { className: "icon-folder" })),
                React.createElement("div", { className: "col" },
                    React.createElement("span", { className: "folder-name" },
                        "../".repeat(depthInt),
                        folder.name),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: "folder-size" }, FolderInfoString(folder))),
                React.createElement("div", { className: "col t-right" },
                    React.createElement("span", { onClick: this.remove, className: "remove-folder h-c" }, "Remove Folder"))),
            React.createElement("div", { className: "folder-content row ".concat(depthInt > 1 ? "depth-pass" : "") }, folder.in_folder ?
                React.createElement(React.Fragment, null,
                    folder.files.map(function (f, index) {
                        return React.createElement(FileView, { file: f, actionHandler: _this.props.actionHandler, key: index });
                    }),
                    folder.folders.map(function (f, index) {
                        return React.createElement(FolderView, { index: "".concat(index), parentTree: "".concat(folder.name), depth: "".concat(depthInt + 1), folder: f, actionHandler: _this.props.actionHandler, key: index });
                    }))
                :
                    React.createElement(React.Fragment, null,
                        folder.folders.map(function (f, index) {
                            return React.createElement(FolderView, { index: "".concat(index), parentTree: "".concat(folder.name), depth: "".concat(depthInt + 1), folder: f, actionHandler: _this.props.actionHandler, key: index });
                        }),
                        folder.files.map(function (f, index) {
                            return React.createElement(FileView, { file: f, actionHandler: _this.props.actionHandler, key: index });
                        })))));
    };
    return FolderView;
}(React.Component));
export { FolderView };
var FileView = /** @class */ (function (_super) {
    __extends(FileView, _super);
    function FileView(props) {
        var _this = _super.call(this, props) || this;
        _this.remove = _this.remove.bind(_this);
        _this.select = _this.select.bind(_this);
        return _this;
    }
    FileView.prototype.remove = function () {
        this.props.actionHandler("REMOVE_FILE", this.props.file);
    };
    FileView.prototype.select = function () {
        this.props.actionHandler("SELECT_FILE", this.props.file);
    };
    FileView.prototype.render = function () {
        var file = this.props.file;
        var type = GetFileType(file.name);
        return (React.createElement("div", { className: "col-12 ".concat(file.selected ? "selected" : "", " ").concat(file.in_folder ? "sub-file-list-item" : "file-list-item ") },
            React.createElement("div", { className: "row align-items-center file-info" },
                React.createElement("div", { className: "col-md-auto" }, type == "image" ?
                    React.createElement("img", { className: "file-thumbnail", src: URL.createObjectURL(file.data) })
                    : React.createElement("i", { className: "icon-draft" })),
                React.createElement("div", { className: "col" },
                    React.createElement("span", { className: "file-name", onClick: this.select }, file.name),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: "file-size" },
                        React.createElement("span", { style: { textTransform: "capitalize" } }, type == "no-preview" ? file.name.split('.').pop().toLowerCase() : type),
                        " - ",
                        FileInfo(file.size))),
                React.createElement("div", { className: "col t-right" },
                    React.createElement("span", { className: "remove-file h-c", onClick: this.remove }, "Remove")))));
    };
    return FileView;
}(React.Component));
export { FileView };
