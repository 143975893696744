var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActionHandler } from 'components/fileHandler/actions';
import { FileInfo } from 'helpers/fileInfo';
import { GetFileType } from 'helpers/fileTypes';
import { FolderInfo, FolderInfoString } from 'helpers/folderInfo';
import React from 'react';
var FileViewer = /** @class */ (function (_super) {
    __extends(FileViewer, _super);
    function FileViewer(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            selected: null,
            folders: props.folders || [],
            files: props.files || [],
        };
        _this.actionHandler = ActionHandler.bind(_this);
        return _this;
    }
    FileViewer.prototype.render = function () {
        var _this = this;
        var _a = this.props, open = _a.open, toggle = _a.toggle;
        var _b = this.props, folders = _b.folders, files = _b.files;
        var ideaFolderInfoArr = [0, 0];
        folders.forEach(function (folder) {
            var res = FolderInfo(folder);
            ideaFolderInfoArr[0]++;
            ideaFolderInfoArr[0] += res[0];
            ideaFolderInfoArr[1] += res[1];
        });
        ideaFolderInfoArr[1] += files.length;
        var selected = this.state.selected;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "file-viewer-bg ".concat(open ? "open" : ""), onClick: this.props.toggle }),
            React.createElement("div", { className: "file-viewer container-fluid ".concat(open ? "open" : "") },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-6 half" },
                        React.createElement("div", { className: "row" },
                            React.createElement("h2", { className: "file-viewer-heading" },
                                ideaFolderInfoArr[0],
                                " folders, ",
                                ideaFolderInfoArr[1],
                                " files")),
                        React.createElement("div", { className: "row file-viewer-list" },
                            folders.map(function (folder, index) {
                                return React.createElement(FolderListPreview, { index: "0", depth: "0", parentTree: "", folder: folder, actionHandler: _this.actionHandler, key: index });
                            }),
                            files.map(function (file, index) {
                                return React.createElement(FileListPreview, { file: file, actionHandler: _this.actionHandler, key: index });
                            }))),
                    React.createElement("div", { className: "col-6 half" },
                        React.createElement("div", { className: "row" },
                            React.createElement("h2", { className: "file-viewer-heading" }, selected != null ? selected.name : "")),
                        React.createElement("div", { className: "row" }, selected != null ?
                            React.createElement(PreviewFile, { file: selected })
                            : null))))));
    };
    return FileViewer;
}(React.Component));
export { FileViewer };
var PreviewFile = /** @class */ (function (_super) {
    __extends(PreviewFile, _super);
    function PreviewFile(props) {
        var _this = _super.call(this, props) || this;
        _this.componentDidUpdate = function (prevProps) {
            if (prevProps.file == undefined || _this.props.file == undefined) {
                return;
            }
            if (prevProps.file.id != _this.props.file.id) {
                _this.setupFile(_this.props.file);
            }
        };
        _this.state = {
            fileText: '',
            fileType: '',
        };
        _this.setupFile = _this.setupFile.bind(_this);
        _this.pdfRef = React.createRef();
        return _this;
    }
    PreviewFile.prototype.setupFile = function (file) {
        var _this = this;
        var split = file.name.split(".");
        var extension = split[split.length - 1];
        if (extension == "mp3") {
            this.type = "audio";
            return;
        }
        if (this.type == "text") {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                var text = reader_1.result;
                _this.setState({
                    fileText: text
                });
            };
            if (file.link != undefined && file.link != "") {
                this.setState({
                    fileType: 'linked-file',
                });
            }
            else {
                reader_1.readAsText(file.data);
            }
        }
        if (this.type == "pdf") {
        }
    };
    PreviewFile.prototype.componentDidMount = function () {
        var file = this.props.file;
        this.setupFile(file);
    };
    PreviewFile.prototype.componentWillUnmount = function () {
        this.setState({
            fileText: '',
        });
    };
    PreviewFile.prototype.render = function () {
        var file = this.props.file;
        this.type = GetFileType(file.name);
        return (React.createElement("div", { className: "file-preview" }, this.type == "video" ? React.createElement("video", { src: file.link != undefined && file.link != "" ? file.link : URL.createObjectURL(file.data), controls: true }) :
            this.type == "image" ? React.createElement("img", { src: file.link != undefined && file.link != "" ? file.link : URL.createObjectURL(file.data) }) :
                this.type == "audio" ? React.createElement("audio", { controls: true, src: file.link }) :
                    this.type == "text" ?
                        this.state.fileType == "linked-file" ?
                            React.createElement("iframe", { className: "text-reader", src: file.link })
                            :
                                React.createElement("pre", null, this.state.fileText)
                        :
                            this.type == "pdf" ?
                                React.createElement("iframe", { className: "pdf-viewer", src: "https://docs.google.com/viewerng/viewer?url=".concat(file.link, "&embedded=true") })
                                :
                                    null));
    };
    return PreviewFile;
}(React.Component));
var FolderListPreview = /** @class */ (function (_super) {
    __extends(FolderListPreview, _super);
    function FolderListPreview(props) {
        return _super.call(this, props) || this;
    }
    FolderListPreview.prototype.render = function () {
        var _this = this;
        var _a = this.props, folder = _a.folder, depth = _a.depth, parentTree = _a.parentTree, index = _a.index;
        folder.folders == null ? folder.folders = [] : null;
        folder.files == null ? folder.files = [] : null;
        var foldername = folder.name.split("/").pop();
        var depthInt = parseInt(depth);
        var indexInt = parseInt(index);
        return (React.createElement("div", { className: "col-12 ".concat(!folder.in_folder ? "folder-list-item" : "sub-folder-list-item", " ").concat(folder.selected ? "selected" : "", " ") },
            React.createElement("div", { className: "".concat(indexInt > 0 ? "b-t" : "", " row align-items-center folder-info") },
                React.createElement("div", { className: "col-md-auto" },
                    React.createElement("i", { className: "icon-folder" })),
                React.createElement("div", { className: "col" },
                    React.createElement("span", { className: "folder-name" },
                        "../".repeat(depthInt),
                        foldername),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: "folder-size" }, FolderInfoString(folder)))),
            React.createElement("div", { className: "folder-content row ".concat(depthInt > 1 ? "depth-pass" : "") }, folder.in_folder ?
                React.createElement(React.Fragment, null,
                    folder.files.map(function (f, index) {
                        return React.createElement(FileListPreview, { file: f, actionHandler: _this.props.actionHandler, key: index });
                    }),
                    folder.folders.map(function (f, index) {
                        return React.createElement(FolderListPreview, { index: "".concat(index), parentTree: "".concat(foldername), depth: "".concat(depthInt + 1), folder: f, actionHandler: _this.props.actionHandler, key: index });
                    }))
                :
                    React.createElement(React.Fragment, null,
                        folder.folders.map(function (f, index) {
                            return React.createElement(FolderListPreview, { index: "".concat(index), parentTree: "".concat(foldername), depth: "".concat(depthInt + 1), folder: f, actionHandler: _this.props.actionHandler, key: index });
                        }),
                        folder.files.map(function (f, index) {
                            return React.createElement(FileListPreview, { file: f, actionHandler: _this.props.actionHandler, key: index });
                        })))));
    };
    return FolderListPreview;
}(React.Component));
var FileListPreview = /** @class */ (function (_super) {
    __extends(FileListPreview, _super);
    function FileListPreview(props) {
        var _this = _super.call(this, props) || this;
        _this.select = _this.select.bind(_this);
        return _this;
    }
    FileListPreview.prototype.select = function () {
        this.props.actionHandler("SELECT_FILE", this.props.file);
    };
    FileListPreview.prototype.render = function () {
        var file = this.props.file;
        var type = GetFileType(file.name);
        return (React.createElement("div", { onClick: this.select, className: "col-12 h-c ".concat(file.selected ? "selected" : "", " ").concat(file.in_folder ? "sub-file-list-item" : "file-list-item ") },
            React.createElement("div", { className: "row align-items-center file-info" },
                React.createElement("div", { className: "col-md-auto" }, type == "image" ? file.thumbnail != undefined && file.thumbnail != "" ? React.createElement("img", { src: file.thumbnail }) : React.createElement("img", { src: URL.createObjectURL(file.data) })
                    : React.createElement("i", { className: "icon-draft" })),
                React.createElement("div", { className: "col" },
                    React.createElement("span", { className: "file-name" }, file.name),
                    " ",
                    React.createElement("br", null),
                    React.createElement("span", { className: "file-size" },
                        React.createElement("span", { style: { textTransform: "capitalize" } }, type == "no-preview" ? file.name.split('.').pop().toLowerCase() : type),
                        " - ",
                        FileInfo(file.size))))));
    };
    return FileListPreview;
}(React.Component));
export { FileListPreview };
