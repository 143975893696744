var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { UpdateInput } from 'helpers/inputHandlers';
import { FORMDATA_REQUEST, USER_POST_REQUEST } from 'helpers/requests';
import React from 'react';
import { connect } from 'react-redux';
import DashboardTemplate from 'templates/dashboard';
var mapState = function (state) { return ({
    User: state.App.User,
}); };
var mapDispatch = {
    UpdateUser: function (user) { return ({ type: 'UPDATE_APP_USER', user: user }); }
};
var connector = connect(mapState, mapDispatch);
var SettingsLocal = /** @class */ (function (_super) {
    __extends(SettingsLocal, _super);
    function SettingsLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.handleInput = function (e) {
            var _a = e.currentTarget, name = _a.name, value = _a.value;
            if (name == "firstName") {
                if (value != _this.state.firstName_INIT) {
                    _this.setState(function (prev) { return ({
                        settingsDelta: true,
                        changes: prev.changes.indexOf("firstName") != -1 ? __spreadArray([], prev.changes, true) : __spreadArray(__spreadArray([], prev.changes, true), ["firstName"], false)
                    }); }, function () {
                    });
                }
                else {
                    _this.setState(function (prev) { return ({
                        changes: prev.changes.filter(function (c) {
                            return c != "firstName";
                        }),
                        settingsDelta: !(_this.state.lastName == _this.state.lastName_INIT)
                    }); }, function () {
                    });
                }
            }
            if (name == "lastName") {
                if (value != _this.state.lastName_INIT) {
                    _this.setState(function (prev) { return ({
                        settingsDelta: true,
                        changes: prev.changes.indexOf("lastName") != -1 ? __spreadArray([], prev.changes, true) : __spreadArray(__spreadArray([], prev.changes, true), ["lastName"], false)
                    }); }, function () {
                        var user = _this.props.User;
                        user.last_name = _this.state.lastName;
                    });
                }
                else {
                    _this.setState(function (prev) { return ({
                        changes: prev.changes.filter(function (c) {
                            return c != "lastName";
                        }),
                        settingsDelta: !(_this.state.firstName == _this.state.firstName_INIT)
                    }); }, function () {
                        var user = _this.props.User;
                        user.first_name = _this.state.firstName;
                    });
                }
            }
            _this.updateInput(e);
        };
        _this.state = {
            firstName: _this.props.User.first_name,
            lastName: _this.props.User.last_name,
            avatar: _this.props.User.avatar,
            newAvatar: null,
            settingsDelta: false,
            changes: [],
            firstName_INIT: _this.props.User.first_name,
            lastName_INIT: _this.props.User.last_name,
            avatar_CHANGED: false,
        };
        _this.handleInput = _this.handleInput;
        _this.handleNewAvatar = _this.handleNewAvatar.bind(_this);
        _this.updateInput = UpdateInput.bind(_this);
        _this.cancelAvatarChange = _this.cancelAvatarChange.bind(_this);
        _this.submitAvatar = _this.submitAvatar.bind(_this);
        _this.submitSettings = _this.submitSettings.bind(_this);
        return _this;
    }
    SettingsLocal.prototype.handleNewAvatar = function (e) {
        this.setState({
            newAvatar: e.currentTarget.files[0],
            avatar_CHANGED: (e.currentTarget.files[0] == undefined ? false : true)
        });
    };
    SettingsLocal.prototype.cancelAvatarChange = function () {
        this.setState({
            newAvatar: null,
            avatar_CHANGED: false,
        });
    };
    SettingsLocal.prototype.submitAvatar = function () {
        var _this = this;
        var fd = new FormData();
        fd.append("avatar", this.state.newAvatar);
        FORMDATA_REQUEST('update-profile-photo', fd, function (res) {
            _this.setState({
                avatar: res.avatar,
                avatar_CHANGED: false,
                newAvatar: null,
            }, function () {
                var user = _this.props.User;
                user.avatar = res.avatar;
                _this.props.UpdateUser(user);
            });
        }, function (err) {
            console.log(err);
        });
    };
    SettingsLocal.prototype.submitSettings = function () {
        var _this = this;
        USER_POST_REQUEST('update-user-settings', { first_name: this.state.firstName, last_name: this.state.lastName }, function (res) {
            _this.setState({
                firstName_INIT: res.first_name,
                lastName_INIT: res.last_name,
                lastName: res.last_name,
                firstName: res.first_name,
                settingsDelta: false,
                changes: [],
            }, function () {
                var User = _this.props.User;
                User.first_name = _this.state.firstName;
                User.last_name = _this.state.lastName;
                _this.props.UpdateUser(User);
            });
        }, function (err) {
            console.log(err);
        });
    };
    SettingsLocal.prototype.render = function () {
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "container settings-page" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h1", { className: "font-alt heading" }, "Settings: ")),
                    React.createElement("div", { className: "col-12 settings-form" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-6" },
                                React.createElement("label", { htmlFor: "firstName" }, "First Name:"),
                                React.createElement("input", { type: "text", name: "firstName", value: this.state.firstName, onChange: this.handleInput })),
                            React.createElement("div", { className: "col-6" },
                                React.createElement("label", { htmlFor: "lastName" }, "Last Name:"),
                                React.createElement("input", { type: "text", name: "lastName", value: this.state.lastName, onChange: this.handleInput })),
                            React.createElement("div", { className: "col-12 submit" },
                                React.createElement("button", { onClick: this.submitSettings, className: "btn btn-blue-alt", disabled: this.state.settingsDelta == false }, "Update Profile Information")),
                            React.createElement("div", { className: "col-12" },
                                React.createElement("label", { htmlFor: "avatar" }, "Profile Photo: "),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-12 t-center" }, this.state.avatar != '' && this.state.newAvatar == null ?
                                        React.createElement("img", { src: this.state.avatar })
                                        : this.state.newAvatar != null && this.state.newAvatar != undefined ?
                                            React.createElement(React.Fragment, null,
                                                React.createElement("img", { className: "avatar-preview", src: URL.createObjectURL(this.state.newAvatar) }),
                                                React.createElement("a", { className: 'btn btn-cancel', onClick: this.cancelAvatarChange }, "Cancel"))
                                            : React.createElement("span", null, "No profile photo"))),
                                React.createElement("input", { type: "file", onChange: this.handleNewAvatar, accept: "image/*" })),
                            React.createElement("div", { className: "col-12 submit" },
                                React.createElement("button", { onClick: this.submitAvatar, className: "btn btn-blue-alt", disabled: this.state.avatar_CHANGED == false }, "Update Profile Photo"))))))));
    };
    return SettingsLocal;
}(React.Component));
var SettingsPage = connector(SettingsLocal);
export default SettingsPage;
