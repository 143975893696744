var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Counter from 'helpers/counter';
import React from 'react';
var AddIdea = /** @class */ (function (_super) {
    __extends(AddIdea, _super);
    function AddIdea() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddIdea.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row field" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("label", { htmlFor: "Name" }, "Add a title"),
                    React.createElement("div", { className: "counter-container" },
                        React.createElement("input", { className: "counted", onChange: this.props.updateInput, name: "Title", value: this.props.data.Title, placeholder: "Give it a name" }),
                        React.createElement(Counter, { limit: 255, value: this.props.data.Title })))),
            React.createElement("div", { className: "row field" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("label", { htmlFor: "Description" }, "Description"),
                    React.createElement("textarea", { onChange: this.props.updateInput, name: "Description", value: this.props.data.Description, placeholder: "Add a description to help identify key aspects of your IP. You\u2019ll be able to add supporting files and folders later in the process." })))));
    };
    return AddIdea;
}(React.Component));
export default AddIdea;
