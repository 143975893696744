var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var DropDown = /** @class */ (function (_super) {
    __extends(DropDown, _super);
    function DropDown(props) {
        var _this = _super.call(this, props) || this;
        _this.select = function (value) {
            _this.toggle();
            _this.props.clickAction(value);
        };
        _this.outsideClick = function (e) {
            if (_this.wrapper && !_this.wrapper.current.contains(event.target)) {
                _this.setState({
                    open: false,
                }, function () {
                    document.removeEventListener("mousedown", _this.outsideClick);
                });
            }
        };
        _this.state = {
            open: false,
        };
        _this.select = _this.select.bind(_this);
        _this.toggle = _this.toggle.bind(_this);
        _this.wrapper = React.createRef();
        _this.outsideClick = _this.outsideClick.bind(_this);
        return _this;
    }
    DropDown.prototype.toggle = function () {
        var open = this.state.open;
        if (!open) {
            document.addEventListener("mousedown", this.outsideClick);
        }
        this.setState({
            open: !open
        });
    };
    DropDown.prototype.render = function () {
        var _this = this;
        var _a = this.props, objClass = _a.objClass, options = _a.options, valueIdentifier = _a.valueIdentifier, value = _a.value, clickAction = _a.clickAction;
        return (React.createElement("div", { className: "".concat(objClass != null ? objClass : "", " ib-dropdown") },
            React.createElement("div", { className: "dropdown-value", onClick: this.toggle },
                valueIdentifier != null ? valueIdentifier : "",
                " ",
                options.filter(function (o) { return o.value == value; })[0].text,
                React.createElement("i", { className: "icon-cube" })),
            React.createElement("div", { className: "dropdown-menu ".concat(this.state.open ? "open" : ""), ref: this.wrapper }, options.map(function (o, i) {
                return (React.createElement("div", { className: "dropdown-option", key: i, onClick: function () { _this.select(o.value); } }, o.text));
            }))));
    };
    return DropDown;
}(React.Component));
export default DropDown;
