import Cookies from 'universal-cookie';
export function handleUpdatedTheme(theme) {
    document.body.classList.add("__theme_transition");
    var cookies = new Cookies();
    cookies.set("theme", theme, { path: '/' });
    document.documentElement.setAttribute("theme", theme);
    setTimeout(function () {
        document.body.classList.remove("__theme_transition");
    }, 500);
}
export function handleUpdatedAssetView(view) {
    var cookies = new Cookies();
    cookies.set("asset_view", view, { path: '/' });
}
