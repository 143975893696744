var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { USER_POST_REQUEST } from "helpers/requests";
import { TimeSince } from "helpers/timesince";
import React from "react";
var ArchivedIdeaView = /** @class */ (function (_super) {
    __extends(ArchivedIdeaView, _super);
    function ArchivedIdeaView(props) {
        var _this = _super.call(this, props) || this;
        _this.loadIdea = function (id) {
            window.location.href = "/idea/".concat(id);
        };
        _this.fetchIdeas = function () {
            _this.setState({ isLoading: true });
            USER_POST_REQUEST("get-archived-ideas", {}, function (res) {
                _this.setState({
                    ideas: res.ideas || [],
                    isLoading: false,
                    archiveErrors: undefined,
                });
            }, function (err) {
                console.error(err);
                _this.setState({
                    isLoading: false,
                    archiveErrors: "Failed to load archived ideas",
                });
            });
        };
        _this.archiveIdea = function (ideaId, archive) {
            _this.setState(function (prevState) {
                var _a;
                return ({
                    isArchiving: __assign(__assign({}, prevState.isArchiving), (_a = {}, _a[ideaId] = true, _a)),
                });
            });
            USER_POST_REQUEST("archive-idea", { idea_id: ideaId, archive: archive ? false : true }, function (res) {
                _this.setState(function (prevState) {
                    var _a;
                    return ({
                        isArchiving: __assign(__assign({}, prevState.isArchiving), (_a = {}, _a[ideaId] = false, _a)),
                    });
                }, function () { return _this.fetchIdeas(); });
            }, function (err) {
                _this.setState(function (prevState) {
                    var _a;
                    return ({
                        archiveErrors: "An error occurred while archiving the idea",
                        isArchiving: __assign(__assign({}, prevState.isArchiving), (_a = {}, _a[ideaId] = false, _a)),
                    });
                });
            });
        };
        _this.state = {
            ideas: [],
            isLoading: true,
            isArchiving: {},
        };
        _this.loadIdea = _this.loadIdea.bind(_this);
        return _this;
    }
    ArchivedIdeaView.prototype.componentDidMount = function () {
        this.fetchIdeas();
    };
    ArchivedIdeaView.prototype.render = function () {
        var _this = this;
        var _a = this.state, ideas = _a.ideas, isLoading = _a.isLoading, archiveErrors = _a.archiveErrors, isArchiving = _a.isArchiving;
        return (React.createElement("div", { className: " " },
            React.createElement("div", { className: "bg-[#2F3744] py-10 mb-10" },
                React.createElement("div", { className: "container mx-auto px-4" },
                    React.createElement("div", { className: "max-w-7xl mx-auto" },
                        React.createElement("div", { className: "" },
                            React.createElement("h4", { className: "overview" },
                                React.createElement("a", { className: "text-[#999999] no-underline text-xs font-bold mb-3 transition-colors duration-400 hover:text-[#98C13D] hover:cursor-pointer inline-flex items-center", onClick: function () {
                                        _this.props.updateView("default");
                                    } },
                                    React.createElement("svg", { className: "w-4 h-4 mr-2", fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", viewBox: "0 0 24 24", stroke: "currentColor" },
                                        React.createElement("path", { d: "M15 19l-7-7 7-7" })),
                                    "Back to Administration Dashboard")),
                            React.createElement("h1", { className: "text-3xl pt-2 font-mono uppercase font-light text-white" }, "MANAGE ARCHIVED IDEAS"))))),
            React.createElement("div", { className: "container mx-auto px-4" },
                React.createElement("div", { className: "max-w-7xl mx-auto" },
                    archiveErrors && (React.createElement("div", { className: "mb-4 p-4  border border-red-400 text-red-700 rounded" }, archiveErrors)),
                    isLoading ? (React.createElement("div", { className: "flex justify-center items-center py-12" },
                        React.createElement("div", { className: "animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" }))) : ideas.length === 0 ? (React.createElement("div", { className: "text-center py-12" },
                        React.createElement("h2", { className: "text-2xl font-light text-gray-600" }, "No Archived ideas...yet"))) : (React.createElement("div", { className: " rounded-lg shadow overflow-hidden" },
                        React.createElement("table", { className: "min-w-full divide-y " },
                            React.createElement("tbody", { className: " " }, ideas.map(function (idea, i) { return (React.createElement(TableIdeaPreview, { key: i, idea: idea, loadIdea: _this.loadIdea, archiveIdea: _this.archiveIdea, isArchiving: isArchiving[idea.id] || false })); })))))))));
    };
    return ArchivedIdeaView;
}(React.Component));
var TableIdeaPreview = /** @class */ (function (_super) {
    __extends(TableIdeaPreview, _super);
    function TableIdeaPreview() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TableIdeaPreview.prototype.render = function () {
        var _a = this.props, idea = _a.idea, loadIdea = _a.loadIdea, archiveIdea = _a.archiveIdea, isArchiving = _a.isArchiving;
        var thumbCheck = parseInt(idea.thumbnail);
        if (!isNaN(thumbCheck)) {
            idea.thumbnail = "/images/cover_images/" + idea.thumbnail + ".png";
        }
        return (React.createElement("tr", { className: "hover:bg-[#193762] transition-colors" },
            React.createElement("td", { className: "py-2 px-6 cursor-pointer", onClick: function () { return loadIdea(idea.id); } },
                React.createElement("img", { className: "w-12 h-12 object-cover rounded-md shadow-sm", src: idea.thumbnail || "/images/cover_images/1.png", alt: idea.title })),
            React.createElement("td", { className: "py-2 px-6 cursor-pointer", onClick: function () { return loadIdea(idea.id); } },
                React.createElement("span", { className: "font-medium text-white hover:text-blue-600" }, idea.title)),
            React.createElement("td", { className: "py-2 px-6 text-sm text-gray-500 cursor-pointer", onClick: function () { return loadIdea(idea.id); } },
                "Updated ",
                TimeSince(idea.created),
                " ago"),
            idea.archived ? (React.createElement("td", { className: "py-2 px-4 text-left flex items-center" },
                React.createElement("span", { className: "text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-full" }, "Archived"))) : (React.createElement("td", { className: "py-2 px-4" })),
            React.createElement("td", { className: "py-2 px-4 text-right" }, idea.private && (React.createElement("i", { className: "icon-status text-[#98C13D] hover:text-[#8ab034]" }))),
            React.createElement("td", { className: "py-2 px-6" },
                React.createElement("button", { onClick: function (e) {
                        e.stopPropagation();
                        archiveIdea(idea.id, idea.archived);
                    }, disabled: isArchiving, className: "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white \n              ".concat(isArchiving
                        ? "bg-gray-400 cursor-not-allowed"
                        : idea.archived
                            ? "bg-green-600 hover:bg-green-700"
                            : "bg-red-600 hover:bg-red-700", "\n              focus:outline-none focus:ring-2 focus:ring-offset-2 \n              ").concat(idea.archived ? "focus:ring-green-500" : "focus:ring-red-500") },
                    isArchiving ? (React.createElement("svg", { className: "animate-spin -ml-1 mr-2 h-4 w-4 text-white", fill: "none", viewBox: "0 0 24 24" },
                        React.createElement("circle", { className: "opacity-25", cx: "12", cy: "12", r: "10", stroke: "currentColor", strokeWidth: "4" }),
                        React.createElement("path", { className: "opacity-75", fill: "currentColor", d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" }))) : null,
                    isArchiving
                        ? "Processing..."
                        : idea.archived
                            ? "Unarchive"
                            : "Archive"))));
    };
    return TableIdeaPreview;
}(React.Component));
export default ArchivedIdeaView;
