var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "universal-cookie";
import { ManageSubscriptionNav } from "templates/manageSubscriptionNav";
import { useLocation } from "react-router-dom";
import { CREDENTIALED_REQUEST } from "helpers/requests";
import { Login as LoginAction } from "auth/login";
var ManageSubscription = function () {
    var cardElementRef = useRef(null);
    var location = useLocation();
    var stripePromiseRef = useRef(null);
    var hasInitialFetchRef = useRef(false); // New ref to track initial fetch
    var _a = useState({}), quantities = _a[0], setQuantities = _a[1];
    var _b = useState(null), verificationToken = _b[0], setVerificationToken = _b[1];
    var _c = useState(false), processing = _c[0], setProcessing = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(null), success = _e[0], setSuccess = _e[1];
    var _f = useState(null), stripe = _f[0], setStripe = _f[1];
    var _g = useState(null), cardElement = _g[0], setCardElement = _g[1];
    var _h = useState(false), showPaymentForm = _h[0], setShowPaymentForm = _h[1];
    var _j = useState(null), clientSecret = _j[0], setClientSecret = _j[1];
    var _k = useState([]), products = _k[0], setProducts = _k[1];
    var _l = useState(false), loading = _l[0], setLoading = _l[1];
    var _m = useState(null), productError = _m[0], setProductError = _m[1];
    useEffect(function () {
        if (!stripePromiseRef.current) {
            stripePromiseRef.current = loadStripe(
            // "pk_test_51QSevF2Y7DlXY3qYbBKocDnUEyUme2aMcEJ8KYYisu3RnORWWDpenwPKfjC1r5WsCbpIENrExCFq1NQSuDGTonFz00kkp2bC7k"
            "pk_test_51QUDfmQotevYImKaVdCRiLT1ujfkwBrAHJoSNMCbOF5f6mJSEEYzFUZUS9oFcyaCPczhzekLpoRcHB2ixILj9DMz000vuC713z");
            stripePromiseRef.current.then(setStripe).catch(function (err) {
                console.error("Stripe initialization error:", err);
                setError(err.message);
            });
        }
    }, []);
    useEffect(function () {
        var fetchProducts = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (hasInitialFetchRef.current || loading) {
                    return [2 /*return*/];
                }
                setLoading(true);
                setProductError(null);
                try {
                    CREDENTIALED_REQUEST("get-stripe-products", {}, function (res) {
                        var productsData = res;
                        var initialQuantities = {};
                        productsData.forEach(function (product) {
                            initialQuantities[product.id] = 0;
                        });
                        // Get URL parameters
                        var searchParams = new URLSearchParams(location.search);
                        var admin_seats = searchParams.get("admin_seats");
                        var user_seats = searchParams.get("user_seats");
                        var token = searchParams.get("token");
                        setVerificationToken(token);
                        if (admin_seats || user_seats) {
                            productsData.forEach(function (product) {
                                var productNameLower = product.name.toLowerCase();
                                if (productNameLower.includes("admin") && admin_seats) {
                                    initialQuantities[product.id] = parseInt(admin_seats) || 0;
                                }
                                if (productNameLower.includes("user") && user_seats) {
                                    initialQuantities[product.id] = parseInt(user_seats) || 0;
                                }
                            });
                        }
                        setProducts(productsData);
                        setQuantities(initialQuantities);
                        hasInitialFetchRef.current = true; // Mark as fetched
                        setLoading(false);
                    }, function (err) {
                        var _a, _b;
                        setProductError(((_b = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errors) || ["Something went wrong"]);
                        setLoading(false);
                    });
                }
                catch (error) {
                    setProductError(error.message);
                    setLoading(false);
                }
                return [2 /*return*/];
            });
        }); };
        fetchProducts();
    }, []);
    useEffect(function () {
        if (!stripe ||
            !clientSecret ||
            !showPaymentForm ||
            !cardElementRef.current ||
            cardElement) {
            return;
        }
        var initializeCardElement = function () {
            try {
                var elements = stripe.elements({
                    clientSecret: clientSecret,
                    appearance: {
                        theme: "stripe",
                    },
                });
                var cookies = new Cookies();
                var theme = cookies.get("theme");
                var newCardElement = elements.create("card", {
                    style: {
                        base: {
                            color: theme === "dark" ? "#fff" : "#000",
                            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                            fontSmoothing: "antialiased",
                            fontSize: "16px",
                            "::placeholder": {
                                color: theme === "dark" ? "#ccc" : "#000",
                            },
                        },
                        invalid: {
                            color: "#fa755a",
                            iconColor: "#fa755a",
                        },
                    },
                });
                newCardElement.mount(cardElementRef.current);
                setCardElement(newCardElement);
                newCardElement.on("change", function (event) {
                    setError(event.error ? event.error.message : null);
                });
            }
            catch (err) {
                console.error("Card element initialization error:", err);
                setError("Failed to initialize payment form");
            }
        };
        initializeCardElement();
    }, [stripe, clientSecret, showPaymentForm]);
    // Cleanup card element
    useEffect(function () {
        return function () {
            if (cardElement) {
                cardElement.destroy();
            }
        };
    }, [cardElement]);
    var calculateTotalAmount = function () {
        return Object.entries(quantities).reduce(function (total, _a) {
            var productId = _a[0], quantity = _a[1];
            var product = products.find(function (p) { return p.id === productId; });
            return total + (product ? (product.price * quantity) / 100 : 0);
        }, 0);
    };
    var updateQuantity = function (productId, value) {
        setQuantities(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[productId] = Math.max(0, value), _a)));
        });
    };
    var handleGetPaymentIntent = function () { return __awaiter(void 0, void 0, void 0, function () {
        var items;
        return __generator(this, function (_a) {
            try {
                items = Object.entries(quantities)
                    .filter(function (_a) {
                    var _ = _a[0], quantity = _a[1];
                    return quantity > 0;
                })
                    .map(function (_a) {
                    var productId = _a[0], quantity = _a[1];
                    return ({
                        productId: productId,
                        quantity: parseInt(quantity.toString()),
                    });
                });
                // create-checkout-session
                CREDENTIALED_REQUEST("create-checkout-session", { items: items, token: verificationToken }, function (res) {
                    setClientSecret(res.clientSecret);
                    setShowPaymentForm(true);
                }, function (err) {
                    console.log(err);
                    setError(err.message);
                });
            }
            catch (err) {
                console.error("Payment intent error:", err);
                setError(err.message);
            }
            return [2 /*return*/];
        });
    }); };
    var handlePayment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, confirmError, paymentIntent, admin_seats_1, user_seats_1, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!stripe || !cardElement || !clientSecret) {
                        console.log("Missing payment requirements:", {
                            stripe: !!stripe,
                            cardElement: !!cardElement,
                            clientSecret: !!clientSecret,
                        });
                        return [2 /*return*/];
                    }
                    setProcessing(true);
                    setError(null);
                    setSuccess(null);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, stripe.confirmCardPayment(clientSecret, {
                            payment_method: { card: cardElement },
                        })];
                case 2:
                    _a = _b.sent(), confirmError = _a.error, paymentIntent = _a.paymentIntent;
                    if (confirmError) {
                        console.log("Payment confirmation error:", confirmError);
                        setError(confirmError.message);
                        return [2 /*return*/];
                    }
                    setSuccess("Payment successful!");
                    admin_seats_1 = 1;
                    user_seats_1 = 0;
                    Object.entries(quantities).forEach(function (_a) {
                        var productId = _a[0], quantity = _a[1];
                        var product = products.find(function (p) { return p.id === productId; });
                        if (product) {
                            if (product.name.toLowerCase().includes("admin")) {
                                admin_seats_1 += quantity;
                                if (admin_seats_1 <= 0) {
                                    admin_seats_1 = 1;
                                }
                            }
                            else if (product.name.toLowerCase().includes("user")) {
                                user_seats_1 += quantity;
                            }
                        }
                    });
                    CREDENTIALED_REQUEST("verify-payment", {
                        payment_intent_id: paymentIntent.id,
                        token: verificationToken,
                        admin_seats: admin_seats_1,
                        user_seats: user_seats_1,
                    }, function (res) {
                        LoginAction(res).then(function () {
                            window.location.href = "/manage-seat";
                        });
                    }, function (err) {
                        console.log(err);
                        setError(err.message);
                    });
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _b.sent();
                    console.error("Payment confirmation error:", err_1);
                    setError(err_1.message);
                    return [3 /*break*/, 5];
                case 4:
                    setProcessing(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (loading)
        return (React.createElement(ManageSubscriptionNav, null,
            React.createElement("div", { className: "p-4 w-full flex justify-center items-center" }, "Loading...")));
    if (productError)
        return (React.createElement(ManageSubscriptionNav, null,
            React.createElement("div", { className: "p-4 text-red-500 w-full flex justify-center items-center" }, productError)));
    var totalAmount = calculateTotalAmount();
    return (React.createElement(ManageSubscriptionNav, null,
        React.createElement("div", { className: "max-w-4xl mx-auto p-5" },
            React.createElement("div", { className: "space-y-6" },
                React.createElement("h2", { className: "text-2xl font-bold" }, "Subscription Details"),
                React.createElement("div", { className: "flex flex-wrap gap-2" }, products.map(function (product) { return (React.createElement("div", { key: product.id, className: "p-6 rounded-lg shadow border border-gray-200" },
                    React.createElement("h3", { className: "text-xl font-semibold capitalize" }, product.name),
                    React.createElement("p", { className: "" }, product.description),
                    React.createElement("div", { className: "" },
                        React.createElement("label", { className: "block" }, "Number of seats:"),
                        React.createElement("input", { type: "number", value: quantities[product.id] || 0, onChange: function (e) {
                                return updateQuantity(product.id, parseInt(e.target.value));
                            }, min: "0", className: "w-full my-2.5 p-3 border border-solid rounded-md bg-transparent mt-3 block" }),
                        React.createElement("p", { className: "mt-2 font-medium" },
                            "$",
                            (product.price / 100).toFixed(2),
                            " per seat",
                            React.createElement("span", { className: "ml-4" },
                                "Subtotal: $",
                                ((product.price * (quantities[product.id] || 0)) /
                                    100).toFixed(2)))))); })),
                React.createElement("div", { className: "text-right text-xl font-bold" },
                    "Total Amount: $",
                    totalAmount.toFixed(2)),
                !showPaymentForm ? (React.createElement("button", { onClick: handleGetPaymentIntent, disabled: totalAmount <= 0, className: "w-full bg-[#CAFC3D] text-black py-2 px-4 rounded-md font-semibold disabled:opacity-50" }, "Pay Now")) : (React.createElement("div", { className: "p-6 rounded-lg border border-gray-200" },
                    React.createElement("h3", { className: "text-xl font-semibold mb-4" }, "Payment Details"),
                    React.createElement("div", { className: "p-3 border border-gray-200 rounded-md" },
                        React.createElement("div", { ref: cardElementRef, className: "min-h-[40px]" })),
                    React.createElement("button", { onClick: handlePayment, disabled: processing || !stripe || !cardElement, className: "w-full mt-4 bg-[#CAFC3D] py-2 px-4 rounded-md font-semibold disabled:opacity-50" }, processing ? "Processing..." : "Subscribe Now"),
                    error && (React.createElement("div", { className: "mt-4 p-3 border border-red-500 text-red-700 rounded" }, error)),
                    success && (React.createElement("div", { className: "mt-4 p-3 border border-green-500 text-green-700 rounded" }, success))))))));
};
export default ManageSubscription;
