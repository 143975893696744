export function Today() {
    var d = new Date;
    var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return "".concat(weekdays[d.getDay()], ", ").concat(d.toLocaleString('default', { month: 'long' }), " ").concat(d.getDate());
}
export function UnixToDate(date) {
    var millis = date * 1000;
    var d = new Date(millis);
    return "".concat(d.getMonth(), "/").concat(d.getDate(), "/").concat(d.getFullYear());
}
