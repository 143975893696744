var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var Icons = /** @class */ (function (_super) {
    __extends(Icons, _super);
    function Icons() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Icons.prototype.render = function () {
        var icons = ["dark_mode", "light_mode", "family", "files", "previous", "project", "tag", "add", "cancel", "check", "cloud_upload", "description", "folder", "permissions", "search", "alert", "allassets", "archive", "assets", "assets_joined", "check_bubble", "compass", "cube_check", "cube_dotted", "cube", "cube_outline", "down_triangle", "download", "draft", "edit", "favorite", "favorite_filled", "group", "home", "ideabase", "ideablock_logo_mark", "info_filled", "info", "insights", "link", "logo_full", "logo_mark", "logo_mark_cube", "logout", "notification", "pending", "pinned", "projects", "settings", "share", "status", "support", "toggle", "verified"];
        return (React.createElement("div", { className: "container", style: { fontSize: "24px" } },
            icons.length,
            React.createElement("div", { className: "row" }, icons.map(function (icon) {
                return (React.createElement("div", { className: "col-12" },
                    React.createElement("span", { className: "icon-".concat(icon) }),
                    React.createElement("span", { style: { marginLeft: "15px" } },
                        "icon-",
                        icon)));
            }))));
    };
    return Icons;
}(React.Component));
export default Icons;
