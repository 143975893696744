var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { USER_POST_REQUEST } from "helpers/requests";
import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { ManageSeatNav } from "templates/manageSeatNav";
var mapState = function (state) { return ({
    LoggedIn: state.App.LoggedIn,
    User: state.App.User,
    Organization: state.App.Organization,
}); };
var mapDispatch = {
    SetAppLoaded: function (loaded) { return ({
        type: "SET_APP_LOADED",
        loaded: loaded,
    }); },
};
var connector = connect(mapState, mapDispatch);
var ManageSeatLocal = /** @class */ (function (_super) {
    __extends(ManageSeatLocal, _super);
    function ManageSeatLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.validateEmailDomain = function (email) {
            var domain = _this.state.domain;
            var domainRegex = new RegExp("@".concat(domain, "$"));
            return domainRegex.test(email);
        };
        _this.validateEmailUnique = function (email) {
            return _this.state.members.every(function (member) { return member.email !== email; });
        };
        _this.addMember = function (first_name, last_name, email, role) {
            _this.setState({
                errors: [],
            });
            if (!_this.validateEmailDomain(email)) {
                _this.setState({
                    errors: ["Email must be from the ".concat(_this.state.domain, " domain.")],
                });
                return;
            }
            if (!_this.validateEmailUnique(email)) {
                _this.setState({
                    errors: ["Email must be unique."],
                });
                return;
            }
            if (role === "admin" && _this.getUnassignedAdminSeats() === 0)
                return;
            if (role === "user" && _this.getUnassignedUserSeats() === 0)
                return;
            _this.setState(function (prevState) { return ({
                members: __spreadArray(__spreadArray([], prevState.members, true), [{ first_name: first_name, last_name: last_name, email: email, role: role }], false),
                errors: [],
            }); });
        };
        _this.deleteMember = function (email) {
            _this.setState(function (prevState) { return ({
                members: prevState.members.filter(function (member) { return member.email !== email; }),
            }); });
        };
        _this.editMember = function (oldEmail, first_name, last_name, newEmail) {
            _this.setState(function (prevState) { return ({
                members: prevState.members.map(function (member) {
                    return member.email === oldEmail
                        ? __assign(__assign({}, member), { first_name: first_name, last_name: last_name, email: newEmail }) : member;
                }),
            }); });
        };
        _this.getUnassignedAdminSeats = function () {
            var assignedAdmins = _this.state.members.filter(function (m) { return m.role === "admin"; }).length;
            return _this.state.admin_seats - assignedAdmins;
        };
        _this.getUnassignedUserSeats = function () {
            var assignedUsers = _this.state.members.filter(function (m) { return m.role === "user"; }).length;
            return _this.state.user_seats - assignedUsers;
        };
        _this.addMembers = function () {
            _this.setState({
                addingMembers: true,
            });
            USER_POST_REQUEST("add-organization-members", _this.state.members.filter(function (m) { return !m.isCurrentAdmin; }), function (res) {
                _this.setState({
                    addingMemberSuccess: true,
                    addingMemberError: false,
                    addingMemberErrorMessage: "",
                });
                window.location.href = "/dashboard";
            }, function (err) {
                _this.setState({
                    addingMemberSuccess: false,
                    addingMemberError: true,
                    addingMemberErrorMessage: err.response.data.message,
                });
            });
            _this.setState({
                addingMembers: false,
            });
        };
        if (props.LoggedIn == false) {
            window.location.href = "/login";
        }
        _this.state = {
            admin_seats: props.Organization.admin_seats,
            user_seats: props.Organization.user_seats,
            members: [
                {
                    isCurrentAdmin: true,
                    first_name: props.User.first_name,
                    last_name: props.User.last_name,
                    email: props.User.email,
                    role: "admin",
                },
            ],
            domain: props.Organization.domain,
            errors: [],
            addingMembers: false,
        };
        return _this;
    }
    ManageSeatLocal.prototype.render = function () {
        var _this = this;
        if (this.props.LoggedIn == false) {
            return React.createElement(Navigate, { to: "/login" });
        }
        return (React.createElement(ManageSeatNav, null,
            React.createElement("div", { className: "seat-assignment" },
                React.createElement("h1", { className: "seat-assignment__title" }, "ASSIGN SEATS"),
                React.createElement("div", { className: "seat-assignment__border" }),
                React.createElement("div", { className: "seat-assignment__cards" },
                    React.createElement("div", null,
                        React.createElement("div", { className: "seat-card" },
                            React.createElement("div", { className: "seat-card__header" },
                                React.createElement("span", { className: "seat-card__number", style: { fontSize: "96px", fontWeight: "bold" } }, this.state.admin_seats),
                                React.createElement("span", { className: "seat-card__type", style: {
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        textTransform: "uppercase",
                                    } },
                                    React.createElement("span", { style: { color: "#8CBF00", textTransform: "uppercase" } }, "Administrator"),
                                    " ",
                                    "Seats"),
                                React.createElement("span", { className: "seat-card__unassigned", style: {
                                        color: "white",
                                        fontFamily: "IBM Plex Mono",
                                        fontSize: "17px",
                                        fontWeight: 600,
                                        textTransform: "uppercase",
                                    } },
                                    this.getUnassignedAdminSeats(),
                                    " Unassigned"))),
                        React.createElement(MemberList, { members: this.state.members.filter(function (m) { return m.role === "admin"; }), onDelete: this.deleteMember, onEdit: this.editMember }),
                        React.createElement(MemberForm, { onSubmit: function (firt_name, last_name, email) {
                                return _this.addMember(firt_name, last_name, email, "admin");
                            }, disabled: this.getUnassignedAdminSeats() === 0 })),
                    React.createElement("div", null,
                        React.createElement("div", { className: "seat-card" },
                            React.createElement("div", { className: "seat-card__header" },
                                React.createElement("span", { className: "seat-card__number", style: { fontSize: "96px", fontWeight: "bold" } }, this.state.user_seats),
                                React.createElement("span", { className: "seat-card__type", style: {
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        textTransform: "uppercase",
                                    } },
                                    React.createElement("span", { style: { color: "#8CBF00", textTransform: "uppercase" } }, "User"),
                                    " ",
                                    "Seats"),
                                React.createElement("span", { className: "seat-card__unassigned", style: {
                                        color: "white",
                                        fontFamily: "IBM Plex Mono",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        textTransform: "uppercase",
                                    } },
                                    this.getUnassignedUserSeats(),
                                    " Unassigned"))),
                        React.createElement(MemberList, { members: this.state.members.filter(function (m) { return m.role === "user"; }), onDelete: this.deleteMember, onEdit: this.editMember }),
                        React.createElement(MemberForm, { onSubmit: function (first_name, last_name, email) {
                                return _this.addMember(first_name, last_name, email, "user");
                            }, disabled: this.getUnassignedUserSeats() === 0 }))),
                React.createElement("div", { style: { display: "flex", justifyContent: "end" } }, this.state.errors.length > 0 && (React.createElement("div", { className: "error-message", style: { marginTop: "10px" } }, this.state.errors.map(function (error, index) { return (React.createElement("p", { key: index, style: { color: "red" } }, error)); })))),
                React.createElement("div", { style: { display: "flex", justifyContent: "end" } }, this.state.addingMembers ? (React.createElement("div", { className: "error-message", style: { marginTop: "10px" } },
                    React.createElement("p", { style: { color: "red" } }, "Submitting..."))) : (React.createElement("button", { onClick: this.addMembers, className: "btn btn-blue" }, "Submit"))))));
    };
    return ManageSeatLocal;
}(React.Component));
var MemberForm = /** @class */ (function (_super) {
    __extends(MemberForm, _super);
    function MemberForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { first_name: "", last_name: "", email: "" };
        _this.handleSubmit = function (e) {
            e.preventDefault();
            _this.props.onSubmit(_this.state.first_name, _this.state.last_name, _this.state.email);
            _this.setState({ first_name: "", last_name: "", email: "" });
        };
        return _this;
    }
    MemberForm.prototype.render = function () {
        var _this = this;
        return (React.createElement("form", { className: "member-form", onSubmit: this.handleSubmit },
            React.createElement("input", { type: "text", placeholder: "First Name", value: this.state.first_name, onChange: function (e) { return _this.setState({ first_name: e.target.value }); }, name: "First Name" }),
            React.createElement("input", { type: "text", placeholder: "Last Name", value: this.state.last_name, onChange: function (e) { return _this.setState({ last_name: e.target.value }); }, name: "Last Name" }),
            React.createElement("input", { type: "email", placeholder: "Email", value: this.state.email, onChange: function (e) { return _this.setState({ email: e.target.value }); } }),
            React.createElement("button", { className: "btn btn-blue", type: "submit", disabled: this.props.disabled }, "Add")));
    };
    return MemberForm;
}(React.Component));
var MemberList = function (_a) {
    var members = _a.members, onDelete = _a.onDelete, onEdit = _a.onEdit;
    var _b = React.useState(null), editingMember = _b[0], setEditingMember = _b[1];
    var _c = React.useState(""), editFirstName = _c[0], setEditFirstName = _c[1];
    var _d = React.useState(""), editLastName = _d[0], setEditLastName = _d[1];
    var _e = React.useState(""), editEmail = _e[0], setEditEmail = _e[1];
    var startEdit = function (member) {
        setEditingMember(member.email);
        setEditFirstName(member.first_name);
        setEditLastName(member.last_name);
        setEditEmail(member.email);
    };
    var saveEdit = function (oldEmail) {
        onEdit(oldEmail, editFirstName, editLastName, editEmail);
        setEditingMember(null);
    };
    return (React.createElement("div", { className: "member-list" },
        React.createElement("div", { className: "member-list__header" },
            React.createElement("span", { style: { color: "white" } }, "Name"),
            React.createElement("span", { style: { color: "#898989" } }, "Email"),
            React.createElement("span", { style: { color: "#898989" } }, "Actions")),
        members.map(function (member, index) { return (React.createElement("div", { key: index + "member", className: "member-list__item" }, editingMember === member.email ? (React.createElement(React.Fragment, null,
            React.createElement("input", { type: "text", value: editFirstName, onChange: function (e) { return setEditFirstName(e.target.value); } }),
            React.createElement("input", { type: "text", value: editLastName, onChange: function (e) { return setEditLastName(e.target.value); } }),
            React.createElement("input", { type: "email", value: editEmail, onChange: function (e) { return setEditEmail(e.target.value); } }),
            React.createElement("div", { className: "member-list__actions" },
                React.createElement("button", { onClick: function () { return saveEdit(member.email); } }, "Save"),
                React.createElement("button", { onClick: function () { return setEditingMember(null); } }, "Cancel")))) : (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                index + 1 + "  ",
                " ",
                member.first_name,
                " ",
                member.last_name),
            React.createElement("span", null, member.email),
            member.isCurrentAdmin ? (React.createElement("span", null, "You")) : (React.createElement("div", { className: "member-list__actions" },
                React.createElement("button", { onClick: function () { return startEdit(member); } }, "Edit"),
                React.createElement("button", { onClick: function () { return onDelete(member.email); } }, "Delete"))))))); })));
};
var ManageSeatForFirstTimeSignup = connector(ManageSeatLocal);
export default ManageSeatForFirstTimeSignup;
