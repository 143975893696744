var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, } from "react-router-dom";
import { Provider, connect, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import createAppStore, { store } from "redux/store";
import { VANITY, SETUP_ENDPOINT_URL, INITIAL_STATE, ENDPOINT } from "config";
import { CREDENTIALED_REQUEST } from "helpers/requests";
import { Login as LoginAction } from "auth/login";
import Login from "pages/auth/login";
import { Error404 } from "pages/errors/404";
import Dashboard from "pages/dashboard/main";
import Icons from "pages/test/icons";
import CreateIdeaPage from "pages/test/createIdea";
import { io } from "socket.io-client";
import IdeaPage from "pages/ideas/idea";
import AssetsPage from "pages/assets/main";
import NewOrganizationPage from "pages/organization/main";
import SettingsPage from "pages/settings/main";
import IdeasPage from "pages/ideas/ideas";
import ProjectsPage from "pages/projects/main";
import ProjectPage from "pages/projects/projectPage";
import Ideabase from "pages/ideabase/main";
import SearchPage from "pages/search/main";
import SignUp from "pages/auth/signup";
import OrganizationMemberSetup from "pages/testingOrganizationSetup";
import GroupsPage from "pages/groups/main";
import GroupPage from "pages/groups/group";
import ForgotPassword from "pages/auth/forgotPassword";
import ResetPassword from "pages/auth/resetPassword";
import VerifyEmail from "./pages/auth/verify";
import { createRoot } from "react-dom/client";
import { BillingPage } from "./pages/billing";
import { MemberRegistration } from "./pages/auth/memberRegistration";
import ManageSeatForFirstTimeSignup from "pages/seats/manageSeat";
import VerifyRegistrationEmail from "pages/auth/activate";
import './index.css';
import ManageSubscription from "pages/billing/managesubscribtion";
/* Redux Information for App Class */
var mapState = function (state) { return ({
    LoggedIn: state.App.LoggedIn,
    AppLoaded: state.App.AppLoaded,
    Auth: state.App.Auth,
    Socket: state.App.Socket,
    SocketStatus: state.App.SocketStatus,
    Deployment: state.App.Deployment || "",
}); };
var mapDispatch = {
    Login: function (payload) { return ({ type: "LOGIN", payload: payload }); },
    SetAppLoaded: function (loaded) { return ({
        type: "SET_APP_LOADED",
        loaded: loaded,
    }); },
    SetAppTheme: function (theme) { return ({ type: "SET_APP_THEME", theme: theme }); },
    SetAppSocketStatus: function (status) { return ({
        type: "SET_APP_SOCKET_STATUS",
        status: status,
    }); },
    SetAppSocket: function (socket) { return ({
        type: "SET_APP_SOCKET",
        socket: socket,
    }); },
    UpdateAssetView: function (view) { return ({
        type: "UPDATE_ASSET_VIEW",
        view: view,
    }); },
};
var connector = connect(mapState, mapDispatch);
var AppLocal = /** @class */ (function (_super) {
    __extends(AppLocal, _super);
    function AppLocal(props) {
        var _this = _super.call(this, props) || this;
        VANITY();
        SETUP_ENDPOINT_URL();
        return _this;
    }
    AppLocal.prototype.componentDidMount = function () {
        var _this = this;
        var cookies = new Cookies();
        var theme = cookies.get("theme");
        var asset_view = cookies.get("asset_view");
        var set_asset_view = "grid";
        if (asset_view != undefined) {
            set_asset_view = asset_view;
        }
        var setTheme = "dark";
        if (theme != undefined) {
            setTheme = theme;
        }
        this.props.SetAppTheme(setTheme);
        this.props.UpdateAssetView(set_asset_view);
        document.documentElement.setAttribute("theme", setTheme);
        var loggedIn = cookies.get("logged_in");
        // @ts-ignore
        if ("a" == "b") {
            var socket_1 = io(ENDPOINT.WSURL, {
                transports: ["websocket"],
                reconnection: false,
            });
            socket_1.on("connect", function () {
                _this.props.SetAppSocket(socket_1);
                _this.props.SetAppSocketStatus("connected");
            });
            socket_1.on("disconnect", function () {
                _this.props.SetAppSocketStatus("disconnected");
            });
            socket_1.on("connect_error", function () {
                _this.props.SetAppSocketStatus("connection_error");
            });
            this.props.SetAppLoaded(true);
            return;
        }
        CREDENTIALED_REQUEST("refresh", {}, function (res) {
            if (res && res.errors) {
                _this.props.SetAppLoaded(true);
                return;
            }
            try {
                LoginAction(res);
                /*
                  const socket = io(ENDPOINT.WSURL, {
                      transports:['websocket'],
                      auth: {
                          token: res.token,
                      },
                      reconnection: false,
                  })
                  socket.on("connect", () => {
                      this.props.SetAppSocket(socket)
                      this.props.SetAppSocketStatus("connected")
                  })
                  socket.on("disconnect", () => {
                      this.props.SetAppSocketStatus("disconnected")
                  })
                  socket.on("connect_error", () => {
                      this.props.SetAppSocketStatus("connection_error")
                  })
                  this.props.SetAppSocket(socket)
                   */
            }
            catch (error) {
                console.error("Error in refresh callback:", error);
                _this.props.SetAppLoaded(true);
            }
        }, function (err) {
            console.error("Error in refresh request:", err);
            _this.props.SetAppLoaded(true);
            /*
                const socket = io(ENDPOINT.WSURL, {
                    transports:['websocket'],
                    reconnection: false,
                })
                socket.on("connect", () => {
                    this.props.SetAppSocket(socket)
                    this.props.SetAppSocketStatus("connected")
                })
                socket.on("disconnect", () => {
                    this.props.SetAppSocketStatus("disconnected")
                })
                socket.on("connect_error", () => {
                    this.props.SetAppSocketStatus("connection_error")
                })*/
        });
    };
    AppLocal.prototype.render = function () {
        if (!this.props.AppLoaded) {
            return null;
        }
        return (React.createElement(Router, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/sign-up", element: React.createElement(SignUp, null) }),
                React.createElement(Route, { path: "/icons", element: React.createElement(Icons, null) }),
                React.createElement(Route, { path: "/organization/groups/:group", element: React.createElement(NewOrganizationPage, null) }),
                React.createElement(Route, { path: "/organization/members/:member", element: React.createElement(NewOrganizationPage, null) }),
                React.createElement(Route, { path: "/organization/projects/:project", element: React.createElement(NewOrganizationPage, null) }),
                React.createElement(Route, { path: "/organization/:view", element: React.createElement(NewOrganizationPage, null) }),
                React.createElement(Route, { path: "/organization", element: React.createElement(NewOrganizationPage, null) }),
                React.createElement(Route, { path: "/create-idea", element: React.createElement(CreateIdeaPage, null) }),
                React.createElement(Route, { path: "/ideas", element: React.createElement(IdeasPage, null) }),
                React.createElement(Route, { path: "/projects", element: React.createElement(ProjectsPage, null) }),
                React.createElement(Route, { path: "/ideas", element: React.createElement(IdeasPage, null) }),
                React.createElement(Route, { path: "/groups", element: React.createElement(GroupsPage, null) }),
                React.createElement(Route, { path: "/assets/ideas", element: React.createElement(IdeasPage, null) }),
                React.createElement(Route, { path: "/assets/projects", element: React.createElement(ProjectsPage, null) }),
                React.createElement(Route, { path: "/idea/:idea", element: React.createElement(IdeaPage, null) }),
                React.createElement(Route, { path: "/draft/:draft", element: React.createElement(CreateIdeaPage, null) }),
                React.createElement(Route, { path: "/project/:project", element: React.createElement(ProjectPage, null) }),
                React.createElement(Route, { path: "/group/:group", element: React.createElement(GroupPage, null) }),
                React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null) }),
                React.createElement(Route, { path: "/register/:referral", element: React.createElement(SignUp, null) }),
                React.createElement(Route, { path: "/register", element: React.createElement(SignUp, null) }),
                React.createElement(Route, { path: "/manage-seat", element: React.createElement(ManageSeatForFirstTimeSignup, null) }),
                React.createElement(Route, { path: "/organization-members-test", element: React.createElement(OrganizationMemberSetup, null) }),
                React.createElement(Route, { path: "/billing", element: React.createElement(BillingPage, null) }),
                React.createElement(Route, { path: "/manage-subscription", element: React.createElement(ManageSubscription, null) }),
                React.createElement(Route, { path: "/member-registration", element: React.createElement(MemberRegistration, null) }),
                React.createElement(Route, { path: "/verify", element: React.createElement(VerifyEmail, null) }),
                React.createElement(Route, { path: "/activate", element: React.createElement(VerifyRegistrationEmail, null) }),
                React.createElement(Route, { path: "/ideabase", element: React.createElement(Ideabase, null) }),
                React.createElement(Route, { path: "/settings", element: React.createElement(SettingsPage, null) }),
                React.createElement(Route, { path: "/assets", element: React.createElement(AssetsPage, null) }),
                React.createElement(Route, { path: "/dashboard", element: React.createElement(Dashboard, null) }),
                React.createElement(Route, { path: "/login/:referral", element: React.createElement(Login, null) }),
                React.createElement(Route, { path: "/login", element: React.createElement(Login, null) }),
                React.createElement(Route, { path: "/reset-password", element: React.createElement(ResetPassword, null) }),
                React.createElement(Route, { path: "/forgot-password", element: React.createElement(ForgotPassword, null) }),
                React.createElement(Route, { path: "/logout", element: React.createElement(Logout, null) }),
                React.createElement(Route, { path: "/", element: React.createElement(Redirector, null) }),
                React.createElement(Route, { path: "/", element: React.createElement(Error404, null) }))));
    };
    return AppLocal;
}(React.Component));
var Logout = function () {
    CREDENTIALED_REQUEST("logout", {}, function (res) {
        store.dispatch({ type: "LOGOUT" });
        return React.createElement(Navigate, { to: "login" });
    }, function (err) {
        console.log(err);
    });
    return React.createElement(Navigate, { to: "/login" });
};
function Redirector() {
    var LoggedIn = useSelector(function (state) { return state.App.LoggedIn; });
    if (LoggedIn) {
        return React.createElement(Navigate, { to: "/dashboard" });
    }
    else {
        var cookies = new Cookies();
        cookies.set("logged_in", "false");
        return React.createElement(Navigate, { to: "/login" });
    }
}
var MobileCover = /** @class */ (function (_super) {
    __extends(MobileCover, _super);
    function MobileCover() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MobileCover.prototype.render = function () {
        return (React.createElement("div", { className: "mobile-cover" },
            React.createElement("div", { className: "container-fluid" },
                React.createElement("div", { className: "row align-items-center" },
                    React.createElement("div", { className: "container" },
                        React.createElement("h1", { className: "font-alt-2 t-center" },
                            "Site only available on desktop for now. ",
                            React.createElement("br", null),
                            "Stay tuned!"))))));
    };
    return MobileCover;
}(React.Component));
var App = connector(AppLocal);
createAppStore(INITIAL_STATE);
var container = document.getElementById("root");
var root = createRoot(container);
root.render(React.createElement(Provider, { store: store },
    React.createElement(MobileCover, null),
    React.createElement(App, null)));
