var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { recursiveFileInsert } from './files/recursiveFileInsert';
import { recursiveFileRemove } from './files/recursiveFileRemove';
import { recursiveFileUnRemove } from './files/recursiveFileUnRemove';
import { recursiveFolderInsert } from './folders/recursiveFolderInsert';
import { recursiveFolderRemove } from './folders/recursiveFolderRemove';
import { recursiveFolderUnRemove } from './folders/recursiveFolderUnRemove';
import { recursiveFolderUpdate } from './folders/recursiveFolderUpdate';
import { recursiveFileUpdate } from './files/recursiveFileUpdate';
import { handleDirectory } from './functions';
function addFolder(folder) {
    if (folder === void 0) { folder = undefined; }
    if (!folder.in_folder) {
        this.setState(function (prev) { return ({
            folders: __spreadArray(__spreadArray([], prev.folders, true), [folder], false)
        }); });
    }
    else {
        this.setState(function (prev) { return ({
            folders: recursiveFolderInsert(folder, prev.folders)
        }); });
    }
}
function addFolderDT(folder, parent) {
    if (folder === void 0) { folder = undefined; }
    if (parent === void 0) { parent = ""; }
    this.handleDirectory = handleDirectory.bind(this);
    this.handleDirectory(folder, parent);
}
function removeFolder(folder) {
    if (folder === void 0) { folder = undefined; }
    if (!folder.in_folder) {
        this.setState(function (prev) { return ({
            folders: prev.folders.filter(function (filtered) { return filtered.id != folder.id; })
        }); });
    }
    else {
        this.setState(function (prev) { return ({
            folders: recursiveFolderRemove(folder, prev.folders)
        }); });
    }
}
function unremoveFolder(folder) {
    if (folder === void 0) { folder = undefined; }
    if (!folder.in_folder) {
        this.setState(function (prev) { return ({
            folders: prev.folders.find(function (found) { return found.id == folder.id; }).Removed = true
        }); });
    }
    else {
        this.setState(function (prev) { return ({
            folders: recursiveFolderUnRemove(folder, prev.folders)
        }); });
    }
}
function addFile(file) {
    if (file === void 0) { file = undefined; }
    if (!file.in_folder) {
        this.setState(function (prev) { return ({
            files: __spreadArray(__spreadArray([], prev.files, true), [file], false)
        }); });
    }
    else {
        this.setState(function (prev) { return ({
            folders: recursiveFileInsert(file, prev.Folders)
        }); });
    }
}
function removeFile(file) {
    if (file === void 0) { file = undefined; }
    if (!file.in_folder) {
        this.setState(function (prev) { return ({
            files: prev.files.filter(function (filtered) { return filtered.id != file.id; })
        }); });
    }
    else {
        this.setState(function (prev) { return ({
            folders: recursiveFileRemove(file, prev.folders)
        }); });
    }
}
function unremoveFile(file) {
    if (file === void 0) { file = undefined; }
    if (!file.in_folder) {
        this.setState(function (prev) { return ({
            files: prev.files.find(function (found) { return found.id == file.id; }).Removed = false
        }); });
    }
    else {
        this.setState(function (prev) { return ({
            folders: recursiveFileUnRemove(file, prev.folders)
        }); });
    }
}
function selectFile(file) {
    console.log(file);
    if (this.state.selected != null) {
        this.deselect = deselect.bind(this);
        this.deselect();
    }
    if (!file.in_folder) {
        var updated = this.state.files;
        updated.find(function (found) { return found.id == file.id; }).selected = true,
            this.setState({
                files: updated,
                selected: file,
            });
    }
    else {
        file.selected = true;
        this.setState(function (prev) { return ({
            folders: recursiveFileUpdate(file, prev.folders),
            selected: file,
        }); });
    }
}
function selectFolder(folder) {
    if (this.state.selected.id != undefined && this.state.selected.id != null) {
        this.deselect = deselect.bind(this);
        this.deselect();
    }
    if (!folder.in_folder) {
        var updated = this.state.folders;
        updated.find(function (found) { return found.id == folder.id; }).selected = true;
        this.setState({
            folders: updated,
            selected: folder,
        });
    }
    else {
        folder.selected = true;
        this.setState(function (prev) { return ({
            folders: recursiveFolderUpdate(folder, prev.folders),
            selected: folder,
        }); });
    }
}
function deselect() {
    var _this = this;
    if (this.state.selected.data == null) {
        this.state.selected.selected = false;
        this.setState(function (prev) { return ({
            folders: recursiveFolderUpdate(_this.state.selected, prev.folders),
            // @ts-ignore
            selected: null,
        }); });
    }
    else {
        if (this.state.selected.in_folder) {
            this.state.selected.selected = false;
            this.setState(function (prev) { return ({
                folders: recursiveFileUpdate(_this.state.selected, prev.folders),
                // @ts-ignore
                selected: null,
            }); });
        }
        else {
            var updated = this.state.files;
            updated.find(function (found) { return found.id == _this.state.selected.id; }).selected = false,
                this.setState({
                    files: updated,
                    // @ts-ignore
                    selected: null,
                });
        }
    }
}
export function ActionHandler(action, data) {
    if (data === void 0) { data = undefined; }
    switch (action) {
        case "ADD_FOLDER":
            this.addFolder = addFolder.bind(this);
            this.addFolder(data);
            break;
        case "ADD_FOLDER_DT":
            this.addFolderDT = addFolderDT.bind(this);
            this.addFolderDT(data);
            break;
        case "REMOVE_FOLDER":
            this.removeFolder = removeFolder.bind(this);
            this.removeFolder(data);
            break;
        case "UNREMOVE_FOLDER":
            this.unremoveFolder = unremoveFolder.bind(this);
            this.unremoveFolder(data);
            break;
        case "ADD_FILE":
            this.addFile = addFile.bind(this);
            this.addFile(data);
            break;
        case "REMOVE_FILE":
            this.removeFile = removeFile.bind(this);
            this.removeFile(data);
            break;
        case "UNREMOVE_FILE":
            this.unremoveFile = unremoveFile.bind(this);
            this.unremoveFile(data);
            break;
        case "SELECT_FOLDER":
            this.selectFolder = selectFolder.bind(this);
            this.selectFolder(data);
            break;
        case "SELECT_FILE":
            this.selectFile = selectFile.bind(this);
            this.selectFile(data);
            break;
        default:
            return;
    }
}
