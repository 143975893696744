var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import TopNavTemplate from 'templates/topNav';
import { Link } from 'react-router-dom';
import TopNavSettings from './shared';
var GuestTopNavLocal = /** @class */ (function (_super) {
    __extends(GuestTopNavLocal, _super);
    function GuestTopNavLocal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GuestTopNavLocal.prototype.render = function () {
        var referral = "";
        return (React.createElement(TopNavTemplate, null,
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "nav-right row justify-content-end align-items-center", style: { marginRight: '20px' } },
                    React.createElement(TopNavSettings, null),
                    React.createElement(Link, { to: "".concat(referral != undefined && referral != "" ? "/login/".concat(referral) : "/login"), style: { textDecoration: "none" } }, "Login"),
                    React.createElement(Link, { to: "".concat(referral != undefined && referral != "" ? "/register/".concat(referral) : "/register"), style: { marginLeft: "1em", textDecoration: "none" } }, "Register")))));
    };
    return GuestTopNavLocal;
}(React.Component));
var GuestTopNav = GuestTopNavLocal;
export default GuestTopNav;
