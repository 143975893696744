var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UpdateInput } from "helpers/inputHandlers";
import React from "react";
import { connect } from "react-redux";
var mapState = function (state) { return ({
    Socket: state.App.Socket,
    SocketStatus: state.App.SocketStatus,
}); };
var connector = connect(mapState, null);
var AssignMetadataLocal = /** @class */ (function (_super) {
    __extends(AssignMetadataLocal, _super);
    function AssignMetadataLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.projectSearchUpdate = function (e) {
            var value = e.target.value;
            _this.setState({
                searchProjectName: value,
                projectSearchResults: _this.props.data.RecentProjects.filter(function (p) {
                    return (p.name.substring(0, value.length).toLowerCase() == value.toLowerCase());
                }),
            });
        };
        _this.tagSearchUpdate = function (e) {
            var value = e.target.value;
            _this.setState({
                searchTagName: value,
            });
            /*() => {
                    if(value.length > 2) {
                        Socket.send(JSON.stringify({type: 'tag-search', data: value}))
                    } else {
                        this.setState({
                            tagSearchResults: []
                        })
                    }
                }*/
        };
        _this.handleTagKeyUp = function (e) {
            var key = e.key;
            if (key === "Enter" || key === 13) {
                _this.tagActions("add", _this.state.searchTagName);
            }
            return;
        };
        _this.tagActions = function (action, tag) {
            _this.props.tagActions(action, tag);
            if (action == "add") {
                _this.setState({
                    searchTagName: "",
                });
            }
        };
        _this.updateVisibility = function (e) {
            _this.props.setVisibility(e.target.value);
        };
        _this.selectProject = function (project) {
            _this.setState({
                projectSearchResults: [],
                searchProjectName: "",
            }, function () {
                _this.props.setCurrentProject(project);
            });
        };
        _this.state = {
            searchProjectName: "",
            searchTagName: "",
            projectSearchResults: [],
            tagSearchResults: [],
        };
        _this.calls = 0;
        _this.projectSearchUpdate = _this.projectSearchUpdate.bind(_this);
        _this.updateInput = UpdateInput.bind(_this);
        // this.handleWS = this.handleWS.bind(this)
        _this.tagActions = _this.tagActions.bind(_this);
        _this.handleTagKeyUp = _this.handleTagKeyUp.bind(_this);
        _this.updateVisibility = _this.updateVisibility.bind(_this);
        _this.selectProject = _this.selectProject.bind(_this);
        return _this;
    }
    AssignMetadataLocal.prototype.componentDidMount = function () {
        if (this.props.SocketStatus == "connected") {
            this.handleWS();
        }
    };
    AssignMetadataLocal.prototype.handleWS = function () {
        return;
        var Socket = this.props.Socket;
        Socket.on("search-results/project", function (msg) {
            console.log(msg);
        });
        Socket.on("search-results/tags", function (msg) {
            console.log(msg);
        });
    };
    AssignMetadataLocal.prototype.componentWillUnmount = function () {
        // const {Socket} = this.props
        // Socket.off("search-results/project")
        // Socket.off("search-results/tags")
    };
    AssignMetadataLocal.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "row field" },
                React.createElement("div", { className: "col-6" },
                    React.createElement("label", { htmlFor: "searchProjectName", className: "label-alt" }, "Add to a project"),
                    React.createElement("input", { type: "text", onChange: this.projectSearchUpdate, value: this.state.searchProjectName, name: "searchProjectName", placeholder: "Search for a project by name" }),
                    React.createElement("div", { className: "".concat(this.state.searchProjectName == "" ? "d-none" : "", " project-search-results ") },
                        this.state.projectSearchResults.length == 0 ? (React.createElement("div", { className: "project-search-result" },
                            React.createElement("span", { className: "name" }, "No results"))) : null,
                        this.state.projectSearchResults.map(function (result, index) {
                            return (React.createElement("div", { key: index, className: "project-search-result", onClick: function () {
                                    _this.selectProject(result);
                                } },
                                React.createElement("span", { className: "name" }, result.name)));
                        })),
                    React.createElement("div", { className: "row" },
                        this.props.data.CurrentProject != null ? (React.createElement("div", { className: "col-12" },
                            React.createElement("h2", { className: "font-alt current-project" },
                                "Current Project:",
                                " ",
                                this.props.data.CurrentProject.project_name,
                                " - [",
                                this.props.data.CurrentProject.id,
                                "]",
                                " "),
                            React.createElement("h3", { onClick: function () {
                                    _this.props.setCurrentProject(null);
                                }, className: "font-alt remove-current-project" }, "[click here to not use this project]"))) : null,
                        React.createElement("div", { className: "suggestions col-12" },
                            React.createElement("h3", null, "Recently Updated Projects:"),
                            React.createElement("div", { className: "row" },
                                React.createElement("div", { className: "col-12" }, this.props.data.RecentProjects.map(function (rp, index) {
                                    if (index > 2) {
                                        return null;
                                    }
                                    return (React.createElement("h4", { key: index, className: "recent-project", onClick: function () {
                                            _this.props.setCurrentProject(rp);
                                        } },
                                        rp.name,
                                        " - [",
                                        rp.id,
                                        "]"));
                                })))))),
                React.createElement("div", { className: "col-6" },
                    React.createElement("label", { htmlFor: "newTag", className: "col-12 label-alt" }, "Assign Tags"),
                    React.createElement("div", { className: "create-tag-wrapper" },
                        React.createElement("input", { onKeyUp: this.handleTagKeyUp, onChange: this.tagSearchUpdate, placeholder: "Create new tag", value: this.state.searchTagName, name: "newTag" }),
                        this.state.searchTagName.length > 0 ? (React.createElement("span", { onClick: function () {
                                _this.tagActions("add", _this.state.searchTagName);
                            }, className: "confirm-add-tag h-c" },
                            "Confirm ", "&",
                            " Add")) : null),
                    React.createElement("div", { className: "tag-search-results" }, this.state.tagSearchResults.map(function (result, index) {
                        return (React.createElement("div", { key: index, className: "tag-search-result" },
                            React.createElement("span", { className: "name" }, result.name)));
                    })),
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: " ".concat(this.props.data.Tags.length == 0 ? "d-none" : "", " tag-list col-12") },
                            React.createElement("h3", null, "Tags:"),
                            this.props.data.Tags.map(function (tag, index) {
                                return (React.createElement("span", { key: index, onClick: function () { return _this.props.tagActions("remove", tag); }, className: "tag active" }, tag));
                            })),
                        React.createElement("div", { className: "suggestions tag-list col-12" },
                            React.createElement("h3", null, "Suggestions:"),
                            React.createElement("span", { onClick: function () {
                                    _this.props.data.Tags.includes("asdf")
                                        ? _this.props.tagActions("remove", "asdf")
                                        : _this.props.tagActions("add", "asdf");
                                }, className: "tag ".concat(this.props.data.Tags.includes("asdf") ? "active" : "") }, "asdf")))))));
    };
    return AssignMetadataLocal;
}(React.Component));
var AssignMetadata = connector(AssignMetadataLocal);
export default AssignMetadata;
