var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Today } from 'helpers/dates';
import React from 'react';
import DashboardTemplate from 'templates/dashboard';
import Assets from './components/assets';
import DashboardContext from './context';
import LoadDashboardData from './functions/loadDashboardData';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
var mapState = function (state) { return ({
    User: state.App.User,
    LoggedIn: state.App.LoggedIn,
}); };
var mapDispatch = {};
var connector = connect(mapState, mapDispatch);
var DashboardLocal = /** @class */ (function (_super) {
    __extends(DashboardLocal, _super);
    function DashboardLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.loadDashboardData = LoadDashboardData.bind(_this);
        _this.state = {
            view: 'home',
            data: _this.loadDashboardData()
        };
        return _this;
    }
    DashboardLocal.prototype.render = function () {
        if (this.props.LoggedIn == false) {
            return React.createElement(Navigate, { to: "/login" });
        }
        return (React.createElement(DashboardTemplate, null,
            React.createElement("div", { className: "welcome-header container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-6" },
                        React.createElement("h1", null,
                            "Welcome back, ",
                            this.props.User != null ? this.props.User.first_name : ""),
                        React.createElement("h3", null, Today())),
                    React.createElement("div", { className: "col-6 t-right d-flex align-items-center justify-content-end" },
                        React.createElement(Link, { to: "/create-idea", className: "dashboard-create-idea welcome-link btn btn-blue" },
                            React.createElement("span", { className: "icon-add" }),
                            " Add New Idea")))),
            React.createElement(DashboardContext.Provider, { value: this.state.data },
                React.createElement("div", { className: "dashboard-content container" },
                    React.createElement("div", { className: "row" },
                        React.createElement(Assets, null))))));
    };
    return DashboardLocal;
}(React.Component));
var Dashboard = connector(DashboardLocal);
export default Dashboard;
