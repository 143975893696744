export function FolderInfoString(folder) {
    var res = iterateFolder(folder);
    return "".concat(res[0] == 0 ? "" : "".concat(res[0], " folders, "), " ").concat(res[1], " files");
}
function iterateFolder(folder) {
    var folderCount = 0;
    var fileCount = 0;
    if (folder.folders != null) {
        for (var i = 0; i < folder.folders.length; i++) {
            folderCount++;
            var res = iterateFolder(folder.folders[i]);
            folderCount += res[0];
            fileCount += res[1];
        }
    }
    if (folder.files != null) {
        for (var i = 0; i < folder.files.length; i++) {
            fileCount++;
        }
    }
    return [folderCount, fileCount];
}
export function FolderInfo(folder) {
    var res = iterateFolder(folder);
    return res;
}
