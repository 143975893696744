export function GetFolderInfo(folder) {
    var info = [];
    if (!folder.new) {
        info = recursiveDBFolderInfo(folder);
    }
    else {
        info = recursiveFolderInfo(folder);
    }
}
function recursiveDBFolderInfo(folder) {
    return [];
}
function recursiveFolderInfo(folder) {
    return [];
}
