var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var CoverImageLocal = /** @class */ (function (_super) {
    __extends(CoverImageLocal, _super);
    function CoverImageLocal(props) {
        var _this = _super.call(this, props) || this;
        _this.preventDefault = function (e) {
            e.preventDefault();
            e.stopPropagation();
        };
        _this.dropHandler = function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.dataTransfer.files.length > 0) {
                _this.props.setCoverImage(e.dataTransfer.files[0]);
            }
        };
        _this.fileProxy = function (e) {
            _this.fileProxyRef.current.click();
        };
        _this.handleFile = function (e) {
            var file = e.target.files[0];
            _this.props.setCoverImage(file);
        };
        _this.selectCoverImage = function (key) {
            if (typeof _this.props.data.Thumbnail == "object") {
                _this.setState({
                    LastImage: _this.props.data.Thumbnail
                });
            }
            _this.props.setCoverImage("".concat(key));
        };
        _this.state = {
            LastImage: null
        };
        _this.preventDefault = _this.preventDefault.bind(_this);
        _this.dropHandler = _this.dropHandler.bind(_this);
        _this.fileProxy = _this.fileProxy.bind(_this);
        _this.handleFile = _this.handleFile.bind(_this);
        _this.selectCoverImage = _this.selectCoverImage.bind(_this);
        _this.removeUploaded = _this.removeUploaded.bind(_this);
        _this.fileProxyRef = React.createRef();
        return _this;
    }
    CoverImageLocal.prototype.removeUploaded = function () {
        if (typeof this.props.data.Thumbnail == "object") {
            this.props.setCoverImage("");
        }
        this.setState({
            LastImage: null,
        });
    };
    CoverImageLocal.prototype.render = function () {
        var _this = this;
        var _a = this.props.data, Thumbnail = _a.Thumbnail, RandomizedCover = _a.RandomizedCover;
        return (React.createElement("div", { className: "row cover-image" },
            React.createElement("input", { type: "file", onChange: this.handleFile, style: { display: "none" }, ref: this.fileProxyRef }),
            React.createElement("div", { className: "col-6" },
                React.createElement("div", { className: "row" },
                    React.createElement("label", { className: "cover-image-headings col-12 font-alt" }, "Upload a custom image"),
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "row justify-content-center align-items-center file-uploader-panel h-c", onDragEnter: this.preventDefault, onDragLeave: this.preventDefault, onDragOver: this.preventDefault, onDrop: this.dropHandler, onClick: this.fileProxy },
                            React.createElement("div", { className: "file-uploader-text" },
                                React.createElement("i", { className: "icon-cloud" }),
                                React.createElement("h2", { className: "font-alt" },
                                    "Drag thumbnail here. or ",
                                    React.createElement("u", null, "click to select.")),
                                React.createElement("h4", null, "We support all standard file types.")))))),
            React.createElement("div", { className: "col-6" },
                React.createElement("div", { className: "row" },
                    typeof Thumbnail == "object" || this.state.LastImage != null ?
                        React.createElement("div", { className: "col-12" },
                            React.createElement("div", { className: "row" },
                                React.createElement("label", { className: "col-12 font-alt" },
                                    "Use your uploaded image",
                                    React.createElement("span", { onClick: this.removeUploaded, className: "font-alt fl-right h-c font-red" }, "Remove x")),
                                React.createElement("div", { className: "cover-preview uploaded-preview col-4 ".concat(typeof Thumbnail == "object" ? "active" : "h-c"), onClick: function () { typeof Thumbnail == "string" ? _this.props.setCoverImage(_this.state.LastImage) : ""; } },
                                    React.createElement("img", { src: typeof Thumbnail == "string" ? URL.createObjectURL(this.state.LastImage) : URL.createObjectURL(Thumbnail) }))))
                        : null,
                    React.createElement("label", { className: "cover-image-headings col-12 font-alt" },
                        "Or pick standard image",
                        typeof Thumbnail == "string" && Thumbnail != "" && Thumbnail != "".concat(RandomizedCover[0]) ? React.createElement("span", { onClick: function () { _this.props.setCoverImage(RandomizedCover[0]); }, className: "font-alt fl-right h-c font-red" }, "De-select x") : null),
                    React.createElement("div", { className: "cover-image-list col-12" },
                        React.createElement("div", { className: "row" }, RandomizedCover.map(function (key, index) {
                            return (React.createElement("div", { key: index, onClick: function () { return _this.selectCoverImage(key); }, className: "cover-preview col-4 ".concat(Thumbnail == "".concat(key) ? "active" : "h-c") },
                                React.createElement("img", { src: "/images/cover_images/".concat(key, ".png") })));
                        })))))));
    };
    return CoverImageLocal;
}(React.Component));
var CoverImage = CoverImageLocal;
export default CoverImage;
